// Angular and RJX Imports
// =========================================================
import { Injectable } from '@angular/core'
// Prime NG Imports
// =========================================================
import { DialogService } from 'primeng/dynamicdialog'
// Axios Imports
// =========================================================
import axios from 'axios'
// Custom Imports
// =========================================================
import { ToastAlertComponent } from 'src/app/03_shared-components/01_alerts/toast-alert/toast-alert.component'
import { TriggerApiCallsService } from '../services/cancel-api-call'
import {
  ExecuteDeleteAPI,
  ExecuteGetAPI,
  ExecutePostAPI
} from './execute_api-calls'

@Injectable()
export class SizeRangeDefinitionsAPIs {
  clearApiCalls: boolean = false

  constructor (
    public dialogService: DialogService,
    public toastAlert: ToastAlertComponent,
    public triggerApiCallsService: TriggerApiCallsService
  ) {
    // ApiCall Service
    this.triggerApiCallsService.triggerApiCalls$.subscribe({
      next: data => {
        this.clearApiCalls = data['clear_api_calls']
      }
    })
  }

  async GetAllSizeRangeDefinitions () {
    // return await new Promise((resolve, reject) => {
    //   resolve([{
    //     size_range_name: 'demo_size_range',
    //     sizes: 'S|M|L',
    //     has_existing_assigment: true
    //   }])
    // })
    const path = '/size_range_definitions'
    const messages = {
      success:
                '---> API Call Successful: Retrieved All Size Range Definitions',
      error: 'Error retrieving rowData for the Size Distro Strategy - Size Range Definitions Grid.',
      showSuccessToast: false
    }

    const response = await ExecuteGetAPI(path, this, messages)
    if (response && response.is_success) {
      return await axios
        .get(response.data, {})
        .then(res => {
          return res.data
        })
        .catch(error => {
          console.error(
            'Error retrieving size range definitions grid data: ',
            error
          )
          this.toastAlert.showError('Error Loading Data')
          throw error
        })
    } else {
      return []
    }
  }

  // Delete size range definitions
  async DeleteSizeRangeDefinitions (size_range_definitions: string[]) {
    // the api call path with the parameter string is needed
    const path = '/size_range_definitions'
    const totalNumOfSizeRangeDefinitions = size_range_definitions.length
    const suffix = totalNumOfSizeRangeDefinitions > 1 ? 's' : ''
    const messages = {
      success: `Successfully deleted ${totalNumOfSizeRangeDefinitions} size range definitions${suffix}.`,
      error: `Error deleting ${totalNumOfSizeRangeDefinitions} size range definitions${suffix}.`,
      showSuccessToast: true
    }

    return await ExecuteDeleteAPI(path, this, messages, {
      size_range_definitions
    })
  }

  async CreateSizeRangeDefinition (data) {
    // the api call path with the parameter string is needed
    const path = '/size_range_definitions'
    const messages = {
      success: 'Successfully created size range definition.',
      error: 'Error creating size range definition.',
      showSuccessToast: true
    }

    return await ExecutePostAPI(path, this, { ...data }, messages)
  }

  async CheckNameDuplicate (name: string) {
    // the api call path with the parameter string is needed
    const path = '/size_range_definitions/duplicates'
    const messages = {
      success:
                'Successfully checked for existing size range definition name.',
      error: 'Error checking for existing size range definition name.',
      showSuccessToast: false
    }
    return await ExecutePostAPI(path, this, { name }, messages)
  }
}
