// Custom Imports
// =========================================================

import { type AgGridColDefsInterface } from 'src/app/core/interfaces/ag-grid-colDef-interface'
import { FormatKey, IsKeyInObj } from 'src/app/utils/global_functions'
import { GenerateDefaultColDefs } from '../02_global-settings/global-cell-formatter'

export interface SizeRangeDefinitions_GridDataParams {
  mainColDefs: any[]
  customCellFormatting: Function
}

export async function SizeRangeDefinitions_GenerateGridData (
  params: SizeRangeDefinitions_GridDataParams
) {
  const {
    mainColDefs,
    customCellFormatting
    // detailColDefs
  } = params
  const GenerateCols = async (data: any[], gridToRender: string) => {
    const mapCols = (colData: AgGridColDefsInterface[]) =>
      Object.entries(colData).map(([colKey, colVal], groupI) => {
        const customField = 'custom_field'
        const colId = colVal?.refData[customField] || FormatKey(colKey)
        // const isGroupCol = IsKeyInObj(colVal, 'children')

        const primaryDefs = {
          ...GenerateDefaultColDefs(colKey, { colId, ...colVal })
        }
        const cellRender = {
          ...primaryDefs
        }

        // if (isGroupCol && cellRender?.children.length > 0) {
        //   return customCellFormatting({
        //     ...cellRender,
        //     children: cellRender?.children.flatMap(child =>
        //       mapCols(child)
        //     )
        //   })
        // } else {
        return customCellFormatting(cellRender)
        // }
      })

    return mapCols(data)
  }

  const getResults = async () => {
    const mainCols = await GenerateCols(mainColDefs, 'main')

    const results = await Promise.all([mainCols])
    const m = results.indexOf(mainCols)

    const data = {
      mainColDefs: results[m]
    }
    console.log('Column Defs: ', results[m])
    return data
  }
  return await getResults()
}

export const SRD_customCellFormatting = col => {
  switch (col.field) {
    case 'delete':
      return {
        ...col,
        cellRendererParams: params => {
          return {
            disabled: false,
            toolTipMessage: 'Delete Size Range Definition',
            onClick: params => {
              return params.context.openDeleteConfirmDialog(
                params
              )
            }
          }
        }
      }
    default:
      return col
  }
}
