// Angular and RJX Imports
// =========================================================
import { Component, ViewChild, type OnInit, type OnDestroy, HostListener } from '@angular/core'
import { cloneDeep } from 'lodash'
// Prime NG Imports
// =========================================================
import { DynamicDialogRef, DynamicDialogConfig, DialogService } from 'primeng/dynamicdialog'
// Ag Grid Imports
// =========================================================
import { type GridReadyEvent, type GridApi, type ColumnApi } from 'ag-grid-community'
// JSON Data
// =========================================================
import $storeIndexOutputGridJSON from '../../../05_ag-grid-configs/01_json-grid-configs/store-index/colDefs-main-store-index-output.json'
// Custom Imports
// =========================================================
import { StoreIndexOutputAPIs } from 'src/app/core/apis/store-index-output_api-calls'
import { CustomGridOptions } from 'src/app/05_ag-grid-configs/02_global-settings/grid-options'
import { StoreIndexOutputGrid_GenerateGridData } from 'src/app/05_ag-grid-configs/04_generate-colDefs/store-index-output-colDefs'
import { DefaultChartOptions, GenerateRandomColor, StandardChartColors } from 'src/app/utils/global-chart-settings'
import { TriggerApiCallsService } from 'src/app/core/services/cancel-api-call'
import { ChartModule, UIChart } from 'primeng/chart'

@Component({
  selector: 'app-output-grid-data-modal',
  templateUrl: './output-grid-data-modal.component.html',
  styleUrls: ['./output-grid-data-modal.component.scss']
})
export class OutputGridDataModalComponent implements OnInit, OnDestroy {
  modalTitle: string = 'Store Index Output: '
  loading: boolean = true
  loadingMessage: string = 'loading'

  // Ag Grid Configuration
  private readonly customGridOptions: any = {
    ...CustomGridOptions,
    context: {
      componentParent: this,
      // columnsToAutoSize: ['source', 'index', 'store_id'],
      pageTitle: this.modalTitle,
      percentageTotal: 0,
      editedStores: []
    }
  }

  // Column Definitions JSON
  public storeIndexOutputGridJSON = $storeIndexOutputGridJSON
  // TODO: V2 Update: Add code to JSON Doc to enable populating the bar chart based on selection
  //   "Select": {
  //     "editable": false,
  //     "pinned": "left",
  //     "refData": {
  //         "datatype": "checkbox",
  //         "selectAll": true,
  //         "colSize": 80
  //     },
  //     "rowGroup": false,
  //     "hide": false,
  //     "spanHeaderHeight": true,
  //     "maxWidth": 60
  // },
  // TODO: V2 Update: ---------------------> DO NOT DELETE CODE BLOCK ABOVE (for JSON Doc)

  // Ag Grid Configuration
  gridApi: GridApi
  columnApi: ColumnApi
  gridOptions: any = {}
  isAgGridLoading: boolean = true
  // Grid Data
  columnDefs: any[] = []

  parentGridData: any

  activeTab: Number = 0

  originalRowData: any
  currentRowData: any
  isRebalancedEnabled: boolean = false
  rebalanceExecuted: boolean = false
  selectedStoresData: any[] = []
  selectedStoresIDs: string[] = []

  // doughnut Chart Vars
  doughnutChartData: any[] = []
  doughnutChartConfig: any = null
  doughnutChartOptions: any = null
  // Bar Chart Vars
  barChartConfig: any = null
  barDatasets: any[] = [{}, {}]
  showDatasets: boolean[] = [true, true]
  barChartOptions: any = null



  canvas: any
  ctx: any
  parentWidth: any

  @ViewChild('barChartAxis') barChartAxis
  @ViewChild('mainBarChartView') chartElement

  constructor (
    private readonly ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public dialogService: DialogService,
    private readonly storeIndexOutputAPIs: StoreIndexOutputAPIs,
    public triggerApiCallsService: TriggerApiCallsService
  ) {
    console.log('---> Store Index Output Modal: ', config.data)
    const { data } = config
    this.parentGridData = {
      ...data?.componentParent,
      rowData: data?.rowData.data,
      node: data?.rowData
    }
    // Update modal title to include the job name
    this.modalTitle = this.modalTitle + this.parentGridData.rowData.job_name

    //  Set AG Grid Options
    this.gridOptions = {
      ...this.customGridOptions,
      onGridReady: (event: GridReadyEvent) => this.onGridReady(event),
      getRowId: node => node.data.store_id,
      // TODO: V2 Update: Future Dev -> code to enable populating the bar chart based on selection
      // onSelectionChanged: (event: SelectionChangedEvent) => {
      //   this.selectedStoresData = event.api.getSelectedNodes()
      //   console.log(
      //     'row selection: changed: ',
      //     this.selectedStoresData
      //   )
      //   this.selectedStoresIDs =
      //               this.selectedStoresData.length > 0
      //                 ? this.selectedStoresData.map(
      //                   store => store.data.store_id
      //                 )
      //                 : []
      // },
      onCellValueChanged: event => {
        console.log('---> cell value changed: ', event)

        if (event?.colDef.colId !== 'is_locked') {
          const newValue = Number(event.newValue)
          const oldValue = Number(Number(event.oldValue).toFixed(2))
          const id = event.node.id

          if (newValue !== oldValue) {
            // console.log('---> cell value changed - NO MATCH: ', event)
            if (!event.context.editedStores.includes(id)) {
              event.context.editedStores.push(id)
            }
            this.isRebalancedEnabled = true
            event.api.refreshCells({ force: true })
          } else if (newValue === oldValue) {
            console.log('---> cell value changed - MATCH: ', event)
          }
        }
      }
    }
  }

  ngOnInit () {
    this.storeIndexOutputAPIs
    .GetStoreIndexOutputData(this.parentGridData?.rowData?.job_name)
    .then(res => {
      this.originalRowData = cloneDeep(res.rowData)
      this.doughnutChartData = res.chartData
      this.loading = false
      this.renderGrid(this.storeIndexOutputGridJSON[0], res.rowData)
    })
  }

  onGridReady (event: GridReadyEvent) {
    this.gridApi = event.api
    this.columnApi = event.columnApi
    this.isAgGridLoading = false
  }

  // Render AG Grid Column Definitions
  async renderGrid (mainColDefs, rowData) {
    const params = {
      mainColDefs
    }
    const gridData = await StoreIndexOutputGrid_GenerateGridData(params)
    if (gridData) {
      this.columnDefs = gridData.mainColDefs

      // function to check if AgGrid is loading
      const checkAgGrid = () => {
        if (!this.isAgGridLoading) {
          if (this.gridApi && !this.gridApi['destroyCalled']) {
            this.gridApi?.setColumnDefs(gridData.mainColDefs)
            this.gridApi?.setRowData(rowData)
            // Set pinned row to display totals
            this.gridApi?.setPinnedBottomRowData([
              {
                store_id: null,
                index: null,
                source: null,
                is_locked: null
              }
            ])
          }
          clearInterval(setData)
        } else {
          console.log('Ag grid is loading')
        }
      }
      const setData = setInterval(checkAgGrid, 100)
    }
  }

  tabChanged (tabIndex) {
    console.log('--> Tab Changed Event: ', tabIndex)
    this.activeTab = tabIndex
    // Configure Data for the charts tab
    if (tabIndex === 1) {
      this.configureChartTab()
    }
  }

  async onRebalanceRows (event) {
    console.log('---> Rebalancing Grid Rows')
    this.gridApi.clearFocusedCell()
    const rowsToUpdate = []

    try {
      const unLockedStores = []
      let finalTotal = 0
      let totalUnlocked = 0
      let available = 100

      await this.gridApi.forEachNode(async row => {
        const { data } = row
        // get the total taken up by the unlocked cells
        if (!data.is_locked) {
          unLockedStores.push(data)
          totalUnlocked += Number(data.index)
        } else if (
        // get the maximum allowed total of the unlocked cells by subtracting the locked ones from 100
          data.is_locked
        ) {
          available -= Number(data.index)
        }
        return data
      })

      if (unLockedStores.length > 1) {
        if (available <= 0) {
          // total locked exceeds 100
          // TODO: show error to user
          console.log('cant rebalance, total is ', available)
        } else {
          console.log('---> Unlocked Stores: ', unLockedStores)
          // Get the ratio
          const ratio = available / totalUnlocked
          // apply ratio to the unlocked cells

          await unLockedStores.forEach((store, sI) => {
            const totalVal = parseFloat((Number(store.index) * ratio).toFixed(4))

            finalTotal += totalVal
            store.index = totalVal

            if (unLockedStores.length === sI + 1) {
              const remainder = parseFloat((available - Number(finalTotal)).toFixed(4))

              store.index += Number(remainder)
            }
            rowsToUpdate.push(store)
          })
        }
      }
    } catch (error) {
      console.error('---> Error Rebalancing Grid Rows')
    } finally {
      console.log('---> Final Rebalanced rows to Update', rowsToUpdate)
      if (rowsToUpdate.length > 0) {
        this.isRebalancedEnabled = false
        this.rebalanceExecuted = true
        this.gridOptions.context.editedStores = []
        this.gridApi.applyTransaction({ update: rowsToUpdate })
      }
    }
  }

  async configureChartTab () {
    console.log('---> configuring the chart tab: ', this.doughnutChartData)
    const dataToCompare =
            this.selectedStoresData.length > 0
              ? this.originalRowData.filter(data => this.selectedStoresIDs.includes(data.store_id))
              : this.originalRowData

    const chartColors = {
      doughnut: [],
      originalBar: [],
      currentBar: []
    }
    const getColors = chart => {
      console.log('object vals: ', Object.values(chartColors))
      const color = GenerateRandomColor(Object.values(chartColors).map(val => val))
      chartColors[chart].push(color)
      return color
    }

    const doughnutChartConfig = {
      labels: [],
      datasets: [
        {
          label: 'Doughnut',
          data: [],
          backgroundColor: [],
          hoverOffset: 4
        }
      ]
    }

    const barChartConfig = {
      labels: [],
      datasets: [
        {
          label: 'Original Value',
          order: 1,
          backgroundColor: StandardChartColors.accent_1,
          data: dataToCompare.map((store, i) => {
            return { y: store.index, x: store.store_id }
          })
        },
        {
          label: 'Current Change',
          order: 2,
          backgroundColor: StandardChartColors.accent_2,
          data: []
        }
      ]
    }

    try {
 // Update doughnut chart data
 await this.doughnutChartData.forEach((data, i) => {
  doughnutChartConfig.labels.push(data.channel)
  doughnutChartConfig.datasets[0].data.push(data.index)
  // If there are only 2 channels present -> use the standard grid colors
  if (this.doughnutChartData.length === 2) {
    doughnutChartConfig.datasets[0].backgroundColor = [
      StandardChartColors.secondary, // stores
      StandardChartColors.primary // channel
    ]
  } else {
    const color = getColors('doughnut')
    if (this.doughnutChartData.length === i + 1 && color) {
      doughnutChartConfig.datasets[0].backgroundColor =
                      chartColors['doughnut']
    }
  }
})

      if (this.selectedStoresData.length > 0) {
        // Update the current bar chart data with changes for the selected stores
        await this.selectedStoresData.forEach(store => {
          if (store?.data) {
            const { data } = store
            barChartConfig.datasets[1].data.push({
              y: data?.index,
              x: data?.store_id
            })
          }
        })
      } else {
        // Update the current bar chart data with changes for all stores
        await this.gridApi.forEachNode(node => {
          if (node?.data) {
            const { data } = node
            barChartConfig.datasets[1].data.push({
              y: data?.index,
              x: data?.store_id
            })
          }
        })
      }
      // Sort the bar chart's x-axis so by total value
      if (barChartConfig.datasets[0].data.length > 0 && barChartConfig.datasets[1].data.length > 0) {
        barChartConfig.labels = [
          ...new Set(
            [...barChartConfig.datasets[0].data, ...barChartConfig.datasets[1].data]
              .sort((a, b) => b.y - a.y)
              .map(store => store.x)
          )
        ]
      }
    } finally {

        this.doughnutChartConfig = doughnutChartConfig
        // Set Chart Options
        this.doughnutChartOptions = {
          ...DefaultChartOptions,
          containerID: 'doughnut-chart',
          plugins: {
            legend: {
              position: 'bottom',
              labels: {
                font: {
                  size: 12,
                  family: "apple-system, 'Roboto', sans-serif"
                }
              }
            },
            tooltip: {
              ...DefaultChartOptions.plugins.tooltip,
              callbacks: {
                ...DefaultChartOptions.plugins.tooltip.callbacks,
                title: context => {
                  return `Channel: ${context[0].label}`
                },
                label: context => {
                  // console.log("this.lineChartData: ", this.lineChartData)
                  return `${context.label}: ${Number(context.formattedValue).toFixed(2)}%`
                }
              }
            }
          }
        }


      // Set the Bar Chart
      this.barDatasets[0] = barChartConfig.datasets[0]
      this.barDatasets[1] = barChartConfig.datasets[1]
      this.barChartConfig = barChartConfig
      // Update the parent grid width p-chart is not functioning dynamically

      const target = <HTMLCanvasElement>(
                document.getElementById('barChartAxis')
            )
            //
      this.parentWidth = (
        barChartConfig.labels.length *
                30
      )
      const mainBarChart: HTMLElement = document.querySelector('#mainBarChart')
      mainBarChart.style.width = this.parentWidth.toString() + 'px'
      console.log('chartelement', this.chartElement)

      // Set the bar chart options
      this.barChartOptions = {
        ...DefaultChartOptions,
        containerID: 'mainBarChart',
        maintainAspectRatio: false,
        responsive: true,
        scales: {
          x: {
            categoryPercentage: 1.0,
            barPercentage: 1.0
          },
          y: {
            beginAtZero: true
          }
        },
        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            ...DefaultChartOptions.plugins.tooltip,
            callbacks: {
              ...DefaultChartOptions.plugins.tooltip.callbacks,
              title: context => {
                // console.log('title: ', context)
                return `Store: ${context[0].label}`
              },
              label: context => {
                return `${this.barChartConfig.datasets[context.datasetIndex].label}: ${Number(
                                    context.formattedValue
                                ).toFixed(2)}`
              }
            }
          }
        }
        // animation: {
        //   onComplete: function () {
        //     // console.log('---> on complete1: ', this)

        //     const sourceCanvas = this.ctx.canvas
        //     const copyWidth = this.chartArea.left - 5
        //     const copyHeight = this.chartArea.bottom + 5

        //     const targetCtx = target.getContext('2d')
        //     targetCtx.canvas.width = copyWidth

        //     // Get the source size ratio by dividing the source height by 300 ( the original CSS height)
        //     const sourceSizeRatio = sourceCanvas.height / 300

        //     targetCtx.drawImage(
        //       sourceCanvas,
        //       0,
        //       0,
        //       copyWidth * sourceSizeRatio,
        //       copyHeight * sourceSizeRatio,
        //       0,
        //       0,
        //       copyWidth,
        //       copyHeight
        //     )
        //   }
        // }
      }

      // console.log('---> Chart Config: ', this.doughnutChartConfig);
    }
  }

  // Save Output changes
  async onSaveOutputChanges (event) {
    console.log('---> Saving Store Index Output Changes')
    const rowDataToSend = []

    try {
      await this.gridApi.forEachNode(row => {
        const { data } = row
        data.job_name = this.parentGridData.rowData.job_name
        rowDataToSend.push(data)
        return data
      })
    } finally {
      // API CALL here
      this.storeIndexOutputAPIs.SaveStoreIndexOutputData({ row_data: rowDataToSend }).then(res => {
        if (res?.is_success) {
          this.close({
            message: 'success',
            data: rowDataToSend
          })
        } else {
          this.close()
        }
      })
    }
  }


  onClickLegend (index: number) {
    console.log('clicked legend!', index)
    this.showDatasets[index] = !this.showDatasets[index]
    console.log('changing to', this.showDatasets[index])
    const id = index == 0 ? 'original-values-btn-wrapper' : 'current-changes-btn-wrapper'
    document.getElementById(id).style.textDecoration = this.showDatasets[index] ? '' : 'line-through'
    this.barChartConfig.datasets = this.recalcBarDatasets()
    this.barChartConfig = cloneDeep(this.barChartConfig)
  }

  recalcBarDatasets () {
    const datasets = []
    this.barDatasets.forEach((dataset, index) => {
      if (this.showDatasets[index]) datasets.push(dataset)
    })
    console.log('returning datasets', datasets)
    return datasets
  }


  close (data?: any) {
    this.triggerApiCallsService.onTriggerApiCalls({
      clear_api_calls: true
    })
    if (this.ref) this.ref.close(data || null)
  }

  @HostListener('unloaded')
  ngOnDestroy () {
    if (this.gridApi) {
      this.gridApi.flushAsyncTransactions()
      this.gridApi.expireValueCache()
      this.gridApi = null
    }
  }
}
