// Angular and RJX Imports
// =========================================================
import {
    Component,
    EventEmitter,
    Input,
    Output,
    ViewChild,
    type OnInit,
} from '@angular/core';
// Prime NG Imports
// =========================================================
import { type TreeNode } from 'primeng/api';
import { Tree } from 'primeng/tree';
import { DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';
// Custom Imports
// =========================================================
import { FormatKey, IsKeyInObj } from 'src/app/utils/global_functions';
import { ConfirmPopupComponent } from 'src/app/03_shared-components/01_alerts/confirm-popup/confirm-popup.component';
import { ProxyProductCreationAPIs } from 'src/app/core/apis/proxy-product-creation_api-calls';
import { ProxyProductDetailsComponent } from 'src/app/04_modals/proxy-product-details/proxy-product-details.component';
import { SizeDistroDefaultParametersAPIs } from 'src/app/core/apis/size-distro-default-parameters_api-calls';
@Component({
    selector: 'app-product-hierarchy',
    templateUrl: './product-hierarchy.component.html',
    styleUrls: ['./product-hierarchy.component.scss'],
})
export class ProductHierarchyComponent implements OnInit {
    // Multiple Nodes Selected
    selectedValues: any;
    // Single Node Selected
    selectedNode: TreeNode = null;
    top3NodeLevels: string[] = null;

    filtersApplied = {
        has_changes: false,
        proxy_product: false,
        new_product: false,
    };

    // TODO: add interface for filters applied
    @Input('filtersApplied')
    set _filtersApplied(filtersApplied: any) {
        this.filtersApplied = { ...this.filtersApplied, ...filtersApplied };
    }

    searchFilterApplied: string = null;
    additionalChangeFilter: string = null;

    expandedNodes: string[] = [];

    // Filters available in the expand more section
    showChangesFilter: boolean;
    showProxyProductsFilter: boolean;
    showNewProductNodesFilter: boolean;

    @Input() selectionMode: string; // options are: single, multiple, or checkbox
    @Input() showEligibleProxySizes?: boolean = false;
    @Input() allowNodeDeletion?: boolean = false;
    @Input() nodeToDelete?: string;
    @Input() noNodeSelectedAllowed?: boolean = true;
    @Input() disabled: boolean = false;

    loading: boolean;
    @Input('loading')
    set _loading(loading: boolean) {
        this.loading = loading;
    }

    // Set the tree data
    treeData: TreeNode[] = [];

    treeDataParams: {
        queryString: string;
        selectedProd: any;
        selectedFilters: any;
    };

    initialQueryString: string;

    @Input('treeDataParams')
    set _treeDataParams(params: any) {
        this.loading = true;
        this.treeData = null;
        console.log('---> Updating Tree Data Params: ', params);
        console.log('old tree data params', this.treeDataParams);
        if (
            params &&
            params !== undefined &&
            (params.queryString !== this.treeDataParams?.queryString ||
                params.selectedProd !== this.treeDataParams?.selectedProd ||
                params.selectedFilters !== this.treeDataParams?.selectedFilters)
        ) {
            this.treeDataParams = params;
            if (!params.selectedProd) {
                this.selectedNode = null;
                this.selectedValues = null;
            } else {
                this.selectedNode = {
                    ...this.selectedNode,
                    key: params.selectedProd,
                };
            }
            this.initialQueryString = params.queryString;
            this.getTreeData(params);
        }
    }

    // Set filters
    filtersToDisplay: string[] = [];
    @Input('filtersToDisplay') // options are: proxy, new, or changes
    set _filtersToDisplay(data) {
        console.log('---> filters to display: ', data);
        if (data && data.length > 0) {
            const knownFilters = ['changes', 'proxy', 'new'];
            // Additional filters is currently set up to update the change icon based on an external selection
            const additionalFilters = data.filter(
                filter => !knownFilters.includes(filter),
            );
            if (additionalFilters.length === 1) {
                this.additionalChangeFilter = additionalFilters[0];
            }
            console.log('---> additional Filters: ', additionalFilters);

            this.showChangesFilter = data.includes('changes');
            this.showProxyProductsFilter = data.includes('proxy');
            this.showNewProductNodesFilter = data.includes('new');
        }
        this.filtersToDisplay = data || [];
    }

    // Handle Default Node selections
    @Input('selectedValues')
    set _selectedValues(data: TreeNode[]) {
        this.setSelectedValues(data);
    }

    get selection() {
        return this.selectionMode === 'single'
            ? this.selectedNode
            : this.selectedValues;
    }

    set selection(value) {
        if (this.selectionMode === 'single') {
            this.selectedNode = value;
        } else {
            this.selectedValues = value;
        }
    }

    @Output() onNodeSelected = new EventEmitter<any>();
    @Output() onNodeDeleted = new EventEmitter<any>();
    @Output() deletedSelection = new EventEmitter<boolean>();

    @ViewChild('confirmPopup') confirmPopup: ConfirmPopupComponent;
    @ViewChild('prodHierTreeData') tree!: Tree;

    constructor(
        private readonly proxyProductCreationAPIs: ProxyProductCreationAPIs,
        private readonly sizeDistroDefaultParametersAPIs: SizeDistroDefaultParametersAPIs,
        private ref: DynamicDialogRef,
        public dialogService: DialogService,
    ) {}

    ngOnInit(): void {
        console.log('---- Product Hierarchy Component ----');
    }

    // Give each childnode access to the parent node
    wireParents(nodes, parent: TreeNode) {
        if (nodes) {
            nodes.forEach(node => {
                if (typeof node === 'object') {
                    node.data['parent'] = parent;
                    this.wireParents(node.children, node);
                }
            });
        }
    }

    // Get All Products Hierarchy Tree Data
    getTreeData(params) {
        console.log('---> Get All Products Hierarchy Tree Data: ', params);
        // Reset the search filters
        if (this.searchFilterApplied && this.searchFilterApplied !== '') {
            this.resetFilter();
        }

        this.treeData = null;

        this.treeDataParams = {
            queryString: params.queryString,
            selectedProd: this.treeDataParams.selectedProd,
            selectedFilters: Object.keys(this.filtersApplied)
                .filter(filter => this.filtersApplied[filter])
                .join(','),
        };

        console.log('-----> Data To Send', this.treeDataParams);
        this.sizeDistroDefaultParametersAPIs
            .GetAllProducts(this.treeDataParams)
            .then(async res => {
                if (res?.status == 200 && res.data && res.data.length > 0) {
                    this.treeData = res.data;
                    this.wireParents(this.treeData, null);

                    if (
                        this.selectedNode &&
                        this.selectedNode.key !== 'all' &&
                        this.treeData.length > 0
                    ) {
                        console.log(
                            '---> Product Node Selected: ',
                            this.selectedNode,
                        );
                        let mappedData;
                        let dataSetIncludesNode = false;
                        const findNode = node => {
                            if (
                                node.key !== this.selectedNode.key &&
                                node.children.length > 0
                            ) {
                                node.children.filter(childNode =>
                                    findNode(childNode),
                                );
                            }
                            if (node.key === this.selectedNode.key) {
                                dataSetIncludesNode = true;
                                this.nodeSelect({ node });
                                this.setSelectedValues([node]);
                            }
                            return node;
                        };
                        try {
                            mappedData = await this.treeData.map(node =>
                                findNode(node),
                            );
                        } finally {
                            this.setTopLevelNodes();

                            if (!dataSetIncludesNode && mappedData.length > 0) {
                                this.nodeSelect({ node: this.treeData[0] });
                                this.setSelectedValues([this.treeData[0]]);
                            }
                        }
                    } else {
                        if (
                            (this.expandedNodes.length === 0 &&
                                !this.noNodeSelectedAllowed) ||
                            this.noNodeSelectedAllowed
                        ) {
                            console.log(
                                '---> Setting Prod Hier Tree Data expanded nodes: ',
                                this.expandedNodes,
                            );
                        }
                        this.setTopLevelNodes();
                        if (params.selectedProd === 'all') {
                            this.nodeSelect({ node: this.treeData[0] });
                            this.setSelectedValues([this.treeData[0]]);
                        }
                    }
                } else {
                    this.treeData = null;
                    this.loading = false;
                }
            });
    }

    setSelectedValues(data: TreeNode[]) {
        if (this.treeData) {
            console.log('---> Setting Selected Hierarchy Product: ', data);
            console.log('---> Current Tree Data: ', this.treeData);
            if (!data) data = [];

            if (data.length === 0) {
                this.selectedNode = {};
                if (this.treeData && this.treeData.length > 0) {
                    this.unselectNode(this.treeData[0]);
                }
            } else if (this.selectionMode === 'single') {
                this.selectedNode = data[0];

                // if (this.expandedNodes.length > 0) {
                this.setTopLevelNodes();
                // }
            } else {
                const selectedKeys = data.map(e => e.key);
                console.log('setting partial selected');

                data.forEach(node => {
                    const parent = node.parent || node?.data?.parent;
                    console.log('---> Parent Selection', parent);
                    this.setParentsPartialSelected(parent, selectedKeys);
                });
            }
        }
    }

    // Set / expand the first 3 node levels
    setTopLevelNodes(resetFilter = true) {
        // Re-apply current search filters
        // if (this.searchFilterApplied && this.searchFilterApplied !== '') {
        //   console.log(
        //     '---> Expanding Nodes after Search Filter: ',
        //     this.searchFilterApplied
        //   )
        //   if (resetFilter) {
        //     this.onTextSearchFilter(
        //       {
        //         target: { value: this.searchFilterApplied }
        //       },
        //       true
        //     )
        //   }
        //   this.loading = false
        // } else if (Object.values(this.filtersApplied).includes(true)) {
        //   console.log(
        //     '---> Expanding Nodes after Standard Filter: ',
        //     this.filtersApplied
        //   )
        //   this.expandAll(null, true)
        //   this.loading = false
        // } else {
        this.top3NodeLevels = this.treeData.flatMap(lvl_1 => [
            FormatKey(lvl_1.key),
            ...lvl_1.children.flatMap(lvl_2 => [
                FormatKey(lvl_2.key),
                ...lvl_2.children.flatMap(lvl_3 => FormatKey(lvl_3.key)),
            ]),
        ]);
        console.log('---> Setting Top 3 Level Nodes: ', this.top3NodeLevels);
        console.log('---> Current Node Expanded: ', this.expandedNodes);

        if (this.top3NodeLevels || this.expandedNodes.length > 0) {
            this.collapseAll(null, this.expandedNodes.length > 0);
        }
        console.log('setting loading to false');
        this.loading = false;
        // }
    }

    // Handle Node selection changes
    nodeSelect(event) {
        console.log('---> Tree Node Selected', event);
        // event.node.parent = event?.node?.parent || event?.node.data?.parent
        this.onNodeSelected.emit({ ...event, selected: this.selectedValues });
        this.selectedNode = event.node;
    }

    // For checkbox selection only
    setParentsPartialSelected(node: TreeNode, selectedKeys: string[]) {
        if (node && !selectedKeys.includes(node.key)) {
            const parent = node.parent || node?.data?.parent;
            console.log(
                'setting parent partial select true: ',
                node.partialSelected,
            );
            node.partialSelected = true;
            this.setParentsPartialSelected(parent, selectedKeys);
        }
    }

    // For checkbox selection only
    unselectNode(node: TreeNode) {
        // used for clearing partial selects when clear button is clicked
        node.partialSelected = false;
        node.children?.forEach(child => {
            this.unselectNode(child);
        });
    }

    // Expand all node levels
    expandAll(event, expandFilters?: boolean) {
        console.log('---> expanding all rows:  ', this.tree.filteredNodes);
        const treeData =
            expandFilters && this.tree?.filteredNodes
                ? this.tree?.filteredNodes
                : this.treeData;

        // console.log("--------> ", setFilteredData)
        treeData.forEach(node => {
            this.expandRecursive(node, true, expandFilters);
        });
        this.tree.updateSerializedValue();
    }

    // Collapses up to the top 3 node levels
    collapseAll(event, maintainExpanded?: boolean) {
        console.log('---> Collapsing Rows to the 3rd Level');
        this.treeData.forEach(node => {
            this.expandRecursive(node, false, maintainExpanded);
        });
        this.tree.updateSerializedValue();
    }

    // Expand Recursive
    expandRecursive(
        data: TreeNode,
        isExpand: boolean,
        maintainExpanded?: boolean,
        isEvent?: boolean,
    ) {
        const node = isEvent && data['node'] ? data['node'] : data;
        const formKey = FormatKey(node.key);
        const savedExpandedNodes = maintainExpanded
            ? this.expandedNodes.includes(formKey)
            : this.top3NodeLevels.includes(formKey);
        const expanded = savedExpandedNodes && !isEvent ? true : isExpand;
        if (typeof node !== 'object') {
            return;
        }
        let shouldExpand = false;
        if (node.children && node.children.length > 0) {
            for (let i = 0; i < node.children.length; i++) {
                const childNode = node.children[i];
                if (childNode.children && childNode.children.length > 0) {
                    shouldExpand = true;
                    break;
                }
            }
        }

        if (shouldExpand) {
            node['expanded'] = expanded;
            this.toggleNodeExpanded({ ...node, expanded });
            // console.log('expanded', expanded)
            // console.log('node.children', node.children)
            // console.log('node', node)

            if (node.children && node.children.length > 0) {
                node.children.forEach(childNode => {
                    if (childNode.children && childNode.children.length > 0) {
                        this.expandRecursive(
                            childNode,
                            isExpand,
                            maintainExpanded,
                            isEvent,
                        );
                    }
                });
            }
        }
        // else if (expanded && node.children) {
        //   console.log('expanded and node.children')
        //   this.sizeDistroDefaultParametersAPIs.GetProductTreeNode(this.treeDataParams, node.key).then(res => {
        //     const data = JSON.parse(res.data).map(childNode => {
        //       childNode = JSON.parse(childNode)
        //       childNode.parent = node
        //       return childNode
        //     })
        //     node.children = data
        //     console.log('---> Fetched additional tree data', this.treeData)
        //     // this.setTopLevelNodes()
        //     // this.nodeSelect({ node: this.treeData[0] })
        //     // this.setSelectedValues([this.treeData[0]])
        //   })
        // }
    }

    deleteNode(treeData, selectedNodeKey) {
        return treeData
            .map(node => ({ ...node }))
            .filter(node => {
                if (node.key === selectedNodeKey) {
                    return false;
                }
                if (node.children && node.children.length > 0) {
                    node.children = this.deleteNode(
                        node.children,
                        selectedNodeKey,
                    );
                }
                return true;
            });
    }

    deleteSelection(isDeleted: boolean) {
        this.deletedSelection.emit(isDeleted);
        this.selectedNode = null;
    }

    toggleNodeExpanded(data: any, isCreated: boolean = false) {
        const node: TreeNode = IsKeyInObj(data, 'key') ? data : data.node;
        const formKey = FormatKey(node.key);

        if (node.expanded && !this.expandedNodes.includes(formKey)) {
            this.expandedNodes.push(formKey);
            console.log(
                `---> Saving Expanded Node:  ${node.key}: ${node.label}`,
            );
            this.fetchNodeChildren(node);
        } else if (
            node.expanded &&
            this.expandedNodes.includes(formKey) &&
            isCreated
        ) {
            node.children[0].children = [];
            this.fetchNodeChildren(node);
        } else if (!node.expanded && this.expandedNodes.includes(formKey)) {
            this.expandedNodes.splice(this.expandedNodes.indexOf(formKey), 1);
            console.log(
                `---> Removing Saved Expanded Node:  ${node.key}: ${node.label}`,
            );
        }
    }

    fetchNodeChildren(node: TreeNode, callback = () => {}) {
        if (node.children.length && node.children[0].children.length == 0) {
            console.log('expanded and node.children');
            // console.log('node.children', JSON.stringify(node.children))
            this.loading = true;
            console.log('tree params', this.treeDataParams);
            this.treeDataParams = {
                queryString: this.treeDataParams.queryString,
                selectedProd: this.treeDataParams.selectedProd,
                selectedFilters: Object.keys(this.filtersApplied)
                    .filter(filter => this.filtersApplied[filter])
                    .join(','),
            };
            this.sizeDistroDefaultParametersAPIs
                .GetProductTreeNode(this.treeDataParams, node.key)
                .then(res => {
                    const data = JSON.parse(res.data).map(childNode => {
                        childNode = JSON.parse(childNode);
                        childNode.parent = node;
                        return childNode;
                    });
                    console.log('node.children', node.children);
                    node.children = data;
                    console.log('node.children', node.children);
                    console.log(
                        '---> Fetched additional tree data',
                        this.treeData,
                    );
                    this.setTopLevelNodes(false);
                    this.tree.updateSerializedValue();
                    // this.nodeSelect({ node: this.treeData[0] })
                    // this.setSelectedValues([this.treeData[0]])
                    this.loading = false;
                });
        }
    }

    resetFilter() {
        this.tree.resetFilter();
    }

    onFilterProductHier(event, filter, maintainFilter?: boolean) {
        if (!this.loading) {
            this.expandedNodes = [];
            console.log('---> Filter Prod Hierarchy');
            console.log('filter product hierarchy: ', filter);
            console.log('maintainFilter: ', maintainFilter);
            console.log('Filters Applied: ', this.filtersApplied);
            console.log('Search Filters Applied: ', this.searchFilterApplied);
            console.log(
                'this.treeDataParams',
                JSON.stringify(this.treeDataParams),
            );
            this.loading = true;
            this.filtersApplied[filter] = !this.filtersApplied[filter];
            console.log(JSON.stringify(this.filtersApplied));
            this.getTreeData({
                ...this.treeDataParams,
                selectedProd: this.selectedNode?.key || null,
            });
        }
    }

    onShowProxyProductDetails(event, selectedNode) {
        this.ref = this.dialogService.open(ProxyProductDetailsComponent, {
            showHeader: false,
            closeOnEscape: true,
            dismissableMask: false,
            styleClass: 'small-modal',
            data: selectedNode,
        });
        // Modal Close Data
        // this.ref.onClose.subscribe((data) => {
        // 	if (data) {
        // 	}
        // })
    }

    onTextSearchFilter(event, reload?: boolean) {
        const { code } = event;
        // console.log('--> setting prod hier tree filter: ', event)
        console.log('--> Search Filter Applied: ', event.target.value);

        if (code === 'Enter' || reload) {
            // if (event.target.value !== '') {
            this.searchFilterApplied = event.target.value;
            this.treeDataParams.queryString = this.initialQueryString;
            if (!this.treeDataParams.queryString) {
                this.treeDataParams.queryString = '?a=a';
            }
            this.treeDataParams.queryString =
                this.treeDataParams.queryString +
                '&search_text=' +
                event.target.value;
            this.loading = true;
            this.sizeDistroDefaultParametersAPIs
                .GetAllProducts(this.treeDataParams)
                .then(async res => {
                    if (res?.status == 200 && res.data && res.data.length > 0) {
                        this.treeData = res.data;
                        console.log(this.treeData);
                        this.wireParents(this.treeData, null);
                        this.setTopLevelNodes(false);
                        this.tree.updateSerializedValue();
                        this.nodeSelect({ node: null });
                        this.setSelectedValues([null]);
                        if (event.target.value === '' || !event.target.value) {
                            this.searchFilterApplied = null;
                            this.resetFilter();
                            this.tree.updateSerializedValue();
                            this.setTopLevelNodes();
                        }
                        this.loading = false;

                        // const getFilteredData = array => {
                        //   const getNodes = (result, object) => {
                        //     if (
                        //       this.searchFilterApplied &&
                        //                   object.label
                        //                     .toLowerCase()
                        //                     .includes(this.searchFilterApplied.toLowerCase())
                        //     ) {
                        //       result.push(object)
                        //       return result
                        //     }

                        //     if (Array.isArray(object.children)) {
                        //       const children = object.children.reduce(getNodes, [])
                        //       if (children.length) {
                        //         result.push({ ...object, children })
                        //       }
                        //     }
                        //     return result
                        //   }
                        //   return array.reduce(getNodes, [])
                        // }
                        // const setFilteredData = getFilteredData(this.treeData)

                        // if (setFilteredData) {
                        //   this.tree.filteredNodes = setFilteredData
                        //   this.expandAll(null, true)
                        // }
                    } else {
                        this.treeData = null;
                        this.loading = false;
                    }
                });
        }
    }

    emitConfirmation(event) {
        // console.log('confirm modal: ', event)
        if (event.userInput === 'except') {
            if (event.command === 'Delete Proxy Product') {
                this.loading = true;

                this.proxyProductCreationAPIs
                    .DeleteProxyProduct(this.selectedNode.key)
                    .then(res => {
                        if (res?.is_success) {
                            // this.treeData = null
                            // this.selectedNode = null
                            // this.onNodeDeleted.emit(true)
                            this.treeData = this.deleteNode(
                                this.treeData,
                                this.selectedNode.key,
                            );
                            this.deleteSelection(true);
                            this.loading = false;
                        } else {
                            this.loading = false;
                        }
                    });
            }
        }
    }
}
