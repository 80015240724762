// Angular Imports
// =========================================================
import { Component } from '@angular/core'
// AG Grid Imports
// =========================================================
import { type ITooltipAngularComp } from 'ag-grid-angular'

@Component({
  selector: 'app-metrics-custom-tooltip',
  templateUrl: './metrics-custom-tooltip.component.html',
  styleUrls: ['./metrics-custom-tooltip.component.scss']
})
export class MetricsCustomTooltipComponent implements ITooltipAngularComp {
  params: any
  data: any

  message: string = ''
  tableData: any
  isHeaderCell: boolean = false

  agInit (params): void {
    this.params = params
    const value = params?.value
    // console.log('tool tip params.value:', typeof value)
    this.isHeaderCell = params.location === 'header'

    if (typeof value !== 'string' && value.length > 0) {
      const levelMap: Record<string, number> = {}

      value.forEach(
        (item: { count: number, optimal_node: number | null }) => {
          const key =
                        item.optimal_node === null
                          ? 'N/A'
                          : item.optimal_node === -1
                            ? '-1'
                            : item.optimal_node.toString()

          levelMap[key] = (levelMap[key] || 0) + item.count
        }
      )

      this.tableData = Object.entries(levelMap)
        .map(([prodLevel, storesAtLevel]) => ({
          prodLevel,
          storesAtLevel
        }))
        .sort((a, b) => {
          if (a.prodLevel === '-1') return -1
          if (b.prodLevel === '-1') return 1
          if (a.prodLevel === 'N/A') return 1
          if (b.prodLevel === 'N/A') return -1
          return Number(a.prodLevel) - Number(b.prodLevel)
        })
    }
    //   console.log('Processed table data:', this.tableData);
  }
}
