[
    {
        "Job ID": {
            "editable": false,
            "refData": {
                "datatype": "number"
            },
            "hide": false,
            "checkboxSelection": true
        },
        "Distro ID": {
            "editable": false,
            "refData": {
                "datatype": "string"
            },
            "hide": false
        },
        "Style-Color ID": {
            "editable": false,
            "refData": {
                "custom_field": "styc_id",
                "datatype": "string"
            },
            "hide": false
        },
        "Style-Color Name": {
            "editable": false,
            "refData": {
                "custom_field": "styc_nm",
                "datatype": "string"
            },
            "hide": false
        },
        "Size Range": {
            "editable": false,
            "refData": {
                "datatype": "string"
            },
            "showToolTip": true,
            "hide": false
        },
        "Store Distro": {
            "editable": false,
            "refData": {
                "datatype": "string",
                "showToolTip": true,
                "tooltipField": "store_distro"
            },
            "hide": false
        },
        "Delivery Date": {
            "editable": false,
            "refData": {
                "datatype": "date"
            },
            "hide": false
        },
        "Unit Qty": {
            "editable": false,
            "refData": {
                "custom_field": "quantity",
                "datatype": "number"
            },
            "hide": false
        },
        "Delivery Type": {
            "editable": false,
            "refData": {
                "datatype": "string"
            },
            "hide": false
        }
    },
    {
        "Channel": {
            "editable": false,
            "refData": {
                "custom_field": "channel",
                "datatype": "string"
            },
            "hide": true,
            "rowGroup": true
        },
        "Store ID": {
            "editable": false,
            "refData": {
                "custom_field": "location_id",
                "datatype": "string"
            },
            "hide": true,
            "rowGroup": true
        },
        "Subclass Id": {
            "editable": false,
            "refData": {
                "custom_field": "subclass_id",
                "datatype": "string",
                "showToolTip": true,
                "tooltipField": "subclass_id"
            },
            "hide": false,
            "aggFunc": "firstOnlyBottomLevel"
        },
        "Carton ID": {
            "editable": false,
            "refData": {
                "datatype": "string",
                "showToolTip": true,
                "tooltipField": "carton_id"
            },
            "hide": false,
            "rowGroup": true,
            "aggFunc": "firstOnlyBottomLevel"
        },
        "Carton Qty": {
            "editable": false,
            "refData": {
                "custom_field": "qty",
                "datatype": "number"
            },
            "hide": false,
            "aggFunc": "srcDestCartonQtySum"
        },
        "Style-Color ID": {
            "editable": false,
            "refData": {
                "custom_field": "styc_id",
                "datatype": "string"
            },
            "hide": false,
            "aggFunc": "firstOnlyBottomLevel"
        }
    }
]
