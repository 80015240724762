// Angular Imports
// =========================================================
import { Component, ElementRef } from '@angular/core'
// AG Grid Imports
// =========================================================
import { type ITooltipAngularComp } from 'ag-grid-angular'
import { type ITooltipParams } from 'ag-grid-community'

@Component({
  selector: 'app-review-orders-carton_id-tooltip',
  templateUrl: './review-orders-carton_id-tooltip.component.html',
  styleUrls: ['./review-orders-carton_id-tooltip.component.scss']
})
export class CartonIDSizesTooltip implements ITooltipAngularComp {
  sizes: any[] = []
  value: any
  stycIds: any[]
  isHeaderCell: boolean = false
  packConfigs: any = {}
  packConfigsEntries: any
  isTooltipBelow: boolean = true

  constructor (private readonly elRef: ElementRef) {}

  agInit (params: ITooltipParams): void {
    console.log('carton config tooltip params', params)
    this.isHeaderCell = params.location === 'header'
    this.value = params.value
    if (!this.isHeaderCell) {
      console.log(params.context.getPackConfigs())
      this.stycIds = params.data.styc_id.split('/')
      this.stycIds.forEach(styc_id => {
        this.packConfigs[styc_id] = Object.entries(params.context.getPackConfigs()[
          params.data.job_id +
                  '_' +
                  styc_id +
                  '_' +
                  this.value
        ])
      })
      this.packConfigsEntries = Object.entries(this.packConfigs)
      console.log('packConfigs', this.packConfigs)
      console.log('packConfigsEntries', this.packConfigsEntries)
      // this.packConfigs.forEach(config => {
      //   Object.keys(config).forEach(key => {
      //     if (!this.sizes.includes(key)) {
      //       this.sizes.push(key)
      //     }
      //   })
      // })
    }
    setTimeout(() => this.adjustTooltipPosition(), 0)
  }

  adjustTooltipPosition (): void {
    const tooltipElement =
            this.elRef.nativeElement.querySelector('.custom-tooltip')
    const rect = tooltipElement.getBoundingClientRect()
    const viewportHeight =
            window.innerHeight || document.documentElement.clientHeight

    // If the tooltip is near the bottom of the page, position it above the cursor
    this.isTooltipBelow = rect.bottom + 32 < viewportHeight
  }
}
