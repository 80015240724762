<div class="main-ag-grid-container flex-column">
    <mat-card class="h-100">
        <div class="grid-toolbar">
            <div class="grid-title">
                <h3>Size Range Assignments</h3>
            </div>
            <div class="grid-tool-panel-btn-container">
                <caption *ngIf="selectedRows.length > 0">
                    {{
                        selectedRows.length
                    }}
                    Row{{
                        selectedRows.length === 1 ? '' : 's'
                    }}
                    Selected
                </caption>
                <span>
                    <button
                        mat-raised-button
                        color="accent"
                        class="primary-split-button"
                        (click)="addAssignment()"
                    >
                        Add Assignment
                    </button>
                    <button
                        id="split-button"
                        mat-raised-button
                        color="accent"
                        [matMenuTriggerFor]="moreOptions"
                        matTooltip="More Options"
                        matTooltipClass="below"
                    >
                        <mat-icon
                            aria-hidden="false"
                            aria-label="More Options"
                            class="m-0"
                        >
                            menu_open
                        </mat-icon>
                    </button>
                </span>
                <mat-menu #moreOptions="matMenu">
                    <app-confirm-popup
                        #confirmPopup
                        (confirmModal)="onDelete($event)"
                    ></app-confirm-popup>
                    <button
                        class="menu-panel-accent-btn"
                        mat-menu-item
                        color="accent"
                        (click)="saveAll()"
                        [disabled]="!gridActionsEnabled.save"
                    >
                        Save
                    </button>
                    <button
                        class="menu-panel-accent-btn"
                        mat-menu-item
                        color="accent"
                        (click)="
                            confirmPopup.confirm(
                                $event,
                                'Delete Size Range Definitions'
                            )
                        "
                        [disabled]="!gridActionsEnabled.delete"
                    >
                        Delete
                    </button>
                    <button
                        class="menu-panel-accent-btn"
                        mat-menu-item
                        color="accent"
                        (click)="markAsInUse()"
                        [disabled]="!gridActionsEnabled.markAsInUse"
                    >
                        Mark as in Use
                    </button>
                    <button
                        class="menu-panel-accent-btn"
                        mat-menu-item
                        color="accent"
                        (click)="markAsNotInUse()"
                        [disabled]="!gridActionsEnabled.markAsNotInUse"
                    >
                        Mark as Not in Use
                    </button>
                </mat-menu>
            </div>
        </div>
        <ag-grid-angular
            id="size-distro-strategy-size-range-assignments-grid"
            class="ag-theme-alpine ag-grid-container"
            [columnDefs]="columnDefs"
            [gridOptions]="gridOptions"
            (gridReady)="onGridReady($event)"
        />
    </mat-card>
</div>
