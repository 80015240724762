<mat-card *ngIf="loading" class="h-100 w-100 flex-center-all">
    <app-loader [loadingMessage]="loadingMessage"></app-loader>
</mat-card>

<mat-card *ngIf="!loading">
    <div class="grid-toolbar">
        <div class="grid-title">
            <h3>Business Rules Engine</h3>
        </div>
        <div class="grid-tool-panel-btn-container">
            <span>
                <button
                    mat-raised-button
                    color="accent"
                    class="primary-split-button"
                    (click)="addRule()"
                >
                    Add New Rule
                </button>
                <button
                    id="split-button"
                    mat-raised-button
                    color="accent"
                    [matMenuTriggerFor]="moreOptions"
                    matTooltip="More Options"
                    matTooltipClass="below"
                >
                    <mat-icon
                        aria-hidden="false"
                        aria-label="More Options"
                        class="m-0"
                    >
                        menu_open
                    </mat-icon>
                </button>
            </span>
            <mat-menu #moreOptions="matMenu">
                <button
                    class="menu-panel-accent-btn"
                    mat-menu-item
                    color="accent"
                    [disabled]="!enableBatchOperations || !canRunRules"
                    (click)="confirmBulkRun.confirm($event, 'Run Rule')"
                >
                    Run
                </button>
                <button
                    class="menu-panel-accent-btn"
                    mat-menu-item
                    color="accent"
                    [disabled]="!enableBatchOperations"
                    (click)="bulkEnableRules()"
                >
                    Enable
                </button>
                <button
                    class="menu-panel-accent-btn"
                    mat-menu-item
                    color="accent"
                    [disabled]="!enableBatchOperations"
                    (click)="bulkDisableRules()"
                >
                    Disable
                </button>
                <button
                    class="menu-panel-accent-btn delete-button"
                    mat-menu-item
                    [disabled]="!enableBatchOperations"
                    (click)="confirmBulkDelete.confirm($event, 'Delete Rule')"
                >
                    Delete
                </button>
                <app-confirm-popup
                    #confirmBulkRun
                    (confirmModal)="bulkRunRules($event)"
                />
                <app-confirm-popup
                    #confirmBulkDelete
                    (confirmModal)="bulkDeleteRules($event)"
                />
            </mat-menu>
        </div>
    </div>
    <div class="selection-buttons" *ngIf="rules && rules.length > 0">
        <button mat-button color="accent" (click)="selectAll()">
            Select All
        </button>
        <button
            mat-button
            color="primary"
            (click)="clearSelection()"
            [disabled]="!enableBatchOperations"
        >
            Clear Selection
        </button>
    </div>
    <div
        *ngIf="!rules || rules.length == 0"
        class="h-100 w-100 flex-center-all"
    >
        <h3>No rules found</h3>
    </div>
    <mat-accordion
        class="drop-list"
        cdkDropList
        (cdkDropListDropped)="drop($event)"
    >
        <div
            *ngFor="let rule of rules; let i = index"
            cdkDrag
            class="col-12 rule-container"
        >
            <mat-checkbox
                [checked]="rule.selected"
                (change)="selectionChanged(rule)"
            />
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <div class="rule-header">
                            <div class="rule-header-title">
                                <h2>{{ rule.rule_name }}</h2>
                                <div>
                                    <p>Created By:</p>
                                    <p>{{ rule.created_by }}</p>
                                </div>
                                <div>
                                    <p>Last Modified:</p>
                                    <p>{{ rule.modified_at || 'Never' }}</p>
                                </div>
                                <div>
                                    <p>Last Run:</p>
                                    <p>{{ rule.last_run || 'Never' }}</p>
                                </div>
                                <div>
                                    <p
                                        class="text-accent"
                                        *ngIf="rule.is_active"
                                    >
                                        ENABLED
                                    </p>
                                    <p
                                        class="text-warn"
                                        *ngIf="!rule.is_active"
                                    >
                                        DISABLED
                                    </p>
                                </div>
                            </div>
                        </div>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="rule-body">
                    <div class="conditions-and-actions">
                        <div class="rule-conditions">
                            <h3>Conditions</h3>
                            <div *ngFor="let condition of rule.conditions">
                                <p>{{ condition.condition_name }}</p>
                            </div>
                        </div>
                        <div class="rule-actions">
                            <h3>Actions</h3>
                            <div *ngFor="let action of rule.actions">
                                <p>{{ action.action_title }}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="rule-footer">
                    <div>
                        <p>Last Modified By:</p>
                        <p>{{ rule.modified_by || rule.created_by }}</p>
                    </div>
                </div>
                <div class="rule-buttons">
                    <button
                        mat-button
                        color="primary"
                        [disabled]="!canRunRules"
                        (click)="confirmRun.confirm($event, 'Run Rule')"
                    >
                        Run
                    </button>
                    <button mat-button color="accent" (click)="editRule(rule)">
                        Edit
                    </button>
                    <button mat-button color="accent" (click)="copyRule(rule)">
                        Copy
                    </button>
                    <button
                        mat-button
                        color="warn"
                        (click)="confirmDelete.confirm($event, 'Delete Rule')"
                    >
                        Delete
                    </button>
                </div>
                <app-confirm-popup
                    #confirmRun
                    (confirmModal)="runRule($event, rule)"
                />
                <app-confirm-popup
                    #confirmDelete
                    (confirmModal)="deleteRule($event, i)"
                />
            </mat-expansion-panel>
        </div>
    </mat-accordion>
</mat-card>
