// Angular and RJX Imports
// =========================================================
import {
    Component,
    type OnDestroy,
    type OnInit,
    ViewChild,
    HostListener,
} from '@angular/core';
import { Router } from '@angular/router';
// Ag Grid Imports
// =========================================================
import {
    type GridReadyEvent,
    type GridApi,
    type ColumnApi,
    type SelectionChangedEvent,
    type ProcessDataFromClipboardParams,
} from 'ag-grid-community';
// Prime NG Imports
// =========================================================
import { DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';
// Angular Material Imports
// =========================================================
import { MatMenuTrigger } from '@angular/material/menu';
// JSON Data
// =========================================================
import $sizeDistroManagementDetailGridJSON from '../../../05_ag-grid-configs/01_json-grid-configs/colDefs-size-distro-management-detail-grid.json';
// Custom Imports
// =========================================================
import {
    CustomGridOptions,
    DefaultGridContextMenu,
} from 'src/app/05_ag-grid-configs/02_global-settings/grid-options';
import { SideBarPanels } from 'src/app/05_ag-grid-configs/02_global-settings/grid-variables';
import { SizeDistroManagementDetailGridAPIs } from 'src/app/core/apis/size-distro-managment-detail-grid_api-calls';
import { DrillInVisualizeModalComponent } from 'src/app/04_modals/size-distro-management/drill-in-visualize-modal/drill-in-visualize-modal.component';
import { SizeDistroManagementDetailGrid_GenerateGridData } from 'src/app/05_ag-grid-configs/04_generate-colDefs/size-distro-management-detail-grid-colDefs';
import { FormatKey, IsKeyInObj } from 'src/app/utils/global_functions';
import { GeneralAPIs } from 'src/app/core/apis/general_api-calls';
import { ConfirmPopupComponent } from 'src/app/03_shared-components/01_alerts/confirm-popup/confirm-popup.component';
import { TriggerApiCallsService } from 'src/app/core/services/cancel-api-call';
import { SaveGridState } from 'src/app/05_ag-grid-configs/02_global-settings/grid-functions-general';
import { ToastAlertComponent } from 'src/app/03_shared-components/01_alerts/toast-alert/toast-alert.component';

@Component({
    selector: 'app-size-distro-management-detail-grid',
    templateUrl: './size-distro-management-detail-grid.component.html',
    styleUrls: ['./size-distro-management-detail-grid.component.scss'],
})
export class SizeDistroManagementDetailGridComponent
    implements OnInit, OnDestroy
{
    private readonly sideBarPanels: any = SideBarPanels();
    public sizeDistroManagementDetailGridJSON =
        $sizeDistroManagementDetailGridJSON;

    gridName: string = 'size_distro_management_detail_view';
    // Ag Grid Configuration
    private readonly customGridOptions: any = {
        ...CustomGridOptions,
        context: {
            componentParent: this,
            allowSaveView: true,
            gridName: this.gridName,
            pageTitle: 'Size Distro Management - Style Color Store Detail',
            updatedRows: {},
            uneditable_rows: [],
            rows_to_rebalance: [],
            rebalancedRows: [],
        },
    };

    ObjectKeys = Object.keys;
    // Ag Grid Configuration
    gridApi: GridApi;
    columnApi: ColumnApi;
    gridOptions: any = {};
    isAgGridLoading: boolean = true;
    totalRowCount: number = 0;
    // Grid Data
    columnDefs: any[] = [];
    selectedDistros: any[] = [];
    dynamicColumns: string[] = []; // Generate from the backend -> list of size columns
    // Global Vars
    loading: boolean = true;
    processingData: boolean = false;
    storedGridSettings: any = {};
    eventPointer: any;

    @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
    @ViewChild('confirmPopupRevert') confirmPopupRevert: ConfirmPopupComponent;
    @ViewChild('confirmPopupResetGrid')
    confirmPopupResetGrid: ConfirmPopupComponent;

    constructor(
        private ref: DynamicDialogRef,
        public dialogService: DialogService,
        private readonly sizeDistroManagementDetailGridAPIs: SizeDistroManagementDetailGridAPIs,
        private readonly router: Router,
        private readonly generalAPIs: GeneralAPIs,
        public triggerApiCallsService: TriggerApiCallsService,
        public toastAlert: ToastAlertComponent,
    ) {
        // Setting AG Grid Options
        this.gridOptions = {
            ...this.customGridOptions,
            sideBar: {
                toolPanels: [this.sideBarPanels.columnPanel],
                ...this.sideBarPanels.defaultLayout,
            },
            cellSelection: true,
            enableRangeSelection: true,
            enableFillHandle: true,
            onGridReady: (event: GridReadyEvent) => this.onGridReady(event),
            rowClassRules: {
                'parent-row-alert': params => {
                    const totalValue = this.dynamicColumns
                        .filter(size => params.data[size] !== '--')
                        .map(size => params.data[size])
                        .reduce(
                            (accumulator, currentValue) =>
                                accumulator + currentValue,
                            0,
                        );
                    return (
                        params.data.is_editable &&
                        Math.round(totalValue) !== 100
                    );
                },
            },
            onSelectionChanged: (event: SelectionChangedEvent) =>
                this.onSelectedDistroChange(event),
            getContextMenuItems: params =>
                this.sizeDistroManagementDetailGridContextMenu(params),
            getRowId: node => node?.data?.row_id,
            onRowDataUpdated: params =>
                params.api.refreshCells({ force: true }),
            onCellValueChanged: event => {
                const newValue = Number(event.newValue);
                const oldValue = Number(Number(event.oldValue).toFixed(2));

                if (newValue !== oldValue) {
                    const rowID = event.node.id;
                    const colId = event.colDef.colId;

                    this.updateStoredSizeChangesByRow(rowID, colId);
                    if (!event.context.rows_to_rebalance.includes(rowID)) {
                        event.context.rows_to_rebalance.push(rowID);
                    }
                    if (event.source !== 'paste') {
                        console.log('---> cell value changed: ', event);
                        // Refresh cells to update grid styling
                        event.api.refreshCells({ force: true });
                    }
                }
            },
            copyHeadersToClipboard: true,
            onPasteEnd: event => {
                event.api.refreshCells({ force: true });
            },
            processDataFromClipboard: (
                params: ProcessDataFromClipboardParams,
            ) => {
                console.log('-----> process cell data: ', params);
                const selectedRange = this.gridApi.getCellRanges();
                console.log(selectedRange[0].columns.length);
                console.log(selectedRange[0].startRow.rowIndex);
                console.log(selectedRange[0].endRow.rowIndex);
                console.log(params.data.length);
                console.log(params.data[0].length);
                if (
                    selectedRange[0].columns.length === 1 &&
                    selectedRange[0].startRow.rowIndex ===
                        selectedRange[0].endRow.rowIndex &&
                    params.data.length === 2 &&
                    params.data[0].length === 1
                ) {
                    // single cell
                    console.log('single cell');
                    return [[params.data[1][0]]]; // [0][0] is header
                }
                const headers = params.data[0];
                const rowData = params.data.slice(1);
                const columns = params.columnApi
                    .getAllGridColumns()
                    .filter(col => col.isVisible())
                    .map((col, index) => {
                        col['location_index'] = index;
                        return col;
                    });
                // the columns are not necessarily sorted in their displayed order if some of them are pinned
                // we want a true order, so we account for pinned with this sort function
                columns.sort((a: any, b: any) => {
                    if (a.getPinned() === b.getPinned()) {
                        return a.location_index - b.location_index;
                    } else if (a.isPinnedLeft()) {
                        return -1;
                    } else if (a.isPinnedRight()) {
                        return 1;
                    } else if (b.isPinnedLeft()) {
                        return 1;
                    } else {
                        return -1;
                    }
                });
                // now see if we can match up the given headers to the selected columns
                let indexOfStartRange = columns.findIndex(
                    col =>
                        col.getColId() ===
                        selectedRange[0].startColumn.getColId(),
                );
                if (indexOfStartRange == -1) {
                    indexOfStartRange = 0;
                }
                const headerOrder = [];
                columns.forEach(column => {
                    // find the position of the pasted header equivalent to this colunm's header
                    // this creates an array of indexes we can reference in the pasted row
                    headerOrder.push(
                        headers.findIndex(pastedHeader => {
                            return (
                                pastedHeader === column.getColDef().headerName
                            );
                        }),
                    );
                });
                // find first actual data instance
                const firstDataColumn = headerOrder.findIndex(num => num == 0);
                const newRowData = rowData.map(row => {
                    const newRow = headerOrder.map(pastedRowIndex => {
                        // we fetch the data from the pasted row using the old indexes in order
                        if (
                            pastedRowIndex >= 0 &&
                            pastedRowIndex < row.length
                        ) {
                            return row[pastedRowIndex];
                        } else return null;
                    });
                    if (indexOfStartRange > firstDataColumn) {
                        return newRow.slice(firstDataColumn);
                    } else {
                        return newRow.slice(indexOfStartRange);
                    }
                });
                return newRowData;
            },
        };
        // Attach the context menu to grid cells on right click
        document.addEventListener('contextmenu', event => {
            if (event.button === 2) {
                this.eventPointer = event;
            }
        });
    }

    async ngOnInit() {
        this.renderGrid();
    }

    onGridReady(event: GridReadyEvent) {
        this.gridApi = event.api;
        this.columnApi = event.columnApi;
        this.isAgGridLoading = false;
        this.gridApi.closeToolPanel();
    }

    // Render AG Grid Column Definitions
    async renderGrid() {
        let gridData = null;
        let gridParams = null;
        let sizeOrder = null;
        // Reset Grid settings
        this.selectedDistros = [];

        this.gridOptions.context = {
            ...this.gridOptions.context,
            updatedRows: {},
            uneditable_rows: [],
            rows_to_rebalance: [],
            rebalancedRows: [],
        };
        try {
            // await this.getGridState()
            const storedData = JSON.parse(
                sessionStorage.getItem('ag_grid_settings'),
            );
            if (storedData) {
                console.log(
                    '---> Inside Drillin Grid: ',
                    storedData.sdm_detail_grid,
                );
                // Get The grid data and list of dynamic cols to generate before configuring the grid
                await Promise.all([
                    this.sizeDistroManagementDetailGridAPIs.GetDynamicColDefsAndRowData(
                        {
                            distro_ids: storedData?.sdm_detail_grid?.distro_ids,
                            is_neg_1:
                                storedData?.sdm_detail_grid?.send_neg_1 ||
                                false,
                            store_list: storedData?.sdm_detail_grid?.store_list,
                        },
                    ),
                    this.generalAPIs.GetAllSizesAndOrder(),
                    this.generalAPIs.GetGridView(this.gridName),
                ]).then(data => {
                    gridParams = data[0];
                    sizeOrder = data[1];
                    this.storedGridSettings[this.gridName] = data[2]?.is_success
                        ? data[2].data
                        : {
                              colDefs: [],
                              filters: [],
                          };
                });
                // Set the Dynamic columns and sort cols by size order (max sure casing is formatted to match the AG Grid Col Id's)
                this.dynamicColumns = sizeOrder
                    .filter(size => gridParams.data.dynamic_cols.includes(size))
                    .map(size => size);

                if (gridParams && gridParams.data) {
                    console.log('---> Detail grid params: ', gridParams.data);
                    const params = {
                        mainColDefs: this.sizeDistroManagementDetailGridJSON[0],
                        dynamicCols: this.dynamicColumns,
                    };

                    gridData =
                        await SizeDistroManagementDetailGrid_GenerateGridData(
                            params,
                        );
                }
            }
        } catch (error) {
            console.error(
                '---> error generating style color store detail grid settings: ',
                error,
            );
            this.onReturnToSummaryGrid(null);
        } finally {
            if (gridData) {
                this.columnDefs = gridData.mainColDefs;

                const checkAgGrid = () => {
                    if (!this.isAgGridLoading) {
                        if (this.gridApi && !this.gridApi['destroyCalled']) {
                            this.gridApi?.setColumnDefs(gridData.mainColDefs);
                            this.gridApi?.setRowData(
                                gridParams.data.row_data || [],
                            );
                            this.totalRowCount = gridParams.data.row_data
                                ? gridParams.data.row_data.length
                                : 0;
                            this.loading = false;
                            // Apply the saved column state
                            if (
                                Object.keys(this.storedGridSettings).length >
                                    0 &&
                                this.storedGridSettings[this.gridName]
                            ) {
                                const { colDefs, filters } =
                                    this.storedGridSettings[this.gridName];
                                const numOfFilters =
                                    Object.keys(filters).length;
                                console.log('---> filters: ', filters);

                                // Dynamically generate the default grid column keys -> If new cols get added to the coldefs JSON file this function will automatically account for them
                                const defaultColIds = Object.values(
                                    this.columnDefs,
                                ).flatMap(col => {
                                    if (col.children) {
                                        return col.children
                                            .filter(
                                                child =>
                                                    !child.refData.isDynamicCol,
                                            )
                                            .map(child => child.colId);
                                    } else {
                                        return col.colId;
                                    }
                                });
                                const filteredColDefIDs = [];
                                const filteredColDefs = [];

                                // Filter out any dynamically size columns that may have been saved - the order of dynamic columns cannot be saved, to honor their required position
                                colDefs.forEach((col, i) => {
                                    if (
                                        defaultColIds.includes(col.colId) &&
                                        col.colId !== 'editable'
                                    ) {
                                        filteredColDefIDs.push(col.colId);
                                        filteredColDefs.push(col);
                                    }
                                    if (
                                        i + 1 === colDefs.length &&
                                        filteredColDefs.length > 0
                                    ) {
                                        // Apply the saved column state to the default column defs only
                                        this.gridOptions?.columnApi?.applyColumnState(
                                            {
                                                state: filteredColDefs,
                                            },
                                        );

                                        // Apply the column order to the default column defs only
                                        this.gridOptions?.columnApi?.moveColumns(
                                            filteredColDefIDs,
                                            0,
                                        );
                                        // Apply saved column filters
                                        if (numOfFilters > 0) {
                                            // Apply the saved filter state
                                            this.gridOptions?.api?.setFilterModel(
                                                filters,
                                            );
                                        }
                                    }
                                });
                            }
                        }

                        clearInterval(setData);
                    } else {
                        console.log('Ag grid is loading');
                    }
                };
                const setData = setInterval(checkAgGrid, 100);
            }
        }
    }

    // Get grid view
    async getGridState() {
        return await this.generalAPIs.GetGridView(this.gridName).then(res => {
            this.storedGridSettings[this.gridName] = res?.is_success
                ? res.data
                : {
                      colDefs: [],
                      filters: [],
                  };
        });
    }

    // Track Row Selection Changes
    onSelectedDistroChange(event) {
        this.selectedDistros = event.api.getSelectedRows();
        console.log('---> Row selection: changed: ', this.selectedDistros);

        if (this.selectedDistros.length > 0) {
            this.gridOptions.context.uneditable_rows = [];

            this.selectedDistros.forEach((distro, i) => {
                const id = distro.row_id;

                if (
                    !distro.is_editable &&
                    !this.gridOptions.context.uneditable_rows.includes(id)
                ) {
                    this.gridOptions.context.uneditable_rows.push(id);
                }
                if (i + 1 === this.selectedDistros.length) {
                    event.api.refreshCells({ force: true });
                }
            });
        }
    }

    sizeDistroManagementDetailGridContextMenu(params) {
        console.log('---> Viewing Context Menu: ', params);
        if (params.column) {
            const colId = params.column.colId;
            const selectedRow = params.api.getSelectedNodes();
            // Return To Summary Grid action button
            const ReturnToSummaryGridBtn = [
                {
                    name: 'Return To Summary Grid',
                    action: () => this.onReturnToSummaryGrid(null),
                    cssClasses: ['text-accent'],
                },
            ];
            // Save action button
            const SaveBtn = params.node.data.is_editable
                ? [
                      {
                          name: 'Save',
                          action: async () =>
                              await this.onSaveGridChanges(params),
                          disabled:
                              this.loading ||
                              this.gridOptions.context.rebalancedRows.length ===
                                  0 ||
                              this.gridOptions.context.uneditable_rows.length >
                                  0 ||
                              this.gridOptions.context.rows_to_rebalance
                                  .length > 0,
                          cssClasses: ['text-accent'],
                      },
                  ]
                : [];
            // Locking and unlocking
            const LockUnlockGroup =
                params?.column?.colDef?.refData?.isDynamicCol &&
                params.node.data.is_editable &&
                params.node.data[colId] !== '--'
                    ? [
                          {
                              name: 'Lock / Unlock Size',
                              subMenu: [
                                  {
                                      name:
                                          params?.node?.data?.locked_sizes &&
                                          params?.node?.data?.locked_sizes[
                                              colId
                                          ]
                                              ? 'Unlock Size'
                                              : 'Lock Size',
                                      action: async () =>
                                          await this.onToggleLockUnlock(
                                              params,
                                              params?.node?.data
                                                  ?.locked_sizes &&
                                                  params?.node?.data
                                                      ?.locked_sizes[colId]
                                                  ? 'unlock size'
                                                  : 'lock size',
                                          ),
                                      cssClasses: ['text-accent'],
                                  },
                                  {
                                      name: 'Lock Size Column',
                                      action: async () =>
                                          await this.onToggleLockUnlock(
                                              params,
                                              'lock col',
                                          ),
                                      cssClasses: ['text-accent'],
                                  },
                                  {
                                      name: 'Unlock Size Column',
                                      action: async () =>
                                          await this.onToggleLockUnlock(
                                              params,
                                              'unlock col',
                                          ),
                                      cssClasses: ['text-accent'],
                                  },
                                  {
                                      name: 'Unlock All Size Cells',
                                      action: async () =>
                                          await this.onToggleLockUnlock(
                                              params,
                                              'unlock all',
                                          ),
                                      cssClasses: ['text-accent'],
                                  },
                              ],
                              disabled: this.loading,
                          },
                      ]
                    : [];

            // Rebalance action button
            const RebalanceBtn = params.node.data.is_editable
                ? [
                      {
                          name: 'Rebalance',
                          action: async () => await this.onRebalance(),
                          disabled: this.loading || selectedRow.length === 0,
                          cssClasses: ['text-accent'],
                      },
                  ]
                : [];

            // Revert To system distro action button
            const RevertToTheSystemDistrosBtn = params.node.data.is_editable
                ? [
                      {
                          name:
                              selectedRow.length > 0
                                  ? 'Revert To System Distro'
                                  : 'Revert To System Distro (No Jobs Selected)',
                          action: async () =>
                              await this.confirmPopupRevert.confirm(
                                  this.eventPointer,
                                  'Revert Distros',
                              ),
                          disabled:
                              this.loading ||
                              selectedRow.length === 0 ||
                              params?.context?.uneditable_rows?.length > 0,
                          cssClasses: ['text-accent'],
                      },
                  ]
                : [];

            // Reset Grid Data
            const ResetGridDataBtn = [
                {
                    name: 'Reset Grid Data',
                    action: async () =>
                        await this.confirmPopupResetGrid.confirm(
                            this.eventPointer,
                            'Reset Grid Data',
                        ),
                    disabled:
                        this.loading ||
                        (Object.keys(params?.context?.updatedRows).length ===
                            0 &&
                            params?.context.rebalancedRows.length === 0),
                    cssClasses: ['text-warn'],
                },
            ];
            // Open the Visualize modal
            const VisualizeBtn = [
                {
                    name: 'Visualize',
                    action: async () => await this.onOpenVisualizeModal(null),
                    disabled:
                        this.loading ||
                        this.selectedDistros.length >= 2000 ||
                        (this.totalRowCount >= 2000 &&
                            this.selectedDistros.length === 0),
                    cssClasses: ['text-accent'],
                },
            ];

            const result = [
                ...ReturnToSummaryGridBtn,
                'separator',
                ...SaveBtn,
                ...LockUnlockGroup,
                ...RebalanceBtn,
                ...RevertToTheSystemDistrosBtn,
                ...ResetGridDataBtn,
                'separator',
                ...VisualizeBtn,
                'separator',
                ...DefaultGridContextMenu(params),
            ];

            return result;
        } else {
            return DefaultGridContextMenu(params);
        }
    }

    // Drill In Visualization Modal
    async onOpenVisualizeModal(event) {
        console.log('open bulk override distros modal');
        let rowData = [];
        try {
            if (this.selectedDistros && this.selectedDistros.length > 0) {
                rowData = this.getMappedRowData();
            } else {
                await this.gridApi.forEachNode(node => {
                    if (node.data) rowData.push(node.data);
                });
            }
        } finally {
            this.ref = this.dialogService.open(DrillInVisualizeModalComponent, {
                showHeader: false,
                closeOnEscape: true,
                dismissableMask: false,
                styleClass: 'large-modal',
                data: {
                    componentParent: this,
                    rowData,
                },
            });
        }
    }

    // On return to Summary grid
    onReturnToSummaryGrid(event) {
        console.log('---> Returning To Summary Grid');
        this.router.navigateByUrl('/size-distro-management');
    }

    async onToggleLockUnlock(params: any, action: string) {
        this.gridApi.clearFocusedCell();

        const lockedSizes = IsKeyInObj(params.node.data, 'locked_sizes')
            ? params?.node.data?.locked_sizes
            : {};
        const colIdToUpdate = params.column.colId;
        const rowsToUpdate = [];

        console.log('---> Toggling Lock / Unlock: ', params);
        // console.log('---> Action: ', action)
        switch (action) {
            case 'lock size':
            case 'unlock size':
                return params.api.applyTransaction({
                    update: [
                        {
                            ...params.node.data,
                            locked_sizes: {
                                ...lockedSizes,
                                [colIdToUpdate]: action === 'lock size',
                            },
                        },
                    ],
                });
            case 'unlock all':
                try {
                    await params.api.forEachNode(node => {
                        const { data } = node;

                        if (data && IsKeyInObj(data, 'locked_sizes')) {
                            const valMap = [
                                ...new Set(Object.values(data?.locked_sizes)),
                            ];
                            if (valMap.includes(true)) {
                                rowsToUpdate.push({
                                    ...data,
                                    locked_sizes: {},
                                });
                            }
                        }
                    });
                } finally {
                    params.api.applyTransactionAsync({ update: rowsToUpdate });
                }
                break;
            case 'lock col':
            case 'unlock col':
                try {
                    await params.api.forEachNode(node => {
                        const { data } = node;
                        if (data?.is_editable) {
                            console.log('---> data: ', data);
                            const lockedRowSizes = data?.locked_sizes || {};
                            const isColSizeLocked =
                                lockedRowSizes[colIdToUpdate] || false;
                            if (
                                (action === 'lock col' && !isColSizeLocked) ||
                                (action === 'unlock col' && isColSizeLocked)
                            ) {
                                rowsToUpdate.push({
                                    ...data,
                                    locked_sizes: {
                                        ...lockedRowSizes,
                                        [colIdToUpdate]: !isColSizeLocked,
                                    },
                                });
                            }
                        }
                    });
                } catch (error) {
                    console.error('---> error locking/unlocking all cols');
                } finally {
                    params.api.applyTransactionAsync({ update: rowsToUpdate });
                }
                break;
        }
    }

    async onRebalance() {
        this.gridApi.clearFocusedCell();
        const selectedRows = this.gridApi.getSelectedNodes();
        const { rows_to_rebalance } = this.gridOptions.context;
        console.log('---> Rebalancing Grid Rows: ', selectedRows);
        console.log('---> dynamic Columns: ', this.dynamicColumns);
        this.loading = true;
        const rowsToUpdate = [];

        try {
            await selectedRows.forEach(async (row, rowIndex) => {
                console.log('---> row: ', row);
                const hasLockedSizes = IsKeyInObj(row.data, 'locked_sizes');
                const { data, id } = row;
                const rowId = `${id}`;
                console.log('----> id: ', rowId);
                if (data.is_editable) {
                    const unLockedSizes = [];
                    let finalTotal: number = 0;
                    let totalUnlocked = 0;
                    let available = 100;
                    const availableSizes = [];
                    try {
                        await this.dynamicColumns.forEach((dSize, i) => {
                            const size = dSize;
                            // Ignore sizes in rebalance that are not part of the distro
                            if (data[size] !== '--') {
                                availableSizes.push(size);
                                const lockObjHasSize = IsKeyInObj(
                                    data.locked_sizes,
                                    size,
                                );
                                // get the total taken up by the unlocked cells
                                if (
                                    !hasLockedSizes ||
                                    (hasLockedSizes &&
                                        (!lockObjHasSize ||
                                            !data?.locked_sizes[size]))
                                ) {
                                    unLockedSizes.push(size);
                                    totalUnlocked += Number(data[size]);
                                } else if (
                                    // get the maximum allowed total of the unlocked cells by subtracting the locked ones from 100
                                    hasLockedSizes &&
                                    lockObjHasSize &&
                                    data?.locked_sizes[size]
                                ) {
                                    available -= Number(data[size]);
                                }
                                if (this.dynamicColumns.length === i + 1) {
                                    if (available <= 0) {
                                        // total locked exceeds 100
                                        console.error(
                                            'cant rebalance, total is ',
                                            available,
                                        );
                                        this.toastAlert.showError(
                                            'Cannot Rebalance Rows. The sum of locked cells exceeds 100.',
                                        );
                                    } else {
                                        // Account for the scenario where  only 0 values exist in the row data
                                        const allZeros =
                                            totalUnlocked === 0 &&
                                            available === 100;
                                        const totalToDistribute = allZeros
                                            ? availableSizes.length
                                            : totalUnlocked;

                                        // Get the ratio
                                        const ratio =
                                            available / totalToDistribute;
                                        // apply ratio to the unlocked cells
                                        const distributeValues =
                                            unLockedSizes.map((uLSize, sI) => {
                                                const sizeVal = allZeros
                                                    ? 1
                                                    : data[uLSize];
                                                const totalVal = Number(
                                                    sizeVal * ratio,
                                                );

                                                // Set the values
                                                finalTotal += totalVal;
                                                data[uLSize] = totalVal;
                                                // Update the array of stored size changes
                                                this.updateStoredSizeChangesByRow(
                                                    rowId,
                                                    uLSize,
                                                );

                                                if (
                                                    unLockedSizes.length ===
                                                    sI + 1
                                                ) {
                                                    const remainder =
                                                        available -
                                                        Number(finalTotal);
                                                    data[uLSize] +=
                                                        Number(remainder);
                                                }

                                                return uLSize;
                                            });

                                        // If the row has user entered changes remove them
                                        if (distributeValues) {
                                            if (
                                                rows_to_rebalance.includes(
                                                    rowId,
                                                )
                                            ) {
                                                this.gridOptions.context.rows_to_rebalance.splice(
                                                    rows_to_rebalance.indexOf(
                                                        rowId,
                                                    ),
                                                    1,
                                                );
                                            }
                                            if (
                                                !this.gridOptions.context.rebalancedRows.includes(
                                                    rowId,
                                                )
                                            ) {
                                                this.gridOptions.context.rebalancedRows.push(
                                                    rowId,
                                                );
                                            }
                                        }
                                    }
                                }
                            }
                        });
                    } catch (error) {
                        console.error(
                            '---> Error calculating new row values: ',
                            error,
                        );
                    } finally {
                        rowsToUpdate.push(data);
                        return data;
                    }
                }
            });
        } catch (error) {
            console.error('---> Error Rebalancing Grid Rows');
        } finally {
            console.log('---> Final Rebalanced rows to Update', rowsToUpdate);
            this.loading = false;
            if (rowsToUpdate.length > 0) {
                this.gridApi.applyTransaction({ update: rowsToUpdate });
            }
        }
    }

    // Update the array of stored size changes
    updateStoredSizeChangesByRow(rowId, size) {
        const storedUpdates = this.gridOptions.context?.updatedRows;
        if (IsKeyInObj(storedUpdates, rowId)) {
            if (!storedUpdates[rowId].includes(size)) {
                this.gridOptions.context.updatedRows[rowId].push(size);
            }
        } else {
            this.gridOptions.context.updatedRows[rowId] = [size];
        }
    }

    // Get the current selected row data
    getMappedRowData = () => this.gridApi.getSelectedRows();
    // Remove a single row from the list of rows to rebalance
    removeRebalancedRow = (rowID: string) => {
        const rebalancedRows = this.gridOptions.context.rebalancedRows;
        if (rebalancedRows.includes(rowID)) {
            this.gridOptions.context.rebalancedRows.splice(
                rebalancedRows.indexOf(rowID),
                1,
            );
        }
    };

    // Reset the grid
    onResetGridData(event) {
        console.log('---> Resetting Grid Data: ', event);
        if (event.userInput === 'except') {
            this.resetGridData();
        }
    }

    resetGridData() {
        const savedState = this.gridOptions.columnApi.getColumnState();
        const appliedFilters = this.gridOptions.api.getFilterModel();
        this.storedGridSettings = {
            colDefs: savedState,
            filters: appliedFilters,
        };
        this.loading = true;
        // this.rebalancedRows = []
        // this.gridOptions.context.updatedRows = {}
        this.gridOptions.context = {
            ...this.gridOptions.context,
            updatedRows: {},
            uneditable_rows: [],
            rows_to_rebalance: [],
            rebalancedRows: [],
        };
        this.gridApi.setRowData([]);
        this.gridApi.showLoadingOverlay();
        this.renderGrid();
    }

    initializeGridUpdate() {
        this.gridApi.clearFocusedCell();
        //  Show loading overlay
        this.gridApi.showLoadingOverlay();
        this.loading = true;
    }

    // Save Grid Changes
    async onSaveGridChanges(event) {
        console.log('---> Saving Grid Changes: ');
        const dataToSend = this.getMappedRowData();
        console.log('---> Data to send: ', dataToSend);
        try {
            this.initializeGridUpdate();
            // Save Grid State
            await SaveGridState(this.gridOptions, this.gridName);
        } finally {
            await this.sizeDistroManagementDetailGridAPIs
                .SaveChanges({ row_data: dataToSend })
                .then(res => {
                    if (res?.is_success) {
                        this.resetGridData();
                    } else {
                        // Hide loading overlay
                        this.gridApi.hideOverlay();
                        this.loading = false;
                    }
                    this.gridApi.deselectAll();
                });
        }
    }

    // Revert To system distros
    async onRevertToSystemDistros(event) {
        console.log(
            '---> Reverting To System Distros',
            this.getMappedRowData(),
        );
        if (event.userInput === 'except') {
            const dataToSend = this.getMappedRowData().map(row => {
                return { distro_id: row.distro_id, location_id: row.store_id };
            });

            try {
                this.initializeGridUpdate();
                // Save Grid State
                await SaveGridState(this.gridOptions, this.gridName);
            } finally {
                // An API call to revert distro
                await this.sizeDistroManagementDetailGridAPIs
                    .RevertToSystemDistros(dataToSend)
                    .then(res => {
                        if (res?.is_success) {
                            // this.renderGrid()
                            this.resetGridData();
                        } else {
                            // Hide loading overlay
                            this.gridApi.hideOverlay();
                            this.loading = false;
                        }
                        this.gridApi.deselectAll();
                    });
            }
        }
    }

    @HostListener('unloaded')
    ngOnDestroy() {
        this.triggerApiCallsService.onTriggerApiCalls({
            clear_api_calls: true,
        });
        if (this.gridApi) {
            this.gridApi.flushAsyncTransactions();
            this.gridApi.expireValueCache();
            this.gridApi = null;
        }
    }
}
