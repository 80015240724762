<app-modal-header
    modalTitle="Add Size Range Assignment"
    [showCloseBtn]="true"
    (closeModal)="close()"
/>

<div
    class="modal-content custom-vertical-scroll"
    [attr.loading]="loading"
    [attr.size]="'medium'"
>
    <div class="form-fields-container" *ngIf="!loading">
        <div class="form-fields-container-inputs">
            <app-form-fields
                [formParams]="size_range_assignments_form"
                controlName="size_range_name"
                (onFormFieldUpdated)="onFormUpdatedFunction($event)"
            />

            <div class="form-fields-span">
                <app-form-fields
                    [formParams]="size_range_assignments_form"
                    controlName="product_id"
                    (onFormFieldUpdated)="onFormUpdatedFunction($event)"
                />

                <app-form-fields
                    [formParams]="size_range_assignments_form"
                    controlName="eligible_channel"
                    (onFormFieldUpdated)="onFormUpdatedFunction($event)"
                />
            </div>

            <div class="form-fields-span">
                <app-form-fields
                    [formParams]="size_range_assignments_form"
                    controlName="is_future"
                    (onFormFieldUpdated)="onFormUpdatedFunction($event)"
                />

                <app-form-fields
                    [formParams]="size_range_assignments_form"
                    controlName="historical_size_range"
                    (onFormFieldUpdated)="onFormUpdatedFunction($event)"
                    [disabled]="!isFuture"
                />
            </div>
        </div>
        <div class="form-fields-container-buttons">
            <button
                mat-raised-button
                color="accent"
                (click)="submit($event)"
                [disabled]="
                    invalidFields === undefined || invalidFields.length > 0
                "
            >
                Create Size Range Assignment
            </button>

            <button mat-button (click)="reset()">Reset</button>
        </div>
    </div>
    <div *ngIf="loading" class="w-100 h-100">
        <app-loader [loadingMessage]="loadingMessage"></app-loader>
    </div>
</div>

<app-modal-footer
    (closeModal)="close()"
    [closeBtnText]="'Close'"
    [showUpdateBtn]="false"
/>
