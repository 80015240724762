import { Injectable } from '@angular/core'
import {
  ExecuteDeleteAPI,
  ExecuteGetAPI,
  ExecutePutAPI
} from './execute_api-calls'
import axios from 'axios'
import { DialogService } from 'primeng/dynamicdialog'
import { ToastAlertComponent } from '../../03_shared-components/01_alerts/toast-alert/toast-alert.component'

interface SizeRangeAssignment {
  is_future: boolean
  in_use: boolean
  product_id: string
  size_range_name: string
  eligible_channel: string
}

interface DropdownValues {
  channels: string[]
  products: string[]
  size_range_names: string[]
}

@Injectable()
export class SizeRangeAssignmentsAPIs {
  constructor (
    public dialogService: DialogService,
    public toastAlert: ToastAlertComponent
  ) {}

  async GetAllSizeRangeAssignments () {
    const path = '/size_range_assignments'
    const messages = {
      success: '---> API Call Successful: Get All Size Range Assignments',
      error: 'Error retrieving rowData for the Size Range Assignments Grid.'
    }

    return await this.ExecuteGet(path, messages)
  }

  async UpdateSizeRangeAssignment (data: SizeRangeAssignment) {
    const path = '/size_range_assignments'
    const messages = {
      success: `Successfully updated size range assignment for ${data.size_range_name}.`,
      error: `Error updating size range assignment for ${data.size_range_name}.`,
      showSuccessToast: true
    }

    return await ExecutePutAPI(path, this, data, messages)
  }

  async DeleteSizeRangeAssignments (data: SizeRangeAssignment[]) {
    const path = '/size_range_assignments'
    const messages = {
      success: 'Successfully deleted size range assignments.',
      error: 'Error deleting size range assignments.',
      showSuccessToast: true
    }

    return await ExecuteDeleteAPI(path, this, messages, data)
  }

  async GetDropdownValues (): Promise<DropdownValues> {
    const path = '/size_range_assignments/dropdowns'
    const messages = {
      success: '---> API Call Successful: Get Dropdown Values',
      error: 'Error retrieving Dropdown Values.'
    }

    return await this.ExecuteGet(path, messages)
  }

  private async ExecuteGet (
    path: string,
    messages: { success: string, error: string }
  ) {
    const apiResponse = await ExecuteGetAPI(path, this, messages)

    if (apiResponse && apiResponse.is_success) {
      const axiosResponse = await axios.get(apiResponse.data, {})

      if (axiosResponse && axiosResponse.data) {
        // console.log('data: ', axiosResponse.data)
        return axiosResponse.data
      }
    }

    return [{}]
  }
}
