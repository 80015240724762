<div [style.paddingLeft.px]="paddingLeft">
    @if (isGroup) {
        <div
            [style.cursor]="'pointer'"
            [style.display]="'inline-block'"
            (click)="onClick()"
        >
            <span *ngIf="!expanded" class="ag-icon ag-icon-tree-close" role="presentation"></span>            
            <span *ngIf="expanded" class="ag-icon ag-icon-tree-open" role="presentation"></span>
        </div>
        &nbsp;
        {{ params.value }}
    }
</div>