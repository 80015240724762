// Angular and RJX Imports
// =========================================================
import { Component, ViewChild, type OnInit } from '@angular/core'
import {
  BehaviorSubject,
  debounceTime,
  Subject,
  type Subscription
} from 'rxjs'
import { cloneDeep } from 'lodash'
// Prime NG Imports
// =========================================================
import {
  DynamicDialogRef,
  DynamicDialogConfig,
  DialogService
} from 'primeng/dynamicdialog'
// Custom Imports
// =========================================================
import { TriggerApiCallsService } from 'src/app/core/services/cancel-api-call'
import { ToastAlertComponent } from 'src/app/03_shared-components/01_alerts/toast-alert/toast-alert.component'
import { SizeRangeDefinitionFormSettings } from 'src/app/03_shared-components/forms/form-data/size-range-definitions'
import { SizeRangeDefinitionsAPIs } from 'src/app/core/apis/size-range-definitions_api-calls'
import { GeneralAPIs } from 'src/app/core/apis/general_api-calls'
import { type FormGroup } from '@angular/forms'
import { FormFieldsComponent } from 'src/app/03_shared-components/forms/form-fields/form-fields.component'
@Component({
  selector: 'app-size-range-definition-modal',
  templateUrl: './size-range-definition-modal.component.html',
  styleUrls: ['./size-range-definition-modal.component.scss']
})
export class SizeRangeDefinitionModalComponent implements OnInit {
  loading: boolean = true
  loadingMessage: string = 'loading'
  formCtrlSub: Subscription

  sizeRangeNameValid = false
  sizesValid = false

  sizesDefaultValue = []
  nameDefaultValue = ''

  // Import the original form settings and track updates

  default_SRD_formSettings = cloneDeep({
    ...SizeRangeDefinitionFormSettings
  })

  size_range_definitions_form = new BehaviorSubject<any>(
    SizeRangeDefinitionFormSettings.size_range_definitions_config
  )

  dataToSend = {
    size_range_name: null,
    sizes: []
  }

  get _size_range_definitions_form () {
    return this.size_range_definitions_form.getValue()
  }

  parentGridData: any

  // Locations For Store to Store Option ONLY
  sizeOptions: string[] = []

  sizeRangeDefinitionErrors = {
    size_range_name: [],
    sizes: []
  }

  @ViewChild('nameFormID') nameFormComponent: FormFieldsComponent
  @ViewChild('sizesFormID') sizesFormComponent: FormFieldsComponent

  constructor (
    private readonly ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public dialogService: DialogService,
    private readonly sizeRangeDefinitionsAPIs: SizeRangeDefinitionsAPIs,
    public triggerApiCallsService: TriggerApiCallsService,
    public toastAlert: ToastAlertComponent,
    public generalAPIs: GeneralAPIs
  ) {
    console.log('---> Size Range Definition Modal: ', config.data)
  }

  async ngOnInit () {
    await this.generalAPIs.GetAllSizesAndOrder().then(res => {
      console.log(
        'this.config.data.existingSizeRangeNames',
        this.config.data.existingSizeRangeNames
      )
      console.log('---> Size Order: ', res)
      const { size_range_definitions_config } = cloneDeep({
        ...this.default_SRD_formSettings
      })
      size_range_definitions_config.sizes.selectionOptions = res
      size_range_definitions_config.size_range_name.validatorOptions.checkForExistingValueInput =
                this.config.data.existingSizeRangeNames
      this.size_range_definitions_form.next(
        size_range_definitions_config
      )
      this.loading = false
    })
  }

  // Form Changes
  onFormUpdatedFunction (form) {
    console.log('---> Size Range Definition Form Updated', form)

    // const field = form.controlName
    // const value = cloneDeep(form.value)
    console.log('--> Form Validation for: ', form.controlName)
    console.log('--> status: ', form.status)
    console.log('---> dataToSend: ', this.dataToSend)
    const { status, controlName, value } = cloneDeep(form)
    const formData = this.size_range_definitions_form.getValue()
    const currentVal = cloneDeep(formData[controlName].currentVal)

    switch (status) {
      case 'VALID':
        console.log('valid response', form)
        console.log('formData', formData)

        if (value !== currentVal) {
          formData[controlName].currentVal = value
          // this[`${formName}_form`].next(formData)
        }
        if (controlName === 'size_range_name') {
          this.sizeRangeNameValid = true
        } else {
          this.sizesValid = true
        }
        this.dataToSend[controlName] = form.value
        break
      case 'DISABLED':
      case 'INVALID':
        console.log('field is disabled: ', form)
        if (controlName === 'size_range_name') {
          this.sizeRangeNameValid = false
        } else {
          this.sizesValid = false
        }
        break
      default:
        console.error('field error: ', form)
    }
  }

  // Submit new size definition
  async onSubmit (event) {
    this.loadingMessage = 'submitting'
    this.loading = true
    const resetLoader = () => {
      this.loading = false
      this.loadingMessage = 'loading'
    }

    console.log('---> Submit Size Range Definition: ')

    this.sizeRangeDefinitionsAPIs
      .CreateSizeRangeDefinition(this.dataToSend)
      .then(res => {
        if (res?.is_success) {
          console.log('is success!')
          resetLoader()
          setTimeout(() => this.onReset(), 100) // timeout bc this depends on components being rendered, and they don't instantly come back after loader resets
        } else {
          resetLoader()
        }
      })
  }

  onReset () {
    this.sizesValid = false
    this.sizeRangeNameValid = false
    this.nameFormComponent.selectionForm.reset()
    this.sizesFormComponent.selectionForm.reset()
  }

  close (data?: any) {
    this.config.data.close()
    if (this.ref) this.ref.close(data || null)
  }
}
