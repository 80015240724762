// Angular and RJX Imports
// =========================================================
import { Injectable } from '@angular/core'
// Prime NG Imports
// =========================================================
import { DialogService } from 'primeng/dynamicdialog'
// Axios Imports
// =========================================================
import axios from 'axios'
// Custom Imports
// =========================================================
import { ToastAlertComponent } from 'src/app/03_shared-components/01_alerts/toast-alert/toast-alert.component'
import { ExecuteGetAPI, ExecutePostAPI } from './execute_api-calls'
import { type SaveGridViewInterface } from '../interfaces/data-expected-from-backend/size-distro-management-summary'
import { TriggerApiCallsService } from '../services/cancel-api-call'
import { ReverseFormatKey } from 'src/app/utils/global_functions'

@Injectable()
export class GeneralAPIs {
  clearApiCalls: boolean = false

  constructor (
    public dialogService: DialogService,
    public toastAlert: ToastAlertComponent,
    public triggerApiCallsService: TriggerApiCallsService
  ) {
    // ApiCall Service
    this.triggerApiCallsService.triggerApiCalls$.subscribe({
      next: data => {
        this.clearApiCalls = data['clear_api_calls']
      }
    })
  }

  async GetAllSizesAndOrder (only_sizes = true) {
    // the api call path with the parameter string is needed
    const path = '/sizes/sizeorder'
    const messages = {
      success:
                '---> API Call Successful: Retrieved All Sizes and Size Order',
      error: 'Error retrieving all sizes and size order.'
    }

    const response = await ExecuteGetAPI(path, this, messages)
    if (response && response.is_success && response.data.length > 0) {
      let sortedSizes = response.data.sort(
        (a, b) => a.size_order - b.size_order
      )
      if (only_sizes) {
        sortedSizes = sortedSizes.map(sizes => sizes.product_size)
      }
      // console.log('sorted sizes: ', sortedSizes);
      return sortedSizes
    } else {
      return []
    }
  }

  async GetStycsForNode (node: string) {
    const path = `/products/${node.replaceAll('/', '%2F')}/stycs`
    const messages = {
      success: `---> API Call Successful: Retrieved All Style-Colors for Node ${node}`,
      error: `Error retrieving all Style-Colors for Node ${node}.`
    }

    const response = await ExecuteGetAPI(path, this, messages)
    // if (response && response.is_success && response.data.length > 0) {
    //   return response.data
    // } else {
    //   return []
    // }
    if (response?.is_success) {
      if (response.data) {
        return await axios
          .get(response.data, {})
          .then(response => {
            console.log('response: ', response)
            return response
          })
          .catch(error => {
            console.error(
              'Error retrieving all Style-Colors',
              error
            )
            this.toastAlert.showError('Error Loading Data')
            throw error
          })
      } else {
        return {
          status: 200,
          data: []
        }
      }
    } else {
      return {
        status: null,
        data: []
      }
    }
  }

  // products/parameters

  async GetProductParameterSelectionOptions () {
    // the api call path with the parameter string is needed
    const path = '/products/parameters'
    const messages = {
      success:
                '---> API Call Successful: Retrieved All Product Parameter Selection Options',
      error: 'Error retrieving product parameter selection options.'
    }

    const response = await ExecuteGetAPI(path, this, messages)

    return response.data
  }

  async GetListOfAllProdIds () {
    // the api call path with the parameter string is needed
    const path = '/products/productslist'
    const messages = {
      success: '---> API Call Successful: Retrieved All Product Ids',
      error: 'Error retrieving a list of all product IDs.'
    }

    const productsList = await ExecuteGetAPI(path, this, messages)
    console.log('productsList: ------>', productsList)
    if (productsList && productsList?.is_success) {
      return await axios
        .get(productsList.data, {})
        .then(response => {
          console.log('response: ------>', response)
          return response.data
        })
        .catch(error => {
          console.error(
            'Error retrieving drill in grid data: ',
            error
          )
          this.toastAlert.showError('Error Loading Data')
          throw error
        })
    }
  }

  // Check if the given prod IDs already exist
  async CheckProdIdDuplicates (data: string[]) {
    const path = '/products/duplicates'
    const messages = {
      success:
                '---> API Call Successful: Checked product IDs for duplicates',
      error: 'Error checking product IDs for duplicates.'
    }
    return await ExecutePostAPI(
      path,
      this,
      { check_products: data[0] },
      messages
    )
  }

  // Save Grid View to database
  async SaveGridView (
    data: SaveGridViewInterface,
    displayToast: boolean = false
  ) {
    const path = `/users/view/${data.gridName}`
    const messages = {
      success: `Successfully saved the ${ReverseFormatKey(
                data.gridName
            )} view.`,
      error: `Error saving the ${ReverseFormatKey(data.gridName)}.`,
      showSuccessToast: displayToast
    }
    return await ExecutePostAPI(path, this, data.columnState, messages)
  }

  async GetAllJobSelectionOptions () {
    // the api call path with the parameter string is needed
    const path = '/jobs/selection_values'
    const messages = {
      success:
                '---> API Call Successful: Retrieved All Job Selection Options',
      error: 'Error retrieving job selection options.'
    }

    const response = await ExecuteGetAPI(path, this, messages)
    if (response && response.is_success) {
      return response.data
    } else {
      return null
    }
  }

  // Get grid view from database
  async GetGridView (grid: string) {
    // console.log('get grid view', grid)
    const path = `/users/view/${grid}`
    const messages = {
      success: `---> API Call Successful: Retrieved Grid Settings for ${grid}`,
      error: `Error retrieving the ${ReverseFormatKey(grid)}.`
    }
    return await ExecuteGetAPI(path, this, messages)
  }
}
