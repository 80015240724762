<div class="multi-select-container">
    <mat-form-field
        appearance="fill"
        [style.width]="params.column.actualWidth + 'px'"
        [style.height]="34 + 'px'"
    >
        <input
            matInput
            #input
            [id]="propertyName"
            [(ngModel)]="inputValue"
            (ngModelChange)="updateFilter($event)"
            placeholder="Search..."
            class="input-search-field"
            color="accent"
        />
        <mat-icon class="drop-up" matSuffix (click)="closeGrid()"
            >arrow_drop_up</mat-icon
        >
    </mat-form-field>
    <ag-grid-angular
        [style.height]="gridHeight + 'px'"
        [style.width]="gridWidth + 'px'"
        class="ag-theme-alpine ag-grid-container ag-popout-grid"
        [gridOptions]="gridOptions"
        [rowData]="rowData"
        [columnDefs]="columnDefs"
        (gridReady)="onGridReady($event)"
    >
    </ag-grid-angular>
</div>
