// AG Grid Imports
// =========================================================
import { type IAggFuncParams } from 'ag-grid-community'

export const AbsSumAggFunc = params => {
  let total = 0
  params.values.forEach(val => {
    if (val < 0) {
      val = -val
    }
    total += val
  })
  return total
}

export const ValueArrayAggFunc = params => {
  const flatVals = params.values.flatMap(array => array)

  const set = {}
  flatVals.forEach(val => {
    set[val] = true
  })

  // console.log("flatVals set at level " + params.rowNode.level, Object.keys(set))
  return Object.keys(set)
}

export const ParseFloatSumAggFunc = params => {
  let total = 0
  params.values.forEach(value => {
    total += parseFloat(value)
  })
  return total
}

export const DisplayFirstChildValAggFunc = params => {
  if (params.rowNode.level <= 1) {
    return params.values[0]
  } else return 'firstlevel'
}

// Custom Agg function for the IMU column -> totals are not displayed
export const HideAggTotalsAggFunc = params => {
  return '--'
}

export const CustomAverageAggFunc = (params: IAggFuncParams) => {
  // the average will be the sum / count
  let sum = 0
  let count = 0
  params.values.forEach(value => {
    const groupNode =
            value !== null && value !== undefined && typeof value === 'object'
    if (groupNode) {
      // we are aggregating groups, so we take the
      // aggregated values to calculated a weighted average
      sum += value.avg * value.count
      count += value.count
    } else {
      // skip values that are not numbers (ie skip empty values)
      if (typeof value === 'number') {
        sum += value
        count++
      }
    }
  })
  // avoid divide by zero error
  let avg = null
  if (count !== 0) {
    avg = sum / count
  }
  // the result will be an object. when this cell is rendered, only the avg is shown.
  // however when this cell is part of another aggregation, the count is also needed
  // to create a weighted average for the next level.
  const result = {
    count,
    avg,
    value: avg,
    // the grid by default uses toString to render values for an object, so this
    // is a trick to get the default cellRenderer to display the avg value
    toString: function () {
      return `${avg}`
    }
  }
  return result
}

export const TotalCartonQtyFunc = (params: IAggFuncParams) => {
  // at a low level, take the max, but at higher ones take the sum
  console.log('params for totalcartyonqtyfunc', params)
  console.log('rownode.level', params.rowNode.level)
  if (params.rowNode.level === 2) {
    return params.rowNode.allLeafChildren.reduce(
      (accumulator, childNode) => {
        if (childNode.data.pack_ttl > accumulator) {
          accumulator = childNode.data.pack_ttl
        }
        return accumulator
      },
      0
    )
  } else {
    return params.rowNode.allLeafChildren.reduce(
      (accumulator, childNode) => {
        accumulator += childNode.data.pack_ttl
        return accumulator
      },
      0
    )
  }
}

export const CombineStycsWithSlashAggFunc = (params: IAggFuncParams) => {
  return params.values.join('/')
}

export const CombineStycsWithSlashNoTopLevelAggFunc = (params: IAggFuncParams) => {
  console.log('CombineStycsWithSlashNoTopLevelAggFunc', params)
  if (params.rowNode.level > 1) {
    return params.values.join('/')
  } else {
    return '--'
  }
}

export const SrcDestCartonQtySumAggFunc = (params: IAggFuncParams) => {
  if (params.rowNode.level > 1) {
    return params.values[0]
  } else {
    return params.values.reduce((a, b) => { return a + b }, 0)
  }
}

export const FirstOnlyBottomLevelAggFunc = (params: IAggFuncParams) => {
  if (params.rowNode.level > 1) {
    return params.values[0]
  } else {
    return '--'
  }
}
