import { Component, type OnInit, ViewChild } from '@angular/core'
import type {
  ColumnApi,
  GridApi,
  GridReadyEvent, IRowNode,
  NewValueParams,
  SelectionChangedEvent
} from 'ag-grid-community'
import { SizeRangeAssignmentsAPIs } from 'src/app/core/apis/size-range-assignment_api-calls'
import sizeRangeAssignmentsColDefsJSON from 'src/app/05_ag-grid-configs/01_json-grid-configs/colDefs-main-size-range-assignments.json'
import { SizeRangeAssignments_GenerateGridData } from '../../../05_ag-grid-configs/04_generate-colDefs/size-range-assignments-colDefs'
import {
  CustomGridOptions,
  DefaultGridContextMenu
} from '../../../05_ag-grid-configs/02_global-settings/grid-options'
import { DialogService } from 'primeng/dynamicdialog'
import { SizeRangeAssignmentsModalComponent } from '../../../04_modals/size-range-assignments-modal/size-range-assignments-modal.component'
import { ConfirmPopupComponent } from '../../../03_shared-components/01_alerts/confirm-popup/confirm-popup.component'

interface SizeRangeAssignment {
  edited: boolean
  is_future: boolean
  in_use: boolean
  product_id: string
  size_range_name: string
  eligible_channel: string
}

@Component({
  selector: 'app-size-range-assignments',
  templateUrl: './size-range-assignments.component.html',
  styleUrls: ['./size-range-assignments.component.scss']
})
export class SizeRangeAssignmentsComponent implements OnInit {
  eventPointer: any
  selectedRows: IRowNode[] = []
  columnDefs: any[] = []
  gridOptions: any = {}
  gridApi: GridApi
  columnApi: ColumnApi
  isAgGridLoading: boolean = true
  sizeRangeAssignmentsColDefs = sizeRangeAssignmentsColDefsJSON
  multiSelectOptions: any = {
    channels: [],
    products: [],
    size_range_names: [],
    historical_size_range: []
  }

  gridActionsEnabled = {
    save: false,
    delete: false,
    markAsInUse: false,
    markAsNotInUse: false
  }

  private readonly customGridOptions: any = {
    ...CustomGridOptions,
    context: {
      componentParent: this,
      pageTitle: 'Size Range Assignments',
      saveAll: async () => await this.saveAll(),
      saveRow: async (params: SizeRangeAssignment) => await this.saveRow(params),
      providers: [SizeRangeAssignmentsAPIs],
      columnsToAutoSize: [
        'product_id',
        'product_name',
        'size_range_name',
        'eligible_channel',
        'future_size_range',
        'historical_size_range',
        'in_use'
      ]
    }
  }

  @ViewChild('confirmPopup') confirmPopup: ConfirmPopupComponent

  constructor (
    private readonly sizeRangeAssignmentsAPIs: SizeRangeAssignmentsAPIs,
    public dialogService: DialogService
  ) {
    this.gridOptions = {
      ...this.customGridOptions,
      pagination: true,
      paginationAutoPageSize: true,
      statusBar: {},
      onGridReady: (event: GridReadyEvent) => this.onGridReady(event),
      getContextMenuItems: params => this.gridContextMenu(params),
      onSelectionChanged: (event: SelectionChangedEvent) =>
        this.onSelectionChanged(event),
      onCellValueChanged: (event: NewValueParams) => {
        if (
          event.oldValue !== event.newValue &&
                    event.colDef.colId !== 'edited'
        ) {
          event.node.setData({ ...event.data, edited: true })
          if (this.selectedRows.some(row => row.data.edited)) {
            this.gridActionsEnabled.save = true
          }
          event.api.refreshCells({ force: true })
        }
      }
    }

    document.addEventListener('contextmenu', event => {
      if (event.button === 2) {
        this.eventPointer = event
      }
    })
  }

  ngOnInit (): void {
    this.getDropdownValues()
    this.getAllSizeRangeAssignments()
  }

  onGridReady (event: GridReadyEvent) {
    console.log('Grid Ready Event', event)

    this.gridApi = event.api
    this.columnApi = event.columnApi
    this.isAgGridLoading = false
  }

  addAssignment () {
    this.dialogService.open(SizeRangeAssignmentsModalComponent, {
      showHeader: false,
      closeOnEscape: true,
      dismissableMask: false,
      styleClass: 'medium-modal',
      data: {
        channels: this.multiSelectOptions.channels,
        products: this.multiSelectOptions.products,
        sizeRangeNames: this.multiSelectOptions.size_range_names,
        close: () => {}
      }
    })
  }

  async saveAll () {
    this.gridApi.showLoadingOverlay()
    const selectedRows = this.selectedRows.map(row => row.data)

    const rowsToSave = selectedRows.filter(row => row.edited)
    console.log('Rows to Save', rowsToSave)

    //  TODO: Ask for new API endpoint for saving multiple rows
    for (const row of rowsToSave) {
      await this.sizeRangeAssignmentsAPIs.UpdateSizeRangeAssignment(row)
      row.edited = false
    }
    this.gridApi.redrawRows()
    this.gridActionsEnabled.save = false
    this.gridApi.hideOverlay()
  }

  async saveRow (data: SizeRangeAssignment) {
    this.gridApi.showLoadingOverlay()
    const response =
            await this.sizeRangeAssignmentsAPIs.UpdateSizeRangeAssignment(data)

    if (response?.is_success) {
      this.gridApi.redrawRows()
    }
    this.gridApi.hideOverlay()
  }

  async onDelete (event) {
    if (event.userInput === 'except') {
      this.gridApi.showLoadingOverlay()
      const rowsToDelete = this.selectedRows.map(row => row.data)

      console.log('Rows to Delete', rowsToDelete)

      const response = await this.sizeRangeAssignmentsAPIs.DeleteSizeRangeAssignments(rowsToDelete)

      if (response?.is_success) {
        this.gridApi.applyTransaction({ remove: rowsToDelete })
      }
      this.gridApi.hideOverlay()
    }
  }

  markAsInUse () {
    this.selectedRows.forEach(row => {
      row.setDataValue('in_use', true)
    })
  }

  markAsNotInUse () {
    this.selectedRows.forEach(row => {
      row.setDataValue('in_use', false)
    })
  }

  private async getAllSizeRangeAssignments () {
    console.log('Get All Size Range Assignments')

    const response =
            await this.sizeRangeAssignmentsAPIs.GetAllSizeRangeAssignments()
    console.log('SRA Response: ', response)

    if (response && response.length > 0) {
      await this.renderGrid(
        this.sizeRangeAssignmentsColDefs[0],
        response
      )
    }
  }

  private async getDropdownValues () {
    const response =
            await this.sizeRangeAssignmentsAPIs.GetDropdownValues()

    if (response && response.size_range_names.length > 0) {
      this.multiSelectOptions.size_range_names =
                response.size_range_names
      this.multiSelectOptions.historical_size_range =
                response.size_range_names
      this.multiSelectOptions.channels = response.channels
      this.multiSelectOptions.products = response.products
    } else {
      this.multiSelectOptions.historical_size_range = []
      this.multiSelectOptions.size_range_names = []
      this.multiSelectOptions.channels = []
      this.multiSelectOptions.products = []
    }
  }

  private async renderGrid (importedColDefs, rowDataFromAPI) {
    const params = { importedColDefs }
    const gridData = await SizeRangeAssignments_GenerateGridData(params)

    if (gridData) {
      this.columnDefs = gridData.mainColDefs

      const checkAgGrid = () => {
        if (!this.isAgGridLoading) {
          if (this.gridApi && !this.gridApi['destroyCalled']) {
            this.gridApi?.setColumnDefs(gridData.mainColDefs)
            this.gridApi.setRowData(rowDataFromAPI)
          }

          clearInterval(setData)
        } else {
          console.log('Ag grid is loading')
        }
      }

      const setData = setInterval(checkAgGrid, 100)
    }
  }

  onSelectionChanged (event) {
    const rows = event.api.getSelectedNodes()
    const isRowSelected = rows.length > 0
    this.selectedRows = rows

    this.gridActionsEnabled = {
      save: false,
      delete: false,
      markAsInUse: false,
      markAsNotInUse: false
    }

    if (isRowSelected) {
      this.gridActionsEnabled.delete = true
      this.gridActionsEnabled.markAsInUse = true
      this.gridActionsEnabled.markAsNotInUse = true
    }

    if (this.selectedRows.some(row => row.data.edited)) {
      this.gridActionsEnabled.save = true
    }
  }

  gridContextMenu (params) {
    console.log('Size Range Assignments Grid Context Menu', params)

    if (params.column) {
      const selectedNodes = params.api.getSelectedNodes()

      const saveButton = [
        {
          name:
                        selectedNodes.length > 0
                          ? `Save ${selectedNodes.length} Row${
                                  selectedNodes.length === 1 ? '' : 's'
                              }`
                          : 'Save (No Rows Selected)',
          action: async () => await this.saveAll(),
          disabled: !this.gridActionsEnabled.save,
          cssClasses: ['text-accent']
        }
      ]

      const deleteButton = [
        {
          name:
                        selectedNodes.length > 0
                          ? `Delete ${selectedNodes.length} Row${
                                  selectedNodes.length === 1 ? '' : 's'
                              }`
                          : 'Delete (No Rows Selected)',
          action: async () =>
            await this.confirmPopup.confirm(
              this.eventPointer,
              'Delete Size Range Assignments'
            ),
          disabled: !this.gridActionsEnabled.delete,
          cssClasses: ['text-warn']
        }
      ]

      const markAsInUseButton = [
        {
          name:
                        selectedNodes.length > 0
                          ? `Mark ${selectedNodes.length} Row${
                                  selectedNodes.length === 1 ? '' : 's'
                              } as in Use`
                          : 'Mark as in Use (No Rows Selected)',
          action: () => this.markAsInUse(),
          disabled: !this.gridActionsEnabled.markAsInUse,
          cssClasses: ['text-accent']
        }
      ]

      const markAsNotInUseButton = [
        {
          name:
                        selectedNodes.length > 0
                          ? `Mark ${selectedNodes.length} Row${
                                  selectedNodes.length === 1 ? '' : 's'
                              } as Not in Use`
                          : 'Mark as Not in Use (No Rows Selected)',
          action: () => this.markAsNotInUse(),
          disabled: !this.gridActionsEnabled.markAsNotInUse,
          cssClasses: ['text-accent']
        }
      ]

      return [
        ...saveButton,
        ...deleteButton,
        ...markAsInUseButton,
        ...markAsNotInUseButton,
        'separator',
        ...DefaultGridContextMenu(params)
      ]
    } else {
      return DefaultGridContextMenu(params)
    }
  }
}
