[
    {
        "Select": {
            "editable": false,
            "pinned": "left",
            "refData": {
                "datatype": "checkbox",
                "selectAll": true,
                "colSize": 50
            },
            "maxWidth": 50,
            "rowGroup": false,
            "hide": false,
            "suppressAutoSize": true
        },
        "Size Range Name": {
            "editable": false,
            "refData": {
                "datatype": "string",
                "showToolTip": false,
                "flexSize": 2,
                "minWidth": 200
            },
            "rowGroup": false,
            "hide": false
        },
        "Sizes": {
            "editable": false,
            "refData": {
                "datatype": "string",
                "showToolTip": false,
                "minWidth": 200
            },
            "rowGroup": false,
            "hide": false
        },
        "Has Existing Assignment": {
            "editable": false,
            "refData": {
                "datatype": "boolean",
                "showToolTip": false,
                "maxWidth": 200
            },
            "rowGroup": false,
            "hide": false
        },
        "Delete": {
            "editable": false,
            "refData": {
                "datatype": "open-screen|delete"
            },
            "minWidth": 100,
            "rowGroup": false,
            "hide": false,
            "lockPosition": true,
            "pinned": "right",
            "lockPinned": true
        }
    }
]
