// Custom Imports
// =========================================================
import { FormatKey } from 'src/app/utils/global_functions'
import { GenerateDefaultColDefs } from '../02_global-settings/global-cell-formatter'
import { MetricsCustomTooltipComponent } from '../03_custom-components/grid-custom-tooltip/metrics-custom-tooltip/metrics-custom-tooltip.component'

export interface SizeDistroManagementSummary_GridDataParams {
  mainColDefs: any[]
}

export async function SizeDistroManagementSummary_GenerateGridData (
  params: SizeDistroManagementSummary_GridDataParams
) {
  const {
    mainColDefs
    // detailColDefs
  } = params
  const GenerateCols = async (data: any[], gridToRender: string) => {
    const mappedCols = {}

    Object.entries(data).map(([colKey, colVal]) => {
      const colId = colVal.refData?.custom_field || FormatKey(colKey)

      const obj = {
        ...GenerateDefaultColDefs(colKey, { colId, ...colVal })
      }
      Object.assign(mappedCols, {
        [obj.colId]: SizeDistroManagementSummary_customCellFormatting(
          obj,
          gridToRender
        )
      })
    })

    return Object.values(mappedCols)
  }

  const getResults = async () => {
    const mainCols = await GenerateCols(mainColDefs, 'main')

    const results = await Promise.all([mainCols])
    const m = results.indexOf(mainCols)

    const data = {
      mainColDefs: results[m]
    }
    return data
  }
  return await getResults()
}

export const SizeDistroManagementSummary_customCellFormatting = (
  col,
  gridToRender
) => {
  const getParams = () => {
    // Main Grid Settings
    if (gridToRender === 'main') {
      // console.log('col.colId ', col.colId)
      switch (col.colId) {
        case 'optimal_node':
          return {
            tooltipComponent: MetricsCustomTooltipComponent
          }
        default:
          return {}
      }
    }
  }
  const updatedCol = { ...col, ...getParams() }
  return updatedCol
}
