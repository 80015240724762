// External Imports
// =========================================================
import moment from 'moment'
import {
  type IDateFilterParams,
  type RowGroupOpenedEvent,
  type ColumnVisibleEvent,
  type PaginationChangedEvent,
  type GetMainMenuItemsParams,
  type ValueFormatterParams,
  type ValueGetterParams,
  KeyCreatorParams,
  type ICellRendererParams,
  CellClassParams
} from 'ag-grid-community'

// Custom Imports
// =========================================================
import { ButtonCellRenderer } from '../03_custom-components/button-cell-renderer/button-renderer'
import { SuffixCellIconRenderer } from '../03_custom-components/suffix-cell-icon-renderer/suffix-cell-icon-renderer'
import { AutoInputDelimiterCellEditor } from '../03_custom-components/auto-input-delimiter-cell-renderer/auto-input-delimiter-cell-editor'
import { GridCustomTooltipComponent } from '../03_custom-components/grid-custom-tooltip/grid-custom-tooltip.component'
import { AutoSelectionCellRenderer } from '../03_custom-components/auto-selection-cell-renderer/auto-selection-cell-renderer'
import { GridLoadingOverlay } from '../03_custom-components/grid-loading-overlay/grid-loading-overlay'
// Grid Functions
// =========================================================
import {
  AbsSumAggFunc,
  CustomAverageAggFunc,
  DisplayFirstChildValAggFunc,
  HideAggTotalsAggFunc,
  ParseFloatSumAggFunc,
  TotalCartonQtyFunc,
  CombineStycsWithSlashAggFunc,
  ValueArrayAggFunc,
  SrcDestCartonQtySumAggFunc,
  CombineStycsWithSlashNoTopLevelAggFunc,
  FirstOnlyBottomLevelAggFunc
} from './grid-functions-aggregate'
import { CellClassRules, RowClassRules } from './cell-class-rules'
import {
  FormatCurrency,
  FormatDate,
  FormatNumber,
  FormatPercentage,
  FormatString
} from './global-cell-formatter'
import { MultiCheckboxColumnRenderer } from '../03_custom-components/multi-checkbox-column-renderer/multi-checkbox-column-renderer'
import { MultiSelectionCellRenderer } from '../03_custom-components/multi-selection-cell-renderer/multi-selection-cell-renderer'
import { CustomHeaderRenderer } from '../03_custom-components/custom-header-renderer/custom-header-renderer'
import {
  FormatKey,
  GetValue,
  IsKeyInObj,
  ReverseFormatKey
} from 'src/app/utils/global_functions'
import { SaveGridState } from './grid-functions-general'
import { isArray } from 'lodash'
import { CheckmarkBooleanCellRenderer } from '../03_custom-components/checkmark-boolean-cell-renderer/checkmark-boolean-cell-renderer'
import { ArrayCustomTooltipComponent } from '../03_custom-components/grid-custom-tooltip/array-custom-tooltip/array-custom-tooltip.component'
import { ConditionalExpandArrowCellRenderer } from '../03_custom-components/conditional-expand-arrow-cell-renderer/conditional-expand-arrow-cell-renderer'

export const CustomGridOptions = {
  // ----> Default column definitions
  defaultColDef: {
    minWidth: 80,
    // maxWidth: 1000,
    sortable: true,
    resizable: true,
    cellDataType: false,
    showDisabledCheckboxes: true,
    valueFormatter: params => {
      if (params.value) return params.value
    },
    cellClassRules: CellClassRules,
    tooltipComponent: GridCustomTooltipComponent,
    useValueFormatterForExport: true,
    useValueParserForImport: true
  },

  // ----> Auto Group Column Definition
  autoGroupColumnDef: {
    minWidth: 200,
    filterValueGetter: function (params) {
      // console.log("filters: ", params)
      let colGettingGrouped = null
      colGettingGrouped = params.colDef.showRowGroup
      const valueForOtherCol = params.api.getValue(
        colGettingGrouped,
        params.node
      )
      return valueForOtherCol
    }
  },

  // ----> Column Type Settingsc
  columnTypes: {
    editableNumValues: {
      doubleClickEdit: true,
      enableValue: true,
      editable: true,
      valueParser: params => Number(params.newValue)
    },
    numberFormatter: {
      valueFormatter: FormatNumber,
      filter: 'agNumberColumnFilter',
      filterValueGetter: params => {
        console.log('---> filter params: ', params)
        const value = FormatNumber({
          ...params,
          value: params.getValue(params.colDef)
        }).replaceAll(',', '')

        return isNaN(Number(value)) ? undefined : Number(value)
      },
      tooltipValueGetter: params => {
        if (params.colDef.refData.showToolTip) {
          return FormatNumber(params)
        }
      },
      valueParser: params => Number(params.newValue),
      comparator: (a, b) => GetValue(a) - GetValue(b)
    },
    numberGroupFormatter: {
      cellRenderer: 'agGroupCellRenderer'
    },
    percentageFormatter: {
      valueFormatter: FormatPercentage,
      filter: 'agNumberColumnFilter',
      filterValueGetter: params => {
        const value = FormatPercentage({
          ...params,
          value: params.getValue(params.colDef)
        }).replaceAll(',', '')
        return isNaN(Number(value)) ? undefined : Number(value)
      },
      tooltipValueGetter: params => {
        if (params.colDef.refData.showToolTip) {
          return FormatPercentage(params)
        }
      },
      valueParser: params => Number(params.newValue),
      comparator: (a, b) => GetValue(a) - GetValue(b)
    },
    currencyFormatter: {
      valueFormatter: FormatCurrency,
      filter: 'agNumberColumnFilter',
      filterValueGetter: params => {
        const value = FormatCurrency({
          ...params,
          value: params.getValue(params.colDef)
        }).replaceAll(',', '')
        return isNaN(Number(value)) ? undefined : Number(value)
      },
      tooltipValueGetter: params => {
        if (params.colDef.refData.showToolTip) {
          return FormatCurrency(params)
        }
      },
      valueParser: params => Number(params.newValue),
      comparator: (a, b) => GetValue(a) - GetValue(b)
    },
    booleanFormatter: {
      valueFormatter: params =>
        typeof params.value === 'boolean'
          ? params.value.toString().toUpperCase()
          : '--',
      filter: 'agSetColumnFilter',
      filterValueGetter: params => params.getValue(params.colDef),
      filterParams: {
        valueFormatter: params =>
          typeof params.value === 'boolean'
            ? params.value.toString().toUpperCase()
            : '--'
      }
    },
    checkmarkBooleanFormatter: {
      cellRenderer: CheckmarkBooleanCellRenderer,
      cellClass: 'checkmark-boolean-cell',
      filter: 'agSetColumnFilter',
      filterValueGetter: params => params.getValue(params.colDef),
      filterParams: {
        valueFormatter: params => {
          console.log('valueformatter checkamrk boolean params')
          return typeof params.value === 'boolean'
            ? params.value.toString().toUpperCase()
            : 'FALSE'
        }
      }
    },
    arrayFormatter: {
      filter: 'agSetColumnFilter',
      valueGetter: params => {
        const delim = params.colDef.field.startsWith('size_range')
          ? '||'
          : '/'
        let oldValue = params.data[params.colDef?.field]
        if (!isArray(oldValue)) {
          oldValue = oldValue?.split(delim)
        }
        if (oldValue) {
          // console.log('returning 1')
          return (params.value = oldValue.map(item => {
            // console.log('item', item)
            const key = params?.colDef?.refData?.arrayKey || null
            // console.log('key', key)
            return key ? item[key] : item
          }))
        } else {
          // console.log('returning null')
          return (params.value =
                        params?.colDef?.refData?.customNullValue || '--')
        }
      },
      valueFormatter: (params: ValueFormatterParams) => {
        const delim = '/'
        return params.value && isArray(params.value)
          ? params.value.join(delim)
          : params.value
      },
      filterParams: {
        valueFormatter: params => (params?.value ? params.value : null) // this makes the filter display values properly for some reason
      },
      tooltipComponent: ArrayCustomTooltipComponent
    },
    statusesArrayFormatter: {
      // valueGetter: params => {
      //   return params.value
      // },
      valueFormatter: (params: ValueFormatterParams) => {
        const countObj = {}
        params.value.forEach(val => {
          if (countObj.hasOwnProperty(val)) {
            countObj[val]++
          } else {
            countObj[val] = 1
          }
        })
        return Object.keys(countObj)
          .map(key => {
            return countObj[key] + ' ' + key
          })
          .join(' / ')
      },
      filter: 'agSetColumnFilter',
      filterParams: {
        valueFormatter: params => (params?.value ? params.value : null) // this makes the filter display values properly for some reason
      }
      // filterValueGetter: params => {
      //   console.log('arrayFormatter filterValueGetter params', params)
      //   const value = params.getValue(params.colDef.field)
      //   console.log('value', value)
      //   return value && isArray(value) ? value.join('/') : value
      // },
      // filterValueFormatter: params => {
      //   return 'formatted filter'
      // },
      // keyCreator: (params: KeyCreatorParams) => {
      //   console.log('key creator value', params.data)
      //   return 'CUSTOM KEY'
      // }
    },
    isBulkStringFormatter: {
      valueFormatter: (params: ValueFormatterParams) => {
        return params.value && params.value.toLowerCase() === 'true'
          ? 'Is Bulk'
          : 'Is Pack'
      }
    },
    customAverage: {
      aggFunc: 'customAverageAggFunc',
      enableValue: true,
      valueGetter: (params: ValueGetterParams) => {
        if (!params.node.group) {
          const value = Number(params.data[params.colDef?.field])
          return {
            count: 1,
            avg: value,
            value
          }
        }
      }
    },
    stringFormatter: {
      valueFormatter: FormatString,
      comparator: (valueA: string, valueB: string) => {
        if (valueA == valueB) return 0
        return FormatKey(valueA) > FormatKey(valueB) ? 1 : -1
      }
    },
    stringLiteralFormatter: {
      valueFormatter: (params: ValueFormatterParams) => {
        return params.colDef.refData.datatype.split('|')[1]
      }
    },
    dateFormatter: {
      valueFormatter: FormatDate,
      filter: 'agDateColumnFilter',
      filterParams: {
        valueFormatter: FormatDate,
        comparator: (filterLocalDateAtMidnight, cellValue) => {
          // console.log('cellvalue: ', cellValue)
          // console.log(
          //   'filterLocalDateAtMidnight: ',
          //   filterLocalDateAtMidnight
          // )
          const getFormattedDate = val => {
            const d = moment(val).format('L')
            return d === 'Invalid date' ? val : d
          }

          const dateAsString = getFormattedDate(cellValue)
          // console.log('dateAsString 2: ', dateAsString)

          if (dateAsString == null) {
            return 0
          }

          const dateParts = dateAsString.split('/')
          const year = Number(dateParts[2])
          const month = Number(dateParts[0]) - 1
          const day = Number(dateParts[1])
          const cellDate = new Date(year, month, day)
          // console.log('cellDate: ', cellDate)

          if (cellDate < filterLocalDateAtMidnight) {
            return -1
          } else if (cellDate > filterLocalDateAtMidnight) {
            return 1
          } else {
            return 0
          }
        }
      } as IDateFilterParams
    },
    checkboxFormatter: {
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: params =>
        params.colDef.refData.datatype === 'checkbox' ||
                params.colDef.refData.datatype === 'multi-checkbox-column',
      cellRenderer: params => {
        let value = params.data
        if (params.data) value = params.data[params.colDef.colId]

        if (value === 'true' || value === true) {
          return params.node.setSelected(true)
        }
      }
    },
    buttonFormatter: {
      cellRenderer: ButtonCellRenderer,
      pinned: 'right',
      resizable: true,
      skipHeaderOnAutoSize: false,
      suppressAutoSize: false,
      // maxWidth: 80,
      // width: 60,
      cellStyle: { textOverflow: 'clip', width: '60px' }
    },
    customHeaderFormatter: {
      headerComponent: CustomHeaderRenderer,
      filter: 'agSetColumnFilter',
      filterValueGetter: params => {
        const { colId } = params.colDef
        return params.getValue(colId)
      },
      filterParams: {
        refreshValuesOnOpen: true,
        valueFormatter: params => {
          return params.value
        }
      } as IDateFilterParams
    },
    multiCheckboxColumnFormatter: {
      cellRenderer: MultiCheckboxColumnRenderer
    },
    multiSelectionCellFormatter: {
      cellEditor: MultiSelectionCellRenderer,
      cellRenderer: SuffixCellIconRenderer,
      cellRendererParams: {
        renderer_icon: 'select'
      }
    },
    selectionAutoCompleteFormatter: {
      cellEditor: AutoSelectionCellRenderer,
      cellRenderer: SuffixCellIconRenderer,
      cellRendererParams: {
        renderer_icon: 'select'
      }
    },
    stringDelimiterFormatter: {
      cellEditor: AutoInputDelimiterCellEditor,
      cellRenderer: SuffixCellIconRenderer,
      cellRendererParams: {
        renderer_icon: 'edit'
      }
    },
    stringEditorFormatter: {
      cellRenderer: SuffixCellIconRenderer,
      cellRendererParams: {
        renderer_icon: 'edit'
      }
    },
    alertCellFormatter: {
      cellRenderer: SuffixCellIconRenderer,
      cellRendererParams: {
        renderer_icon: 'error_outline'
      }
    },
    groupCellFormatter: {
      cellRenderer: 'agGroupCellRenderer'
    },
    conditionalExpandArrowFormatter: {
      cellRendererSelector: (params: ICellRendererParams) => {
        console.log('selecting cell renderer pinned', params)
        if (params.data?.isAggRow && params.data.carton_id) {
          console.log('returning cell renderer ConditionalExpandArrowCellRenderer')
          return {
            component: ConditionalExpandArrowCellRenderer
          }
        } else {
          return null // just renders value in plain text
        }
      },
      cellStyle: (params: CellClassParams) => {
        if (!params.data || (params.data.isAggRow && params.data.carton_id) || !params.data.isPinnedRow) {
          return null
        } else {
          return { 'text-align': 'center' }
        }
      }
    }
  },
  // ----> Framework Components
  // frameworkComponents: {
  // buttonCellRenderer: ButtonCellRenderer,
  // customTooltip: GridCustomTooltipComponent,
  // rowLoadingCellRenderer: RowLoadingCellRenderer,
  // gridLoadingOverlay: GridLoadingOverlay,
  // autoComplete: AutoSelectionCellRenderer,
  // autoInputDelimiter: AutoInputDelimiterCellEditor,
  // multiSelection: MultiSelectionCellRenderer
  // },
  // ----> Custom Agg Functions
  aggFuncs: {
    absSum: AbsSumAggFunc,
    chartAgg: params => {
      return true // ButtonCellRenderer
    },
    aggValueArray: ValueArrayAggFunc,
    parseFloatSum: ParseFloatSumAggFunc,
    displayFirstChildVal: DisplayFirstChildValAggFunc,
    hideAggTotals: HideAggTotalsAggFunc,
    customAverage: CustomAverageAggFunc,
    totalCartonQty: TotalCartonQtyFunc,
    combineStycsWithSlash: CombineStycsWithSlashAggFunc,
    combineStycsWithSlashNoTopLevel: CombineStycsWithSlashNoTopLevelAggFunc,
    srcDestCartonQtySum: SrcDestCartonQtySumAggFunc,
    firstOnlyBottomLevel: FirstOnlyBottomLevelAggFunc
  },

  // ----> Grid Settings
  rowClassRules: RowClassRules,
  loadingOverlayComponent: GridLoadingOverlay,
  loadingOverlayComponentParams: { loadingMessage: 'loading' },
  tooltipShowDelay: 0,
  rowSelection: 'multiple',
  singleClickEdit: true,
  detailRowAutoHeight: true,
  groupSuppressBlankHeader: true,
  suppressRowClickSelection: true,
  suppressFieldDotNotation: true,
  suppressAggFuncInHeader: true,
  stopEditingWhenCellsLoseFocus: true,
  animateRows: true,
  allowContextMenuWithControlKey: true,
  suppressScrollOnNewData: true,
  debug: true,
  rowGroupPanelShow: 'never',
  tooltipMouseTrack: true,
  maintainColumnOrder: true,

  // Status Bar / Grid Footer - Used to display number of rows
  statusBar: {
    statusPanels: [
      {
        statusPanel: 'agTotalAndFilteredRowCountComponent',
        align: 'left'
      }
    ]
  },
  excelStyles: [
    {
      id: 'number',
      numberFormat: {
        format: '0'
      }
    },
    {
      id: 'percentage',
      numberFormat: {
        // format: '#,##0.00 %'
        format: '#,##0.00'
      }
    },
    {
      id: 'currency',
      numberFormat: {
        // format: '#,##0.00 $'
        format: '#,##0.00'
      }
    },
    {
      id: 'multi-checkbox-column',
      dataType: 'Boolean'
    },
    {
      id: 'boolean',
      dataType: 'Boolean'
    },
    {
      id: 'string',
      dataType: 'String'
    },
    {
      id: 'multi-selection-auto-complete',
      dataType: 'String'
    },
    {
      id: 'date',
      dataType: 'String'
      // dataType: 'DateTime',
      // numberFormat: {
      //   format: 'dd/mm/yyy h:mm:ss AM/PM'
      // }
    }
  ],
  // ----> Grid Functions
  onCellClicked: params => console.log('---> Cell Clicked: ', params),
  onFilterChanged: params => {
    // console.log('filter changed: ', params)
    // const rowCount = params.api.getDisplayedRowCount()
    // if (!rowCount || rowCount === 0) {
    //   params.api.showNoRowsOverlay()
    // } else {
    //   // params.api.hideOverlay()
    // }
    params.api.refreshCells()
  },
  getContextMenuItems: params => DefaultGridContextMenu(params),
  getMainMenuItems: (params: GetMainMenuItemsParams) =>
    DefaultGridContextMenu(params),
  onRowGroupOpened: (event: RowGroupOpenedEvent) => AutoSizeColumns(event),
  onColumnVisible: (event: ColumnVisibleEvent) => {
    if (event.source === 'toolPanelUi' && event.visible) {
      event.columnApi.autoSizeColumn(event.column['colId'], false)
    } else if (event.visible) {
      AutoSizeColumns(event)
    }
  },
  onPaginationChanged: (event: PaginationChangedEvent) => {
    if (event.newPage) {
      console.log('---> Pagination changes: ', event)
      AutoSizeColumns(event)
    }
  },
  // AutoSizeColumns(event)
  onFirstDataRendered: params => AutoSizeColumns(params)
}

export const AutoSizeColumns = grid => {
  console.log('autosizing columns')
  if (grid.api && !grid.api.destroyCalled) {
    if (grid?.context.fitAllColumns) {
      return grid?.api?.sizeColumnsToFit()
    } else if (IsKeyInObj(grid?.context, 'columnsToAutoSize')) {
      // console.log("---> Columns to Autosize: ",  grid)
      // console.log("---> Columns to Autosize: ",  grid?.context?.columnsToAutoSize)
      return grid.columnApi.autoSizeColumns(
        grid.context.columnsToAutoSize
      )
    } else {
      // console.log("---> Auto size all columns: ")
      // const columns = grid.columnApi.getAllGridColumns()
      // const colsToResize = columns.filter(col => col.visible && col?.colDef?.flex && !col?.colDef?.suppressAutoSize).map(col => col?.colDef?.colId)
      // console.log("---> columns: ", colsToResize)
      // return grid.columnApi.autoSizeColumns(
      //   colsToResize
      // )
      // grid?.columnApi?.autoSizeColumns()
      // grid?.columnApi?.autoSizeAllColumns()
    }
  }
}

// Default context menu
export const DefaultGridContextMenu = params => {
  console.log('context params: ', params)

  const checkNestedRows = (children, action) => {
    if (children?.group && children?.childrenAfterFilter?.length > 0) {
      if (action === 'expand') children.setExpanded(true)
      if (action === 'collapse') children.setExpanded(false)

      children.childrenAfterFilter.map(child => {
        checkNestedRows(child, action)
      })
    }
  }

  const expandSelectedGroup =
        params?.node?.group && !params?.node?.expanded
          ? [
              {
                name: 'Expand Selected Row Group',
                action: () => checkNestedRows(params.node, 'expand')
              }
            ]
          : []

  const collapseSelectedGroup =
        params?.node?.group && params?.node?.expanded
          ? [
              {
                name: 'Collapse Selected Row Group',
                action: () => checkNestedRows(params.node, 'collapse')
              }
            ]
          : []
  const allowRowGroupToggle = () =>
    params?.context && params?.context?.allowRowGroupToggle
      ? [
          ...expandSelectedGroup,
          ...collapseSelectedGroup,
          {
            name: 'Expand All Row Groups',
            action: () =>
              params.api.forEachNode(node =>
                node.setExpanded(true)
              )
            // params.api.expandAll(),
            // cssClasses: ['bold'],
          },
          {
            name: 'Collapse All Row Groups',
            action: () =>
              params.api.forEachNode(node =>
                node.setExpanded(false)
              )
            // params.api.collapseAll(),
          }
        ]
      : []

  const allowSaveView = () =>
    params?.context &&
        params?.context?.allowSaveView &&
        params?.context?.gridName
      ? [
          {
            name: 'Save Grid View',
            action: async () => {
              const gridName = params?.context?.gridName
              await SaveGridState(params, gridName, true)
            },
            cssClasses: ['text-accent']
          },
          'separator'
        ]
      : []
  const exportGridData = (fileType: string) => {
    let {
      context,
      context: { pageTitle, componentParent }
    } = params
    const customDetailsGridsToExport = [
      'buy_summary',
      'source_to_dest_summary',
      'carton_summary'
    ]
    if (
      !pageTitle &&
            customDetailsGridsToExport.includes(componentParent.tabKey)
    ) {
      pageTitle = `Review orders - ${ReverseFormatKey(
                componentParent.tabKey
            )} detail`
    }
    const columnDefs = context?.gridKey
      ? componentParent[`${context?.gridKey}_columnDefs`]
      : params.columnApi.getAllDisplayedColumns()
    console.log('---> columnDefs to export: ', columnDefs)
    const fileParams = {
      columnKeys: columnDefs
        .flatMap(col =>
          col?.colDef?.children
            ? col.colDef.children.map(childCol => childCol)
            : col?.colDef
              ? col.colDef
              : col
        )
        .filter(
          col =>
            !col?.refData?.actionColumn || col?.refData?.treeGroup
        )
        .map(col => {
          return col.colId === 'ag-Grid-AutoColumn'
            ? FormatKey(col.headerName)
            : col.colId
        }),
      allColumns: false,
      fileName: `${pageTitle}_` + moment().format('LLL'),
      skipHeader: false,
      processRowGroupCallback (params) {
        // console.log('----> Process Group level nodes: ', params)
        return `Group: ${params.node.key}`
      },
      processCellCallback (params) {
        console.log('----> Process Cell CallBack: ', params)

        const { cellClass, refData, colId } = params.column.colDef
        const isNumericCell = [
          'number',
          'percentage',
          'currency'
        ].includes(cellClass)
        const isDateCell = cellClass === 'date'
        // const isArrayCell = Array.isArray(params.value)
        const value = params.value

        if (
          (value && value !== '--') ||
                    value === false ||
                    value === 0
        ) {
          if (isNumericCell) {
            if (params?.node?.data?.isPctRow && params?.value) {
              return `${GetValue(100 * params.value).toFixed(
                                2
                            )}%`
            }
            if (
              typeof params?.value === 'object' &&
                            params?.value?.buy
            ) {
              return params.value.buy
            }
            return `${GetValue(params.value).toFixed(2)}`
          } else if (isDateCell) {
            return FormatDate(params)
          } else {
            if (
              refData.datatype === 'array' &&
                            IsKeyInObj(refData, 'joinForExcelExport')
            ) {
              return value.join(refData.joinForExcelExport)
            } else {
              if (
                colId === 'status' &&
                                value === 'Upload Error' &&
                                params?.node?.data &&
                                IsKeyInObj(params.node.data, 'error_reason')
              ) {
                return `${params.node.data.error_reason}`
              } else {
                return value
              }
            }
          }
        } else {
          return '--'
        }
      }
    }

    if (fileType === 'excel') {
      return params.api.exportDataAsExcel(fileParams)
    }
    if (fileType === 'csv') {
      return params.api.exportDataAsCsv(fileParams)
    }
  }

  const menuItems = [
    ...allowSaveView(),
    ...allowRowGroupToggle(),
    {
      name: 'Column Layout',
      subMenu: [
        {
          name: 'Pin Column',
          icon: `<span class="material-icons push_pin-icon flex-center-all" style="font-size: 14px">
          push_pin
          </span>`,
          disabled:
                        !params?.column ||
                        params?.column?.colDef?.refData?.actionColumn,
          subMenu: [
            {
              name: 'No Pin',
              action: () =>
                params.columnApi.applyColumnState({
                  state: [
                    {
                      colId: params?.column.colId,
                      pinned: null
                    }
                  ]
                }),
              checked: !params?.column?.pinned
            },
            {
              name: 'Pin Left',
              action: () =>
                params.columnApi.applyColumnState({
                  state: [
                    {
                      colId: params?.column.colId,
                      pinned: 'left'
                    }
                  ]
                }),
              checked: params?.column?.pinned === 'left'
            },
            {
              name: 'Pin Right',
              action: () =>
                params.columnApi.applyColumnState({
                  state: [
                    {
                      colId: params?.column.colId,
                      pinned: 'right'
                    }
                  ]
                }),
              checked: params?.column?.pinned === 'right'
            }
          ]
        },
        // 'pinSubMenu',
        'separator',
        {
          name: 'Fit All Columns',
          action: () => params.api.sizeColumnsToFit()
        },
        {
          name: 'Auto-size All Columns',
          action: () => params.columnApi.autoSizeAllColumns()
        },
        {
          name: 'Auto-size This Column',
          action: () =>
            params.columnApi.autoSizeColumn(params.column.colId),
          disabled:
                        !params?.column ||
                        params?.column?.colDef?.refData?.actionColumn
        },
        'separator',
        {
          name: 'Reset Columns',
          action: async () => {
            params.columnApi.resetColumnState()

            // Apply the column order
            params.columnApi?.applyColumnState({
              state: params.context.componentParent.columnDefs
            })

            // Reset the filters
            params.api?.setFilterModel([])
          },
          cssClasses: ['text-warn']
        }
      ]
    },
    'separator',
    {
      name: 'Export',
      subMenu: [
        {
          name: 'Excel Export (.xlsx)',
          action: () => exportGridData('excel')
        },
        {
          name: 'CSV Export',
          action: () => exportGridData('csv')
        }
      ]
    }
  ]
  return menuItems
}
