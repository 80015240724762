// Angular Imports
// =========================================================
import { Component, ViewChild } from '@angular/core'
// AG Grid Imports
// =========================================================
import { type ICellRendererAngularComp } from 'ag-grid-angular'
import { type ICellRendererParams } from 'ag-grid-enterprise';
// Custom Imports
// =========================================================

@Component({
  selector: 'no-children-group-cell',
  templateUrl: './no-children-group-cell-renderer.component.html',
  styleUrls: ['./no-children-group-cell-renderer.component.scss']
})
export class NoChildrenGroupCellRenderer implements ICellRendererAngularComp {
  public params!: ICellRendererParams
  public paddingLeft!: number
  public isGroup!: boolean
  public expanded!: boolean

  value: string

  constructor () {

  }

  agInit (params: any): void {
    this.params = params
    this.paddingLeft = params.node.level * 15
    this.isGroup = !!params.node.group && params.node.level <= 1
    this.expanded = params.node.expanded

    this.params.node.addEventListener('expandedChanged', this.onExpand)
    this.value = params.value
  }

  onClick () {
    this.params.node.setExpanded(!this.params.node.expanded)
  }

  onExpand = () => {
    this.expanded = this.params.node.expanded
  }

  refresh (): boolean {
    return true
  }

  destroy () {
    this.params.node.removeEventListener('expandedChanged', this.onExpand)
  }
}
