<!-- Size Range Definitions -->
<div class="main-ag-grid-container flex-column">
    <mat-card class="h-100">
        <!-- Toolbar -->
        <div class="grid-toolbar">
            <!-- Grid Header -->
            <div class="grid-title">
                <h3>Size Range Definitions</h3>
            </div>
            <div class="grid-tool-panel-btn-container">
                <!-- Rows Selected User Feedback -->
                <caption *ngIf="selectedSizeRanges.length > 0">
                    {{
                        selectedSizeRanges.length
                    }}
                    Size Range Definition{{
                        selectedSizeRanges.length === 1 ? '' : 's'
                    }}
                    Selected
                </caption>
                <!-- Create Size Range Definition -->
                <span>
                    <button
                        mat-raised-button
                        color="accent"
                        class="primary-split-button"
                        (click)="onCreateSizeRange($event)"
                    >
                        Create Size Range Definition
                    </button>
                    <!-- More Options -->
                    <button
                        id="split-button"
                        mat-raised-button
                        color="accent"
                        [matMenuTriggerFor]="moreOptions"
                        #tooltip="matTooltip"
                        matTooltip="More Options"
                        matTooltipClass="below"
                    >
                        <mat-icon
                            aria-hidden="false"
                            aria-label="More Options"
                            class="m-0"
                            >menu_open</mat-icon
                        >
                    </button>
                </span>
                <!-- Create Size Range button -->
                <mat-menu #moreOptions="matMenu">
                    <!-- Delete Size Ranges confirm popup -->
                    <app-confirm-popup
                        #confirmPopup
                        (confirmModal)="onDeleteSizeRanges($event)"
                    ></app-confirm-popup>
                    <!-- Delete Size Ranges button -->
                    <button
                        class="menu-panel-warn-btn"
                        color="accent"
                        mat-menu-item
                        color="warn"
                        (click)="
                            confirmPopup.confirm(
                                $event,
                                'Delete Size Range Definitions'
                            )
                        "
                        [disabled]="
                            processingData || selectedSizeRanges.length === 0
                        "
                    >
                        Delete Size Range(s)
                    </button>
                </mat-menu>
                <!-- Delete confirm modal -->
                <app-confirm-popup
                    #confirmPopupDelete
                    (confirmModal)="
                        onDeleteSizeRanges($event); trigger.closeMenu()
                    "
                ></app-confirm-popup>
            </div>
        </div>
        <!-- AG Grid -->
        <ag-grid-angular
            id="size-distro-strategy-size-range-definitions-grid"
            class="ag-theme-alpine ag-grid-container"
            [columnDefs]="columnDefs"
            [gridOptions]="gridOptions"
            (gridReady)="onGridReady($event)"
            [attr.tool-panel]="true"
        >
        </ag-grid-angular>
    </mat-card>
</div>
