import {
  Component,
  type OnInit,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core'
import { BehaviorSubject } from 'rxjs'
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef
} from 'primeng/dynamicdialog'
import { FormFieldsComponent } from '../../03_shared-components/forms/form-fields/form-fields.component'
import { cloneDeep } from 'lodash'
import { SizeRangeAssignmentsFormSettings } from '../../03_shared-components/forms/form-data/size-range-assignments'
import { TriggerApiCallsService } from '../../core/services/cancel-api-call'
import { ToastAlertComponent } from '../../03_shared-components/01_alerts/toast-alert/toast-alert.component'
import { GeneralAPIs } from '../../core/apis/general_api-calls'
import { SizeRangeAssignmentsAPIs } from '../../core/apis/size-range-assignment_api-calls'

@Component({
  selector: 'app-size-range-assignments-modal',
  templateUrl: './size-range-assignments-modal.component.html',
  styleUrl: './size-range-assignments-modal.component.scss'
})
export class SizeRangeAssignmentsModalComponent implements OnInit {
  loading: boolean = true
  loadingMessage: string = 'loading'

  invalidFields: FormFieldsComponent[]
  isFuture: boolean = false

  dataToSend = {
    eligible_channel: null,
    size_range_name: null,
    historical_size_range: null,
    product_id: null,
    is_future: false,
    in_use: true
  }

  default_formSettings = cloneDeep({
    ...SizeRangeAssignmentsFormSettings
  })

  size_range_assignments_form = new BehaviorSubject<any>(
    SizeRangeAssignmentsFormSettings.size_range_assignments_config
  )

  @ViewChildren(FormFieldsComponent)
    formFieldsComponents!: QueryList<FormFieldsComponent>

  constructor (
    private readonly dynamicDialogRef: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public dialogService: DialogService,
    public triggerApiCallsService: TriggerApiCallsService,
    public toastAlert: ToastAlertComponent,
    public generalAPIs: GeneralAPIs,
    private readonly sizeRangeAssignmentsAPIs: SizeRangeAssignmentsAPIs
  ) {}

  ngOnInit (): void {
    const { size_range_assignments_config } = cloneDeep({
      ...this.default_formSettings
    })
    size_range_assignments_config.eligible_channel.selectionOptions =
            this.config.data.channels
    size_range_assignments_config.size_range_name.selectionOptions =
            this.config.data.sizeRangeNames
    size_range_assignments_config.historical_size_range.selectionOptions =
            this.config.data.sizeRangeNames
    size_range_assignments_config.product_id.selectionOptions =
            this.config.data.products

    this.size_range_assignments_form.next(size_range_assignments_config)

    this.loading = false
  }

  close (data?: any) {
    if (this.dynamicDialogRef) {
      this.dynamicDialogRef.close(data || null)
    }
  }

  onFormUpdatedFunction (form) {
    this.dataToSend[form.controlName] = form.value

    if (form.controlName === 'is_future') {
      this.isFuture = form.value
      this.updateHistoricalSizeRangeFieldState()
    }

    this.invalidFields = this.formFieldsComponents.filter(
      formField => !formField.selectionForm.valid
    )
  }

  reset () {
    this.invalidFields = []
    this.isFuture = false
    this.formFieldsComponents.forEach(formField => {
      formField.selectionForm.reset()
    })
  }

  async submit ($event: any) {
    if (this.invalidFields.length > 0) {
      const invalidFieldNames = this.invalidFields.map(field => field._formParams.label).join(', ')
      throw new Error(`The following fields are invalid: ${invalidFieldNames}`)
    }
    const response =
        await this.sizeRangeAssignmentsAPIs.UpdateSizeRangeAssignment(this.dataToSend)

    // Todo - Add success and error messages, loading wheel, and close screen on success
    if (response?.is_success) {
      console.log('Success')
    } else {
      console.log('Error')
    }
  }

  private updateHistoricalSizeRangeFieldState (): void {
    const historicalSizeRangeField = this.formFieldsComponents.find(
      (field) => field.controlName === 'historical_size_range'
    )

    if (historicalSizeRangeField && !this.isFuture) {
      historicalSizeRangeField.selectionForm.reset()
    }
  }
}
