// General Imports
// =========================================================
import { BrowserModule } from '@angular/platform-browser'
import { APP_INITIALIZER, NgModule } from '@angular/core'
import {
  provideHttpClient,
  withInterceptorsFromDi
} from '@angular/common/http'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { CommonModule } from '@angular/common'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
// aws-amplify Imports
// =========================================================
import { AmplifyAuthenticatorModule } from '@aws-amplify/ui-angular'
// Material Components (UI) Imports
// =========================================================
import { MatToolbarModule } from '@angular/material/toolbar'
import { MatSidenavModule } from '@angular/material/sidenav'
import { MatAutocompleteModule } from '@angular/material/autocomplete'
import { MatButtonModule } from '@angular/material/button'
import { MatButtonToggleModule } from '@angular/material/button-toggle'
import { MatCardModule } from '@angular/material/card'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatIconModule } from '@angular/material/icon'
import { MatExpansionModule } from '@angular/material/expansion'
import { MatTabsModule } from '@angular/material/tabs'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { MatSelectModule } from '@angular/material/select'
import { MatRadioModule } from '@angular/material/radio'
import { MatChipsModule } from '@angular/material/chips'
import { MatStepperModule } from '@angular/material/stepper'
import { MatMenuModule } from '@angular/material/menu'
import { MatDividerModule } from '@angular/material/divider'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatTooltipModule } from '@angular/material/tooltip'
import { MatTreeModule } from '@angular/material/tree'
import { MatBadgeModule } from '@angular/material/badge'
import { MatNativeDateModule } from '@angular/material/core'
import { MatPaginatorModule } from '@angular/material/paginator'
// AG Grid
// =========================================================
import { AgGridModule } from 'ag-grid-angular'
import 'ag-grid-community'
import 'ag-grid-enterprise'
// Prime NG (UI) Imports
// =========================================================
import { TreeSelectModule } from 'primeng/treeselect'
import { TreeModule } from 'primeng/tree'
import { TriStateCheckboxModule } from 'primeng/tristatecheckbox'
import { AutoCompleteModule } from 'primeng/autocomplete'
import { ChartModule } from 'primeng/chart'
import { DividerModule } from 'primeng/divider'
import { DragDropModule } from 'primeng/dragdrop'
import { DropdownModule } from 'primeng/dropdown'
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogModule,
  DynamicDialogRef
} from 'primeng/dynamicdialog'
import { FileUploadModule } from 'primeng/fileupload'
import { ConfirmPopupModule } from 'primeng/confirmpopup'
import { MessageService, FilterService } from 'primeng/api'
import { OverlayPanelModule } from 'primeng/overlaypanel'
import { ToastModule } from 'primeng/toast'
import { MessagesModule } from 'primeng/messages'
import { MessageModule } from 'primeng/message'
import { MenuModule } from 'primeng/menu'
import { SplitButtonModule } from 'primeng/splitbutton'
import { SplitterModule } from 'primeng/splitter'
import { VirtualScrollerModule } from 'primeng/virtualscroller'
import { DialogModule } from 'primeng/dialog'
import { PickListModule } from 'primeng/picklist'
import { ListboxModule } from 'primeng/listbox'
// Services Imports
// =========================================================
import { AuthGuard } from './core/services/auth/auth-guard'
import { JobManagementCreatorService } from './core/services/job-management-creator-service.service'
import { SideNavPanelService } from './core/services/side-nav-panel-service'
import { LastActiveService } from './core/services/last-activity-service'
// AG Grid Custom Components Imports
// =========================================================
import { ButtonCellRenderer } from './05_ag-grid-configs/03_custom-components/button-cell-renderer/button-renderer'
import { AutoInputDelimiterCellEditor } from './05_ag-grid-configs/03_custom-components/auto-input-delimiter-cell-renderer/auto-input-delimiter-cell-editor'
import { AutoSelectionCellRenderer } from './05_ag-grid-configs/03_custom-components/auto-selection-cell-renderer/auto-selection-cell-renderer'
import { GridCustomTooltipComponent } from './05_ag-grid-configs/03_custom-components/grid-custom-tooltip/grid-custom-tooltip.component'
import { GridLoadingOverlay } from './05_ag-grid-configs/03_custom-components/grid-loading-overlay/grid-loading-overlay'
import { RowLoadingCellRenderer } from './05_ag-grid-configs/03_custom-components/row-loading-renderer/row-loading-renderer'
import { SuffixCellIconRenderer } from './05_ag-grid-configs/03_custom-components/suffix-cell-icon-renderer/suffix-cell-icon-renderer'
import { MultiCheckboxColumnRenderer } from './05_ag-grid-configs/03_custom-components/multi-checkbox-column-renderer/multi-checkbox-column-renderer'
import { CustomHeaderRenderer } from './05_ag-grid-configs/03_custom-components/custom-header-renderer/custom-header-renderer'
import { CheckmarkBooleanCellRenderer } from './05_ag-grid-configs/03_custom-components/checkmark-boolean-cell-renderer/checkmark-boolean-cell-renderer'

// Custom API Imports
// =========================================================
import { ProxyProductCreationAPIs } from './core/apis/proxy-product-creation_api-calls'
import { GeneralAPIs } from './core/apis/general_api-calls'
import { SizeDistroDefaultParametersAPIs } from './core/apis/size-distro-default-parameters_api-calls'
import { SizeDistroExceptionsAPIs } from './core/apis/size-distro-exceptions_api-calls'
import { ManagementJobsAPIs } from './core/apis/job-management_api-calls'
import { UserManagementAPIs } from './core/apis/user-management_api-calls'
import { SizeRangeAlterationsAPIs } from './core/apis/size-range-alterations_api-calls'
import { SizeDistroManagementSummaryAPIs } from './core/apis/size-distro-management-summary_api-calls'
import { SizeDistroManagementDetailGridAPIs } from './core/apis/size-distro-managment-detail-grid_api-calls'
import { StoreIndexAPIs } from './core/apis/store-index_api-calls'
import { StoreIndexOutputAPIs } from './core/apis/store-index-output_api-calls'
import { BuyingStrategyAPIs } from './core/apis/buying-strategy_api-calls'
import { ReviewOrdersAPIs } from './core/apis/review-orders_api-calls'
import { JobCreationAPIs } from './core/apis/job-creation_api-calls'
import { DrillInFilteringAPIs } from './core/apis/drill-in-filtering_api-calls'
// Custom Component Imports
// =========================================================
import { NavBarComponent } from './03_shared-components/nav-bar/nav-bar.component'
import { DefaultParametersComponent } from './01_pages/01_size-distro-strategy/default-parameters/default-parameters.component'
import { ProductCreationComponent } from './01_pages/01_size-distro-strategy/product-creation/product-creation.component'
import { JobManagementComponent } from './01_pages/02_size-distro-execution/job-management/job-management.component'
import { JobCreationComponent } from './01_pages/02_size-distro-execution/job-creation/job-creation.component'
import { JobUploadComponent } from './01_pages/02_size-distro-execution/job-upload/job-upload.component'
import { JobExceptionsComponent } from './01_pages/02_size-distro-execution/job-exceptions/job-exceptions.component'
import { DashboardComponent } from './01_pages/00_dashboard/dashboard.component'
import { ProductHierarchyComponent } from './02_shared-features/product-hierarchy/product-hierarchy.component'
import { FormFieldsComponent } from './03_shared-components/forms/form-fields/form-fields.component'
import { DefaultParametersInputFieldsComponent } from './02_shared-features/default-parameters-input-fields/default-parameters-input-fields.component'
import { AccordionHeadersComponent } from './03_shared-components/section-headers/accordion-headers/accordion-headers.component'
import { BasicHeadersComponent } from './03_shared-components/section-headers/basic-headers/basic-headers.component'
import { ConfirmPopupComponent } from './03_shared-components/01_alerts/confirm-popup/confirm-popup.component'
import { ToastAlertComponent } from './03_shared-components/01_alerts/toast-alert/toast-alert.component'
import { DashboardTileComponent } from './03_shared-components/dashboard-tile/dashboard-tile.component'
import { ErrModalComponent } from './04_modals/03_error-modal/err-modal.component'
import { ModalHeaderComponent } from './04_modals/01_modal-header/modal-header.component'
import { ProductUploadComponent } from './01_pages/01_size-distro-strategy/product-upload/product-upload.component'
import { ModalFooterComponent } from './04_modals/02_modal-footer/modal-footer.component'
import { ProxyProductDetailsComponent } from './04_modals/proxy-product-details/proxy-product-details.component'
import { LoaderComponent } from './03_shared-components/loader/loader.component'
import { UploadGridsComponent } from './02_shared-features/upload-grids/upload-grids.component'
import { FormWrapperComponent } from './03_shared-components/forms/form-wrapper/form-wrapper.component'
import { JobManagementStatusAlertComponent } from './05_ag-grid-configs/03_custom-components/custom-comp-popover-alerts/job-management-status-alert/job-management-status-alert.component'
import { UserManagementComponent } from './01_pages/03_admin/user-management/user-management.component'
import { MultiSelectionCellRenderer } from 'src/app/05_ag-grid-configs/03_custom-components/multi-selection-cell-renderer/multi-selection-cell-renderer'
import { SizeDistroManagementSummaryComponent } from './01_pages/04_size-distro-management/size-distro-management-summary/size-distro-management-summary.component'
import { SizeDistroManagementDetailGridComponent } from './01_pages/04_size-distro-management/size-distro-management-detail-grid/size-distro-management-detail-grid.component'
import { DistroCopyBuyingSeasonModalComponent } from './04_modals/size-distro-management/distro-copy-buying-season-modal/distro-copy-buying-season-modal.component'
import { DistroCopyNewProductModalComponent } from './04_modals/size-distro-management/distro-copy-new-product-modal/distro-copy-new-product-modal.component'
import { BulkOverridesModalComponent } from './04_modals/size-distro-management/bulk-overrides-modal/bulk-overrides-modal.component'
import { SizeRangeAlterationsModalComponent } from './04_modals/size-distro-management/size-range-alterations-modal/size-range-alterations-modal.component'
import { DrillInVisualizeModalComponent } from './04_modals/size-distro-management/drill-in-visualize-modal/drill-in-visualize-modal.component'
import { SizeDistroManagementSummaryToolPanelComponent } from './05_ag-grid-configs/03_custom-components/custom-tool-panels/size-distro-management-summary-tool-panel/size-distro-management-summary-tool-panel.component'
import { CreateEditStoreIndexJobModalComponent } from './04_modals/store-indexes/create-edit-store-index-job-modal/create-edit-store-index-job-modal.component'
import { CopyStoreIndexJobModalComponent } from './04_modals/store-indexes/copy-store-index-job-modal/copy-store-index-job-modal.component'
import { OutputGridDataModalComponent } from './04_modals/store-indexes/output-grid-data-modal/output-grid-data-modal.component'
import { StoreIndexComponent } from './01_pages/05_store-index/store-index.component'
import { BuyingStrategyComponent } from './01_pages/06_buying/buying-strategy/buying-strategy.component'
import { ReviewOrdersComponent } from './01_pages/06_buying/review-orders/review-orders.component'
import { RoAgGridComponent } from './01_pages/06_buying/review-orders/ro-ag-grid/ro-ag-grid.component'
import { UserInputConfigSettingsComponent } from './04_modals/review-orders/user-input-config/user-input-config.component'
import { PackSummaryConfigComponent } from './04_modals/review-orders/pack-summary-config/pack-summary-config.component'
import { MismatchChartComponent } from './04_modals/review-orders/mismatch-chart/mismatch-chart.component'
import { StoreTotalsChartsComponent } from './04_modals/review-orders/store-totals-charts/store-totals-charts.component'
import { CartonizationSettingsInputFieldsComponent } from './02_shared-features/cartonization-settings-input-fields/cartonization-settings-input-fields.component'
import { CartonIDSizesTooltip } from './05_ag-grid-configs/03_custom-components/grid-custom-tooltip/review-orders-carton_id-tooltip/review-orders-carton_id-tooltip.component'
import { MetricsCustomTooltipComponent } from './05_ag-grid-configs/03_custom-components/grid-custom-tooltip/metrics-custom-tooltip/metrics-custom-tooltip.component'
import { DrillInFilteringComponent } from './04_modals/size-distro-management/drill-in-filtering/drill-in-filtering.component'
import { PanelModule } from 'primeng/panel'
import { ArrayCustomTooltipComponent } from './05_ag-grid-configs/03_custom-components/grid-custom-tooltip/array-custom-tooltip/array-custom-tooltip.component'
import { SizeRangeDefinitionsComponent } from './01_pages/01_size-distro-strategy/size-range-definitions/size-range-definitions.component'
import { SizeRangeDefinitionModalComponent } from './04_modals/size-range-definition-modal/size-range-definition-modal.component'
import { SizeRangeDefinitionsAPIs } from './core/apis/size-range-definitions_api-calls'
import { SizeRangeAssignmentsComponent } from './01_pages/01_size-distro-strategy/size-range-assignments/size-range-assignments.component'
import { SizeRangeAssignmentsModalComponent } from './04_modals/size-range-assignments-modal/size-range-assignments-modal.component'
import { SizeRangeAssignmentsAPIs } from './core/apis/size-range-assignment_api-calls'
import { ConditionalExpandArrowCellRenderer } from './05_ag-grid-configs/03_custom-components/conditional-expand-arrow-cell-renderer/conditional-expand-arrow-cell-renderer'
import { NoChildrenGroupCellRenderer } from './05_ag-grid-configs/03_custom-components/no-children-group-cell-renderer/no-children-group-cell-renderer.component'
import { BusinessRulesEngineComponent } from './01_pages/01_size-distro-strategy/business-rules-engine/business-rules-engine.component'
import { BusinessRulesEngineModalComponent } from './04_modals/business-rules-engine-modal/business-rules-engine-modal.component'
import { BusinessRulesEngineAPIs } from './core/apis/business-rules-engine_api-calls'

@NgModule({
  declarations: [
    // AG Grid Custom Components
    ButtonCellRenderer,
    CheckmarkBooleanCellRenderer,
    GridCustomTooltipComponent,
    ArrayCustomTooltipComponent,
    CartonIDSizesTooltip,
    MetricsCustomTooltipComponent,
    RowLoadingCellRenderer,
    GridLoadingOverlay,
    AutoSelectionCellRenderer,
    AutoInputDelimiterCellEditor,
    ProductUploadComponent,
    SuffixCellIconRenderer,
    MultiCheckboxColumnRenderer,
    CustomHeaderRenderer,
    // General Component Imports
    AppComponent,
    NavBarComponent,
    DefaultParametersComponent,
    ProductCreationComponent,
    JobManagementComponent,
    JobCreationComponent,
    JobUploadComponent,
    JobExceptionsComponent,
    SizeRangeDefinitionsComponent,
    SizeRangeAssignmentsComponent,
    DashboardComponent,
    ProductHierarchyComponent,
    FormFieldsComponent,
    DefaultParametersInputFieldsComponent,
    AccordionHeadersComponent,
    BasicHeadersComponent,
    ConfirmPopupComponent,
    ToastAlertComponent,
    DashboardTileComponent,
    ModalHeaderComponent,
    ModalFooterComponent,
    ErrModalComponent,
    ProxyProductDetailsComponent,
    LoaderComponent,
    UploadGridsComponent,
    FormWrapperComponent,
    JobManagementStatusAlertComponent,
    UserManagementComponent,
    MultiSelectionCellRenderer,
    SizeDistroManagementSummaryComponent,
    SizeDistroManagementDetailGridComponent,
    DistroCopyBuyingSeasonModalComponent,
    DistroCopyNewProductModalComponent,
    BulkOverridesModalComponent,
    SizeRangeAlterationsModalComponent,
    DrillInVisualizeModalComponent,
    SizeDistroManagementSummaryToolPanelComponent,
    CreateEditStoreIndexJobModalComponent,
    CopyStoreIndexJobModalComponent,
    OutputGridDataModalComponent,
    StoreIndexComponent,
    BuyingStrategyComponent,
    ReviewOrdersComponent,
    RoAgGridComponent,
    UserInputConfigSettingsComponent,
    PackSummaryConfigComponent,
    MismatchChartComponent,
    StoreTotalsChartsComponent,
    CartonizationSettingsInputFieldsComponent,
    DrillInFilteringComponent,
    SizeRangeDefinitionModalComponent,
    SizeRangeAssignmentsModalComponent,
    ConditionalExpandArrowCellRenderer,
    NoChildrenGroupCellRenderer,
    BusinessRulesEngineComponent,
    BusinessRulesEngineModalComponent
  ],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    AmplifyAuthenticatorModule,
    AgGridModule,
    // Angular material Importss
    MatToolbarModule,
    MatSidenavModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatIconModule,
    MatExpansionModule,
    MatTabsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatRadioModule,
    MatChipsModule,
    MatStepperModule,
    MatMenuModule,
    MatDividerModule,
    MatDatepickerModule,
    MatTooltipModule,
    MatTreeModule,
    MatBadgeModule,
    MatNativeDateModule,
    // Prime NG Imports
    TreeSelectModule,
    TreeModule,
    TriStateCheckboxModule,
    AutoCompleteModule,
    ChartModule,
    DragDropModule,
    DropdownModule,
    FileUploadModule,
    ConfirmPopupModule,
    ToastModule,
    MessagesModule,
    MessageModule,
    MenuModule,
    DividerModule,
    SplitButtonModule,
    SplitterModule,
    OverlayPanelModule,
    VirtualScrollerModule,
    DialogModule,
    PickListModule,
    ListboxModule,
    MatPaginatorModule,
    PanelModule
  ],
  providers: [
    AuthGuard,
    DialogService,
    DynamicDialogConfig,
    DynamicDialogRef,
    MessageService,
    FilterService,
    SideNavPanelService,
    JobManagementCreatorService,
    SizeDistroExceptionsAPIs,
    ManagementJobsAPIs,
    SizeDistroDefaultParametersAPIs,
    ProxyProductCreationAPIs,
    SizeRangeAlterationsAPIs,
    GeneralAPIs,
    JobCreationAPIs,
    UserManagementAPIs,
    SizeDistroManagementSummaryAPIs,
    SizeDistroManagementDetailGridAPIs,
    StoreIndexAPIs,
    StoreIndexOutputAPIs,
    BuyingStrategyAPIs,
    ReviewOrdersAPIs,
    DrillInFilteringAPIs,
    SizeRangeDefinitionsAPIs,
    SizeRangeAssignmentsAPIs,
    BusinessRulesEngineAPIs,
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [LastActiveService],
      useFactory: (lastActiveService: LastActiveService) => () =>
        lastActiveService.initializeTimer()
    },
    provideHttpClient(withInterceptorsFromDi())
  ]
})
export class AppModule {}
