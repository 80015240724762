import { Injectable } from '@angular/core'
import { DialogService } from 'primeng/dynamicdialog'
import { ToastAlertComponent } from '../../03_shared-components/01_alerts/toast-alert/toast-alert.component'
import { ExecuteDeleteAPI, ExecuteGetAPI, ExecutePostAPI, ExecutePutAPI } from './execute_api-calls'

@Injectable()
export class BusinessRulesEngineAPIs {
  constructor (public dialogService: DialogService, public toastAlert: ToastAlertComponent) {}

  async GetAllRules () {
    const path = '/rules'
    const messages = {
      success: '---> API Call Successful: Get All Rules',
      error: 'Error retrieving rules.'
    }

    const response = await ExecuteGetAPI(path, this, messages)

    if (response && response.is_success) {
      return response.data.sort((a, b) => a.priority_order - b.priority_order)
    }

    return [{}]
  }

  async UpdatePriorityOrder (rules) {
    const path = '/rules'
    const messages = {
      success: '---> API Call Successful: Update Priority Order',
      error: 'Error updating priority order.'
    }

    const data = {
      rules: rules.map(rule => {
        return {
          rule_id: rule.rule_id,
          priority_order: rule.priority_order
        }
      })
    }

    return await ExecutePutAPI(path, this, data, messages)
  }

  async GetAllConditionsAndActions () {
    const path = '/rules/get_conditions_actions'
    const messages = {
      success: '---> API Call Successful: Get All Conditions and Actions',
      error: 'Error retrieving conditions and actions.'
    }

    const response = await ExecuteGetAPI(path, this, messages)

    if (response && response.is_success) {
      return response.data
    }
  }

  async AddRule (rule) {
    const path = '/rules'
    const messages = {
      success: '---> API Call Successful: Add Rule',
      error: 'Error adding rule.'
    }

    const data = {
      rule: {
        rule_name: rule.name,
        rule_description: '',
        priority_order: 999,
        conditions: rule.conditions.map(condition => {
          return {
            condition_id: condition.condition_id,
            input_name: condition.input_name,
            input_value: condition.input_value
          }
        }),
        actions: rule.actions.map(action => {
          return {
            action_id: action.action_id,
            action_execution_order: action.action_execution_order,
            action_inputs: action.action_inputs.map(input => {
              return {
                input_name: input.input_name,
                input_value: input.input_value
              }
            })
          }
        })
      }
    }

    // console.log('Calling `POST /rules` with data: ', data)

    return await ExecutePostAPI(path, this, data, messages)
  }

  async DeleteRule (rule_id: number) {
    const path = `/rules/${rule_id}`
    const messages = {
      success: '---> API Call Successful: Delete Rule',
      error: 'Error deleting rule.'
    }

    return await ExecuteDeleteAPI(path, this, messages)
  }

  async DeleteRules (ruleIdsToDelete: number[]) {
    const path = '/rules/delete'
    const messages = {
      success: '---> API Call Successful: Delete Rules',
      error: 'Error deleting rules.'
    }

    const data = { rule: ruleIdsToDelete }

    return await ExecutePostAPI(path, this, data, messages)
  }

  async EnableRules (ruleIdsToEnable: number[]) {
    const path = '/rules'
    const messages = {
      success: '---> API Call Successful: Enable Rules',
      error: 'Error enabling rules.'
    }

    const data = {
      rules: ruleIdsToEnable.map(ruleId => {
        return {
          rule_id: ruleId,
          is_active: true
        }
      })
    }

    return await ExecutePutAPI(path, this, data, messages)
  }

  async DisableRules (ruleIdsToDisable: number[]) {
    const path = '/rules'
    const messages = {
      success: '---> API Call Successful: Disable Rules',
      error: 'Error disabling rules.'
    }

    const data = {
      rules: ruleIdsToDisable.map(ruleId => {
        return {
          rule_id: ruleId,
          is_active: false
        }
      })
    }

    return await ExecutePutAPI(path, this, data, messages)
  }
}
