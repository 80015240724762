export const SizeRangeDefinitionFormSettings = {
  size_range_definitions_config: {
    size_range_name: {
      controlName: 'size_range_name',
      label: 'Size Range Name',
      placeholder: null,
      hint: 'Must be unique',
      formType: 'input',
      datatype: 'string',
      selectMultiple: null,
      selectionOptions: null,
      loading: false,
      disabled: false,
      autoComplete: false,
      reset: false,
      defaultVal: null,
      validatorOptions: {
        required: true,
        minLength: 3,
        checkForExistingValueInput: []
      },
      formDisplay: {
        formWidth: '50%'
      }
    },
    sizes: {
      controlName: 'sizes',
      label: 'Sizes',
      placeholder: null,
      hint: null,
      formType: 'auto-selection',
      datatype: 'array',
      selectMultiple: true,
      selectionOptions: [],
      loading: true,
      disabled: false,
      autoComplete: false,
      reset: false,
      defaultVal: null,
      currentVal: null,
      validatorOptions: {
        required: true
      },
      formDisplay: {
        formWidth: '50%'
      }
    }
  }
}
