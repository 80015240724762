export const SizeRangeAssignmentsFormSettings = {
  size_range_assignments_config: {
    size_range_name: {
      controlName: 'size_range_name',
      label: 'Size Range Name',
      placeholder: null,
      hint: null,
      formType: 'auto-selection',
      datatype: 'array',
      selectMultiple: false,
      selectionOptions: [],
      loading: true,
      disabled: false,
      autoComplete: false,
      reset: false,
      defaultVal: null,
      validatorOptions: {
        required: true
      },
      formDisplay: {
        formWidth: '100%'
      }
    },
    product_id: {
      controlName: 'product_id',
      label: 'Assign to Product',
      placeholder: null,
      hint: null,
      formType: 'auto-selection',
      datatype: 'array',
      selectMultiple: false,
      selectionOptions: [],
      loading: true,
      disabled: false,
      autoComplete: false,
      reset: false,
      defaultVal: null,
      validatorOptions: {
        required: true
      },
      formDisplay: {
        formWidth: '100%'
      }
    },
    eligible_channel: {
      controlName: 'eligible_channel',
      label: 'Eligible Channel',
      placeholder: null,
      hint: null,
      formType: 'auto-selection',
      datatype: 'array',
      selectMultiple: false,
      selectionOptions: [],
      loading: true,
      disabled: false,
      autoComplete: false,
      reset: false,
      defaultVal: null,
      validatorOptions: {
        required: true
      },
      formDisplay: {
        formWidth: '100%'
      }
    },
    is_future: {
      controlName: 'is_future',
      label: 'Future Size Range',
      placeholder: null,
      hint: null,
      formType: 'checkbox',
      datatype: 'boolean',
      selectMultiple: null,
      selectionOptions: null,
      loading: false,
      disabled: false,
      autoComplete: false,
      reset: false,
      defaultVal: null,
      validatorOptions: {},
      formDisplay: {
        formWidth: '100%'
      }
    },
    historical_size_range: {
      controlName: 'historical_size_range',
      label: 'Historical Size Range',
      placeholder: null,
      hint: null,
      formType: 'auto-selection',
      datatype: 'array',
      selectMultiple: false,
      selectionOptions: [],
      loading: true,
      disabled: true,
      autoComplete: false,
      reset: false,
      defaultVal: null,
      validatorOptions: {
        required: false
      },
      formDisplay: {
        formWidth: '100%'
      }
    }
  }
}
