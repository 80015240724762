import { Component, type OnInit } from '@angular/core'
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog'
import { TriggerApiCallsService } from '../../core/services/cancel-api-call'
import { ToastAlertComponent } from '../../03_shared-components/01_alerts/toast-alert/toast-alert.component'
import { GeneralAPIs } from '../../core/apis/general_api-calls'
import { BusinessRulesEngineAPIs } from '../../core/apis/business-rules-engine_api-calls'
import {
  type AbstractControl,
  type FormArray,
  FormBuilder,
  type FormControl,
  type FormGroup,
  type ValidationErrors,
  type ValidatorFn,
  Validators
} from '@angular/forms'
import { type MatAutocompleteSelectedEvent } from '@angular/material/autocomplete'

interface Condition {
  condition_id: number
  input_name: string
  input_value: any[]
}

interface Action {
  action_id: number
  action_execution_order: number
  action_inputs: ActionInput[]
}

interface ActionInput {
  input_name: string
  input_value: any
}

interface ConditionChoice {
  condition_id: number
  category: string
  condition: string
  datatype: string
  options: any[]
  data_query: string
}

interface ActionChoice {
  action_id: number
  category: string
  action_title: string
  inputs: ActionChoiceInput[]
}

interface ActionChoiceInput {
  input_name: string
  input_type: string
  options: any[]
  data_query: string
}

@Component({
  selector: 'app-business-rules-engine-modal',
  templateUrl: './business-rules-engine-modal.component.html',
  styleUrl: './business-rules-engine-modal.component.scss'
})
export class BusinessRulesEngineModalComponent implements OnInit {
  loading: boolean = true
  loadingMessage: string = 'loading'
  ruleForm: FormGroup
  conditionChoices: ConditionChoice[]
  actionChoices: ActionChoice[]
  existingRules: any[]

  get title (): string {
    if (this.config.data.mode === 'copy') {
      return 'Copy Rule'
    } else if (this.config.data.mode === 'edit') {
      return 'Edit Rule'
    } else {
      return 'Add Rule'
    }
  }

  constructor (
    private readonly dynamicDialogRef: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public dialogService: DialogService,
    public triggerApiCallsService: TriggerApiCallsService,
    public toastAlert: ToastAlertComponent,
    public generalAPIs: GeneralAPIs,
    private readonly businessRulesEngineAPIs: BusinessRulesEngineAPIs,
    private readonly formBuilder: FormBuilder
  ) {
    this.ruleForm = this.formBuilder.group({
      name: ['', [Validators.required, this.uniqueRuleNameValidator()]],
      conditions: this.formBuilder.array<Condition>([]),
      actions: this.formBuilder.array<Action>([])
    })
  }

  ngOnInit (): void {
    this.conditionChoices = this.config.data.conditions
    this.actionChoices = this.config.data.actions
    this.existingRules = this.config.data.existingRules

    // pre-populate the form with the data from the given rule
    if (this.config.data.rule) {
      this.ruleForm.patchValue(this.config.data.rule)

      for (const condition of this.config.data.rule.conditions) {
        const conditionGroup = this.formBuilder.group({
          condition_id: condition.condition_id,
          input_name: condition.input_name,
          input_value: this.formBuilder.array(condition.input_value)
        })
        this.conditions.push(conditionGroup)
      }

      for (const action of this.config.data.rule.actions) {
        const actionGroup = this.formBuilder.group({
          action_id: action.action_id,
          action_execution_order: action.action_execution_order,
          action_inputs: this.formBuilder.array(
            action.action_inputs.map(input =>
              this.formBuilder.group({
                input_name: input.input_name,
                input_value: input.input_value
              })
            )
          )
        })
        this.actions.push(actionGroup)
      }
    }

    this.loading = false
  }

  get conditions (): FormArray {
    return this.ruleForm.get('conditions') as FormArray
  }

  get actions (): FormArray {
    return this.ruleForm.get('actions') as FormArray
  }

  get name () {
    return this.ruleForm.get('name') as FormControl
  }

  addCondition () {
    const conditionGroup = this.formBuilder.group({
      condition_id: 0,
      input_name: '',
      input_value: this.formBuilder.array([])
    })
    this.conditions.push(conditionGroup)
  }

  removeCondition (i: number) {
    this.conditions.removeAt(i)
  }

  addAction () {
    const actionGroup = this.formBuilder.group({
      action_id: [''],
      action_execution_order: [''],
      action_inputs: this.formBuilder.array([])
    })
    this.actions.push(actionGroup)
  }

  removeAction (i: number) {
    this.actions.removeAt(i)
  }

  addConditionInput (conditionIndex: number) {
    const inputs = this.conditions.at(conditionIndex).get('input_value') as FormArray
    inputs.push(this.formBuilder.control(''))
  }

  addActionInput (actionIndex: number) {
    const inputs = this.actions.at(actionIndex).get('action_inputs') as FormArray
    inputs.push(this.createActionInput())
  }

  private createActionInput () {
    return this.formBuilder.group({
      input_name: [''],
      input_value: ['']
    })
  }

  async onSubmit () {
    console.log('Submitting Form', this.ruleForm.value)

    await this.addRule(this.ruleForm.value)

    this.close()
  }

  private async addRule (rule) {
    await this.businessRulesEngineAPIs.AddRule(rule)
  }

  getActionInputs (action: AbstractControl): FormArray {
    return action.get('action_inputs') as FormArray
  }

  getConditionInputs (condition: AbstractControl): FormArray {
    return condition.get('input_value') as FormArray
  }

  close (data?: any) {
    if (this.dynamicDialogRef) {
      this.dynamicDialogRef.close(data || null)
    }
  }

  private uniqueRuleNameValidator (): ValidatorFn {
    return (control: FormControl): ValidationErrors | null => {
      // HACK: prevent the validator from running when the form is first initialized
      if (!this.existingRules || this.existingRules.length === 0) {
        return null
      }

      if (this.existingRules.some(rule => rule.rule_name === control.value)) {
        return { unique: true }
      }

      return null
    }
  }

  onConditionSelected (event: MatAutocompleteSelectedEvent, index: number) {
    console.log('Selected Condition:', event.option.value)
    const selectedOption = event.option.value as ConditionChoice
    const conditionsArray = this.ruleForm.get('conditions') as FormArray
    const conditionGroup = conditionsArray.at(index) as FormGroup

    // Update the form controls with the selected option's properties
    conditionGroup.patchValue({
      condition_id: selectedOption.condition_id,
      // TODO: figure out if the `input_name` will ever be anything other than "value"
      input_name: 'value'
      // TODO: figure out if the `input_value` will ever have multiple values
      // input_value: []
    })

    // Clear any existing condition inputs
    const inputs = this.getConditionInputs(conditionGroup)

    while (inputs.length) {
      inputs.removeAt(0)
    }

    // Add a new condition input
    this.addConditionInput(index)
  }

  onActionSelected (event: MatAutocompleteSelectedEvent, index: number) {
    console.log('Selected Action:', event.option.value)
    const selectedOption = event.option.value as ActionChoice
    const actionsArray = this.ruleForm.get('actions') as FormArray
    const actionGroup = actionsArray.at(index) as FormGroup

    // Update the form controls with the selected option's properties
    actionGroup.patchValue({
      action_id: selectedOption.action_id,
      action_execution_order: index + 1
    })

    // Clear any existing action inputs
    const inputs = this.getActionInputs(actionGroup)

    while (inputs.length) {
      inputs.removeAt(0)
    }

    // Add the correct number of inputs with the correct names, etc.
    for (const input of selectedOption.inputs) {
      const inputGroup = this.createActionInput()

      inputGroup.patchValue({
        input_name: input.input_name
      })

      inputs.push(inputGroup)
    }
  }

  displayCondition (condition: any): string {
    return condition ? condition.condition : ''
  }

  displayAction (action: any): string {
    return action ? action.action_title : ''
  }
}
