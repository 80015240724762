import { Component, type OnInit } from '@angular/core'
import { DialogService } from 'primeng/dynamicdialog'
import { BusinessRulesEngineModalComponent } from '../../../04_modals/business-rules-engine-modal/business-rules-engine-modal.component'
import { type CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop'
import { BusinessRulesEngineAPIs } from '../../../core/apis/business-rules-engine_api-calls'

interface Rule {
  selected: boolean
  priority_order: number
  rule_id: number
  rule_name: string
  created_by: string
  created_at: string
  modified_by: string
  modified_at: string
  last_run: string
  is_active: boolean
  conditions: Array<{
    condition_id: number
    condition_category: string
    condition_name: string
    input_name: string
    input_value: string[]
  }>
  actions: Array<{
    action_id: number
    action_category: string
    action_title: string
    action_execution_order: number
    action_inputs: Array<{
      input_name: string
      input_value: string
      input_order: number
    }>
  }>
}

@Component({
  selector: 'app-business-rules-engine',
  templateUrl: './business-rules-engine.component.html',
  styleUrl: './business-rules-engine.component.scss'
})
export class BusinessRulesEngineComponent implements OnInit {
  loading: boolean = true
  loadingMessage: string = 'loading'
  rules: Rule[] = []
  conditions: any[] = []
  actions: any[] = []
  canRunRules: boolean = false

  get enableBatchOperations () {
    return this.rules.filter(rule => rule.selected).length > 0
  }

  constructor (
    public dialogService: DialogService,
    private readonly businessRulesEngineAPIs: BusinessRulesEngineAPIs
  ) {}

  async ngOnInit (): Promise<void> {
    await this.getAllRules()
    await this.getAllConditionsAndActions()

    // console.log('this.rules', this.rules)
    // console.log('this.conditions', this.conditions)
    // console.log('this.actions', this.actions)

    this.loading = false
  }

  async drop (event: CdkDragDrop<string[]>) {
    moveItemInArray(this.rules, event.previousIndex, event.currentIndex)

    this.rules.forEach((rule: Rule, index: number) => {
      rule.priority_order = index + 1
    })

    await this.businessRulesEngineAPIs.UpdatePriorityOrder(this.rules)
  }

  addRule () {
    this.dialogService.open(BusinessRulesEngineModalComponent, {
      showHeader: false,
      closeOnEscape: true,
      dismissableMask: false,
      styleClass: 'medium-modal',
      data: {
        mode: 'add',
        conditions: this.conditions,
        actions: this.actions,
        existingRules: this.rules,
        close: () => {}
      }
    })
  }

  copyRule (rule: Rule) {
    this.dialogService.open(BusinessRulesEngineModalComponent, {
      showHeader: false,
      closeOnEscape: true,
      dismissableMask: false,
      styleClass: 'medium-modal',
      data: {
        mode: 'copy',
        conditions: this.conditions,
        actions: this.actions,
        existingRules: this.rules,
        rule: { ...rule, name: `${rule.rule_name} (Copy)` },
        close: () => {}
      }
    })
  }

  editRule (rule: Rule) {
    this.dialogService.open(BusinessRulesEngineModalComponent, {
      showHeader: false,
      closeOnEscape: true,
      dismissableMask: false,
      styleClass: 'medium-modal',
      data: {
        mode: 'edit',
        conditions: this.conditions,
        actions: this.actions,
        existingRules: this.rules,
        rule: { ...rule, name: `${rule.rule_name}` },
        close: () => {}
      }
    })
  }

  runRule (event: any, rule: Rule) {
    if (event.userInput === 'except') {
      // TODO: Implement the ability to run rules
    }
  }

  bulkRunRules (event: any) {
    if (event.userInput === 'except') {
      // TODO: Implement the ability to run rules in bulk
    }
  }

  async deleteRule (event: any, index: number) {
    if (event.userInput === 'except') {
      this.loading = true

      const response = await this.businessRulesEngineAPIs.DeleteRule(this.rules[index].rule_id)

      if (response && response.is_success) {
        this.rules.splice(index, 1)
      }

      this.loading = false
    }
  }

  async bulkDeleteRules (event: any) {
    if (event.userInput === 'except') {
      this.loading = true

      const ruleIdsToDelete = this.rules.filter(rule => rule.selected).map(rule => rule.rule_id)

      const response = await this.businessRulesEngineAPIs.DeleteRules(ruleIdsToDelete)

      if (response && response.is_success) {
        this.rules = this.rules.filter(r => !ruleIdsToDelete.some(ruleId => ruleId === r.rule_id))
      }

      this.loading = false
    }
  }

  async bulkEnableRules () {
    this.loading = true

    const ruleIdsToEnable = this.rules.filter(rule => rule.selected).map(rule => rule.rule_id)

    const response = await this.businessRulesEngineAPIs.EnableRules(ruleIdsToEnable)

    if (response && response.is_success) {
      this.rules
        .filter(r => ruleIdsToEnable.some(ruleId => ruleId === r.rule_id))
        .forEach(rule => (rule.is_active = true))
    }

    this.loading = false
  }

  async bulkDisableRules () {
    this.loading = true

    const ruleIdsToDisable = this.rules.filter(rule => rule.selected).map(rule => rule.rule_id)

    const response = await this.businessRulesEngineAPIs.DisableRules(ruleIdsToDisable)

    if (response && response.is_success) {
      this.rules
        .filter(r => ruleIdsToDisable.some(ruleId => ruleId === r.rule_id))
        .forEach(rule => (rule.is_active = false))
    }

    this.loading = false
  }

  selectionChanged (rule: Rule) {
    rule.selected = !rule.selected
  }

  selectAll () {
    this.rules.forEach(rule => (rule.selected = true))
  }

  clearSelection () {
    this.rules.forEach(rule => (rule.selected = false))
  }

  private async getAllRules () {
    const response = await this.businessRulesEngineAPIs.GetAllRules()

    if (response) {
      this.rules = response.map((rule: Rule) => ({
        ...rule,
        selected: false
      }))
    } else {
      this.rules = []
    }
  }

  private async getAllConditionsAndActions () {
    const response = await this.businessRulesEngineAPIs.GetAllConditionsAndActions()

    if (response) {
      this.conditions = response.conditions
      this.actions = response.actions
    }
  }
}
