<div
    class="custom-tooltip"
    [ngClass]="{ below: isTooltipBelow, left: !isTooltipBelow }"
>
    <p *ngIf="isHeaderCell" class="array-tooltip text-center">{{ value }}</p>
    <div *ngIf="!isHeaderCell">
        <div *ngFor="let entry of packConfigsEntries" class="array-tooltip-container">
            <p *ngIf="packConfigsEntries.length > 1" class="styc-id-name">{{entry[0]}}</p>
            <ul class="array-tooltip">
                <li *ngFor="let sizeEntry of entry[1]">{{ sizeEntry[0] }} - {{ sizeEntry[1] }}</li>
            </ul>
        </div>
    </div>
</div>
