// Angular Imports
// =========================================================
import { Component, Input, ViewChild } from '@angular/core'
// AG Grid Imports
// =========================================================
import { type ICellRendererAngularComp } from 'ag-grid-angular'
import type { ICellRendererParams } from 'ag-grid-community'

@Component({
  selector: 'child-cell',
  templateUrl: './conditional-expand-arrow-cell-renderer.html',
  styleUrls: ['./conditional-expand-arrow-cell-renderer.scss']
})
export class ConditionalExpandArrowCellRenderer implements ICellRendererAngularComp {
  data: any
  cellValue: any
  onExpandCollapsePinnedRow: Function

  agInit (params: ICellRendererParams<any, any>): void {
    this.cellValue = params.value
    this.data = params.data
    this.onExpandCollapsePinnedRow = (data, expand) => params.context.onExpandCollapsePinnedRow(data, expand)
  }

  onClick (event) {
    if (this.data.isExpanded) {
      this.data.isExpanded = false
      this.onExpandCollapsePinnedRow(this.data, false)
    } else {
      this.data.isExpanded = true
      this.onExpandCollapsePinnedRow(this.data, true)
    }
  }

  refresh (params: ICellRendererParams<any, any>): boolean {
    return true
  }
}
