import { FormatKey, IsKeyInObj } from 'src/app/utils/global_functions'
import { GenerateDefaultColDefs } from '../02_global-settings/global-cell-formatter'
import { ICellRendererParams } from 'ag-grid-community';

export interface SizeRangeAssignments_GridDataParams {
  importedColDefs: any[]
}

export async function SizeRangeAssignments_GenerateGridData (
  params: SizeRangeAssignments_GridDataParams
) {
  const { importedColDefs } = params

  const GenerateCols = async (
    importedColDefsParam: any[],
    gridToRender: string
  ) => {
    const mappedCols = {}

    Object.entries(importedColDefsParam).map(([colKey, colVal]) => {
      const colId = FormatKey(colKey)

      const generatedColDefs = {
        ...GenerateDefaultColDefs(colKey, { colId, ...colVal })
      }

      Object.assign(mappedCols, {
        [generatedColDefs.colId]:
                    SizeRangeAssignments_customCellFormatting(
                      generatedColDefs,
                      gridToRender
                    )
      })
    })

    return Object.values(mappedCols)
  }

  const getResults = async () => {
    const mainCols = await GenerateCols(importedColDefs, 'main')
    const results = await Promise.all([mainCols])
    const m = results.indexOf(mainCols)

    return { mainColDefs: results[m] }
  }

  return await getResults()
}

export const SizeRangeAssignments_customCellFormatting = (
  col,
  gridToRender
) => {
  const getParams = () => {
    if (gridToRender === 'main') {
      switch (col.colId) {
        case 'product_id':
          return { sort: 'asc', sortIndex: 0 }
        case 'eligible_channel':
          return { sort: 'asc', sortIndex: 1 }
        case 'size_range_name':
          return { sort: 'asc', sortIndex: 2 }
        case 'historical_size_range':
          return {
            editable: (params: any) => params.data.is_future,
            cellEditorParams: {
              propertyRendered: 'historical_size_range',
              returnObject: false,
              rowData: [],
              columnDefs: [
                {
                  headerName: 'Historical Size Range',
                  checkboxSelection: true,
                  refData: {
                    datatype: 'string',
                    custom_field: 'size_range_name'
                  },
                  suppressMenu: true,
                  valueFormatter: (params: any) => {
                    if (params.data) {
                      const { size_range_name } =
                                                params.data
                      return `${size_range_name}`
                    }
                    return 'Select A Size Range'
                  }
                }
              ]
            },
            valueFormatter: (params: any) => {
              const value = params.data[col.colId]

              return (params.value =
                                value && value[0] !== null && value.length > 0
                                  ? value
                                  : 'No range selected.')
            }
          }
        case 'edited': {
          return {
            valueGetter: (params: any) => {
              if (
                params.value === null ||
                                !IsKeyInObj(params.data, 'edited')
              ) {
                params.data.edited = false
                params.value = false
              } else {
                params.value = params?.data?.edited
                return params.value
              }
            }
          }
        }
        case 'save':
          return {
            cellRendererParams: (params: ICellRendererParams) => {
              return {
                disabled: (!params?.data?.edited),
                toolTipMessage: `Save changes for: ${params.data.size_range_name}`,
                onClick: (params: ICellRendererParams) => {
                  params.context.saveRow(params.data)
                  delete params?.data?.edited
                }
              }
            }
          }
      }
    }
  }

  return { ...col, ...getParams() }
}
