import {
  Component,
  type OnInit,
  ViewChild,
  type OnDestroy,
  HostListener
} from '@angular/core'
import { Router } from '@angular/router'
// JSON Data
// =========================================================
import $sizeRangeDefinitionsJSON from '../../../05_ag-grid-configs/01_json-grid-configs/colDefs-main-size-range-definitions.json'
// Ag Grid Imports
// =========================================================
import {
  type GridReadyEvent,
  type GridApi,
  type ColumnApi,
  type SelectionChangedEvent,
  type RowDataUpdatedEvent
} from 'ag-grid-community'
// Prime NG Imports
// =========================================================
import { DynamicDialogRef, DialogService } from 'primeng/dynamicdialog'
// Custom Imports
// =========================================================
import {
  AutoSizeColumns,
  CustomGridOptions,
  DefaultGridContextMenu
} from '../../../05_ag-grid-configs/02_global-settings/grid-options'
import { JobManagementCreatorService } from 'src/app/core/services/job-management-creator-service.service'
import { ConfirmPopupComponent } from 'src/app/03_shared-components/01_alerts/confirm-popup/confirm-popup.component'
import { FormatKey } from 'src/app/utils/global_functions'
import { TriggerApiCallsService } from 'src/app/core/services/cancel-api-call'
import { GeneralAPIs } from 'src/app/core/apis/general_api-calls'
import { SideBarPanels } from 'src/app/05_ag-grid-configs/02_global-settings/grid-variables'
import { SaveGridState } from 'src/app/05_ag-grid-configs/02_global-settings/grid-functions-general'
import { SizeRangeDefinitionsAPIs } from 'src/app/core/apis/size-range-definitions_api-calls'
import {
  SizeRangeDefinitions_GenerateGridData,
  SRD_customCellFormatting
} from 'src/app/05_ag-grid-configs/04_generate-colDefs/size-range-definitions-colDefs'
import { SizeRangeDefinitionModalComponent } from 'src/app/04_modals/size-range-definition-modal/size-range-definition-modal.component'
import { MatMenuTrigger } from '@angular/material/menu'

@Component({
  selector: 'app-size-range-definitions',
  templateUrl: './size-range-definitions.component.html',
  styleUrls: ['./size-range-definitions.component.scss']
})
export class SizeRangeDefinitionsComponent implements OnInit, OnDestroy {
  eventPointer: any
  private readonly sideBarPanels: any = SideBarPanels()
  gridName: string = 'size_distro_strategy_size_range_definitions'
  // Ag Grid Configuration
  private readonly customGridOptions: any = {
    ...CustomGridOptions,
    context: {
      componentParent: this,
      allowSaveView: true,
      gridName: this.gridName,
      columnsToAutoSize: [
        'size_range_name',
        'sizes',
        'has_existing_assignment'
      ],
      pageTitle: 'Size Distro Strategy - Size Range Definitions'
    }
  }

  public sizeRangeDefinitions = $sizeRangeDefinitionsJSON

  // Ag Grid Configuration
  gridApi: GridApi
  columnApi: ColumnApi
  gridOptions: any = {}
  isAgGridLoading: boolean = true
  // Grid Data
  columnDefs: any[] = []
  selectedSizeRanges: any[] = []
  storedGridSettings: any = {}
  rowData: any[] = []

  // Global Vars
  processingData: boolean = false

  @ViewChild('confirmPopup') confirmPopup: ConfirmPopupComponent
  @ViewChild('confirmPopupDelete') confirmPopupDelete: ConfirmPopupComponent
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger

  constructor (
    public dialogService: DialogService,
    private readonly sizeRangeDefinitionsAPIs: SizeRangeDefinitionsAPIs,
    public triggerApiCallsService: TriggerApiCallsService,
    private readonly generalAPIs: GeneralAPIs
  ) {
    this.gridOptions = {
      ...this.customGridOptions,
      sideBar: {
        toolPanels: this.sideBarPanels.allPanels,
        ...this.sideBarPanels.defaultLayout
      },
      pagination: true,
      paginationAutoPageSize: true,
      statusBar: {}, // Hide the status bar because pagination is applied here
      getRowId: node => FormatKey(node?.data?.size_range_name),
      getContextMenuItems: params =>
        this.SizeRangeDefinitionsGridContextMenu(params),
      onSelectionChanged: (event: SelectionChangedEvent) =>
        this.onSelectedSizeRangeChange(event),
      onRowDataUpdated: (event: RowDataUpdatedEvent) => {
        event.api.redrawRows()
      },
      context: {
        openDeleteConfirmDialog: event => {
          console.log('delete confirm event', event)
          this.confirmPopupDelete.confirm(
            event,
            'Delete Size Range Definitions'
          )
        },
        componentParent: this,
        gridName: 'size_range_definitions'
      }
    }
    // Event is fired when the user right-clicks on an element to open the context menu
    document.addEventListener('contextmenu', event => {
      if (event.button === 2) {
        this.eventPointer = event
      }
    })
  }

  ngOnInit (): void {}

  onGridReady (event: GridReadyEvent) {
    this.gridApi = event.api
    this.columnApi = event.columnApi
    this.isAgGridLoading = false
    this.gridApi.closeToolPanel()
    this.getAllSizeRangeDefinitions()
  }

  // Render AG Grid Column Definitions
  async renderGrid (mainColDefs, rowData) {
    const params = {
      mainColDefs,
      customCellFormatting: SRD_customCellFormatting
    }

    let gridData
    try {
      await this.getGridState()
      gridData = await SizeRangeDefinitions_GenerateGridData(params)
    } finally {
      this.columnDefs = gridData.mainColDefs
      AutoSizeColumns(this.gridApi)

      const checkAgGrid = () => {
        if (!this.isAgGridLoading) {
          if (this.gridApi && !this.gridApi['destroyCalled']) {
            this.gridApi?.setColumnDefs(gridData.mainColDefs)
            this.gridApi.setRowData(rowData)
            this.rowData = rowData // not bound to the grid directly, but we need it for duplicate name validation
            // Apply the saved column state
            if (
              Object.keys(this.storedGridSettings).length > 0 &&
                            this.storedGridSettings[this.gridName]
            ) {
              const { colDefs, filters } =
                                this.storedGridSettings[this.gridName]
              // Apply the saved column state
              this.gridOptions?.columnApi?.applyColumnState({
                state: colDefs
              })
              // Apply the column order
              this.gridOptions?.columnApi?.moveColumns(
                colDefs.map(col => col.colId),
                0
              )
              // Apply the saved filter state
              this.gridOptions?.api?.setFilterModel(filters)
            }
          }
          clearInterval(setData)
        } else {
          console.log('Ag grid is loading')
        }
      }
      const setData = setInterval(checkAgGrid, 100)
    }
  }

  // Get grid view
  async getGridState () {
    return await this.generalAPIs
      .GetGridView(this.gridOptions.context.gridName)
      .then(res => {
        this.storedGridSettings[this.gridOptions.context.gridName] =
                    res?.is_success
                      ? res.data
                      : {
                          colDefs: [],
                          filters: []
                        }
      })
  }

  onSelectedSizeRangeChange (event) {
    const rows = event.api.getSelectedNodes()
    this.selectedSizeRanges = rows
    console.log('row selection: changed: ', this.selectedSizeRanges)
  }

  // Call getAllManagementJobs API
  getAllSizeRangeDefinitions () {
    this.sizeRangeDefinitionsAPIs
      .GetAllSizeRangeDefinitions()
      .then((res: any) => {
        // Render if data is available
        console.log('result from get all', res)
        if (res && res.length > 0) {
          this.renderGrid(this.sizeRangeDefinitions[0], res)
        }
      })
  }

  // Sets the context menu in the grid
  SizeRangeDefinitionsGridContextMenu (params) {
    console.log('context menu: ', params)
    if (params.column) {
      const selectedNodes = params.api.getSelectedNodes()
      // console.log('selected cell: ', `${params.node.rowIndex}-${colId}`);
      // Delete Jobs Action
      const deleteSizeRangesButton = [
        {
          name:
                        selectedNodes.length > 0
                          ? `Delete ${selectedNodes.length} Size Range${
                                  selectedNodes.length === 1 ? '' : 's'
                              }`
                          : 'Delete (No Size Ranges Selected)',
          action: async () =>
            await this.confirmPopup.confirm(
              this.eventPointer,
              'Delete Size Ranges'
            ),
          disabled: !this.selectedSizeRanges.length,
          cssClasses: ['text-warn']
        }
      ]

      const result = [
        ...deleteSizeRangesButton,
        'separator',
        ...DefaultGridContextMenu(params)
      ]

      return result
    } else {
      return DefaultGridContextMenu(params)
    }
  }

  // Open Size Range creator screen
  async onCreateSizeRange (event: any) {
    // open modal
    this.dialogService.open(SizeRangeDefinitionModalComponent, {
      showHeader: false,
      closeOnEscape: true,
      dismissableMask: false,
      styleClass: 'small-modal',
      data: {
        existingSizeRangeNames: this.rowData.map(
          row => row.size_range_name
        ),
        close: () => {
          this.getAllSizeRangeDefinitions()
          this.selectedSizeRanges = []
        }
      }
    })
  }

  // On Delete Size Ranges
  async onDeleteSizeRanges (event) {
    console.log('delete definitions button pressed: ', event)

    if (event.userInput === 'except') {
      if (event.command === 'Delete Size Range Definitions') {
        let sizeRangesToDelete
        if (event.params?.data?.size_range_name) {
          // user clicked button in grid
          sizeRangesToDelete = [event.params.data.size_range_name]
        } else {
          // user is using button at top with selecting rows
          sizeRangesToDelete = this.selectedSizeRanges.map(
            rowNode => rowNode.data.size_range_name
          )
        }
        try {
          this.gridApi.showLoadingOverlay()
          // Save Grid State
          await SaveGridState(this.gridOptions, this.gridName)
        } finally {
          await this.sizeRangeDefinitionsAPIs
            .DeleteSizeRangeDefinitions(sizeRangesToDelete)
            .then(res => {
              if (
                res.is_success ||
                                (!res.is_success &&
                                    res.details.includes(
                                      'Successfully deleted size range definitions'
                                    ))
              ) {
                this.getAllSizeRangeDefinitions()
                this.selectedSizeRanges = []
              } else {
                this.gridApi.hideOverlay()
              }
            })
        }
      }
    }
  }

  @HostListener('unloaded')
  ngOnDestroy () {
    this.triggerApiCallsService.onTriggerApiCalls({
      clear_api_calls: true
    })
    if (this.gridApi) {
      this.gridApi.flushAsyncTransactions()
      this.gridApi.expireValueCache()
      this.gridApi = null
    }
  }
}
