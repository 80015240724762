<div class="custom-tooltip below text-center">
    <p *ngIf="isHeaderCell" class="text-center">
        {{ params.value }}
    </p>

    <table *ngIf="!isHeaderCell">
        <thead>
            <tr>
                <th>Prod Lvl</th>
                <th></th>
                <th>Stores at Lvl</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let row of tableData">
                <td>{{ row.prodLevel }}</td>
                <td></td>
                <td>{{ row.storesAtLevel }}</td>
            </tr>
        </tbody>
    </table>
</div>
