<!-- Modal Header -->
<app-modal-header
    modalTitle="Size Range Definition"
    [showCloseBtn]="false"
    (closeModal)="close()"
></app-modal-header>
<!-- Modal Content -->
<div
    class="modal-content custom-vertical-scroll"
    [attr.loading]="loading"
    [attr.size]="'small'"
>
    <div class="form-fields-container" *ngIf="!loading">
        <div class="form-fields-container-inputs">
            <app-form-fields
                #nameFormID
                [formParams]="size_range_definitions_form"
                controlName="size_range_name"
                (onFormFieldUpdated)="onFormUpdatedFunction($event)"
            >
            </app-form-fields>
            <app-form-fields
                #sizesFormID
                [formParams]="size_range_definitions_form"
                controlName="sizes"
                (onFormFieldUpdated)="onFormUpdatedFunction($event)"
            >
            </app-form-fields>
        </div>
        <div class="form-fields-container-buttons">
            <button mat-button (click)="onReset()">Reset</button>
            <button
                mat-raised-button
                [disabled]="!sizeRangeNameValid || !sizesValid"
                (click)="onSubmit($event)"
            >
                Create Size Range
            </button>
        </div>
    </div>
    <!-- Loading Component -->
    <div *ngIf="loading" class="w-100 h-100">
        <app-loader [loadingMessage]="loadingMessage"></app-loader>
    </div>
</div>

<!-- Modal Footer -->
<app-modal-footer
    (closeModal)="close()"
    [closeBtnText]="'Close'"
    [showUpdateBtn]="false"
></app-modal-footer>
