[
  {
    "Select": {
      "editable": false,
      "pinned": "left",
      "headerCheckboxSelection": true,
      "maxWidth": 50,
      "rowGroup": false,
      "hide": false,
      "suppressAutoSize": true,
      "refData": {
        "datatype": "checkbox",
        "selectAll": true,
        "colSize": 50
      }
    },
    "Product ID": {
      "editable": false,
      "refData": {
        "datatype": "string",
        "showToolTip": false,
        "flexSize": 2,
        "minWidth": 200
      },
      "rowGroup": false,
      "hide": false,
      "lockPosition": false
    },
    "Product Name": {
      "editable": false,
      "refData": {
        "datatype": "string",
        "showToolTip": false,
        "custom_field": "product_nm"
      },
      "rowGroup": false,
      "hide": false,
      "lockPosition": false
    },
    "Size Range Name": {
      "editable": false,
      "refData": {
        "datatype": "string",
        "showToolTip": false
      },
      "rowGroup": false,
      "hide": false,
      "lockPosition": false
    },
    "Eligible Channel": {
      "editable": false,
      "refData": {
        "datatype": "string",
        "showToolTip": false
      },
      "rowGroup": false,
      "hide": false,
      "lockPosition": false
    },
    "Future Size Range": {
      "editable": false,
      "refData": {
        "custom_field": "is_future",
        "datatype": "multi-checkbox-column",
        "showToolTip": false,
        "displayHeaderName": true,
        "colSize": 200
      },
      "maxWidth": 200,
      "rowGroup": false,
      "hide": false,
      "lockPosition": false
    },
    "Historical Size Range": {
      "refData": {
        "datatype": "multi-selection-auto-complete",
        "showToolTip": false
      },
      "rowGroup": false,
      "hide": false,
      "lockPosition": false
    },
    "In Use": {
      "editable": true,
      "refData": {
        "datatype": "multi-checkbox-column",
        "showToolTip": false,
        "displayHeaderName": true,
        "colSize": 200
      },
      "maxWidth": 200,
      "rowGroup": false,
      "hide": false,
      "lockPosition": false
    },
    "Edited": {
      "editable": false,
      "refData": {
        "datatype": "boolean"
      },
      "rowGroup": false,
      "hide": true
    },
    "Save": {
      "editable": false,
      "refData": {
        "datatype": "action-button|save",
        "displayBlankHeader": true
      },
      "rowGroup": false,
      "hide": false
    }
  }
]
