<app-modal-header [modalTitle]="title" [showCloseBtn]="true" (closeModal)="close()" />

<div *ngIf="loading" class="h-100 w-100 flex-center-all">
    <app-loader [loadingMessage]="loadingMessage"></app-loader>
</div>

<div *ngIf="!loading" class="modal-content custom-vertical-scroll" [attr.loading]="loading" [attr.size]="'medium'">
    <form [formGroup]="ruleForm" (ngSubmit)="onSubmit()">
        <div class="form-fields-container">
            <mat-form-field class="col-5">
                <mat-label>Rule Name</mat-label>
                <input matInput formControlName="name" placeholder="Rule Name" />
                <mat-error *ngIf="name.hasError('required')"> Rule Name is required </mat-error>
                <mat-error *ngIf="name.hasError('unique')"> Rule Name must be unique </mat-error>
            </mat-form-field>

            <h3>Conditions</h3>
            <div formArrayName="conditions">
                <div
                    *ngFor="let condition of conditions.controls; let i = index"
                    class="form-fields-span"
                    [formGroupName]="i"
                >
                    <!-- Condition Dropdown -->
                    <mat-form-field class="col-5">
                        <mat-label>Condition</mat-label>
                        <input matInput type="text" placeholder="Pick one" [matAutocomplete]="auto" />
                        <mat-autocomplete
                            #auto="matAutocomplete"
                            (optionSelected)="onConditionSelected($event, i)"
                            [displayWith]="displayCondition"
                        >
                            <mat-option *ngFor="let option of conditionChoices" [value]="option">
                                {{ option.condition }}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>

                    <!-- Condition Inputs -->
                    <div class="col-4" formArrayName="input_value">
                        <div *ngFor="let input of getConditionInputs(condition).controls; let j = index">
                            <mat-form-field class="w-100">
                                <mat-label>Value</mat-label>
                                <input matInput type="text" placeholder="Enter a value" [formControlName]="j" />
                            </mat-form-field>
                        </div>
                    </div>

                    <!-- Remove Condition Button -->
                    <button mat-button type="button" class="col-1" color="warn" (click)="removeCondition(i)">
                        Remove
                    </button>
                </div>

                <!-- Add Another Condition Button -->
                <button type="button" mat-button class="add-another" color="accent" (click)="addCondition()">
                    Add Another Condition
                </button>
            </div>

            <h3>Actions</h3>
            <div formArrayName="actions">
                <div
                    *ngFor="let action of actions.controls; let i = index"
                    class="form-fields-span"
                    [formGroupName]="i"
                >
                    <!-- Action Dropdown -->
                    <mat-form-field class="col-5">
                        <mat-label>Action</mat-label>
                        <input matInput type="text" placeholder="Pick one" [matAutocomplete]="auto" />
                        <mat-autocomplete
                            #auto="matAutocomplete"
                            (optionSelected)="onActionSelected($event, i)"
                            [displayWith]="displayAction"
                        >
                            <mat-option *ngFor="let option of actionChoices" [value]="option">
                                {{ option.action_title }}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>

                    <!-- Action Inputs -->
                    <div class="col-4" formArrayName="action_inputs">
                        <div *ngFor="let input of getActionInputs(action).controls; let j = index">
                            <div [formGroupName]="j">
                                <mat-form-field class="w-100">
                                    <mat-label>{{ input.get('input_name').value }}</mat-label>
                                    <input
                                        matInput
                                        type="text"
                                        placeholder="Enter a value"
                                        formControlName="input_value"
                                    />
                                </mat-form-field>
                            </div>
                        </div>
                    </div>

                    <!-- Remove Action Button -->
                    <button type="button" mat-button class="col-1" color="warn" (click)="removeAction(i)">
                        Remove
                    </button>
                </div>

                <!-- Add Another Action Button -->
                <button type="button" mat-button class="add-another" color="accent" (click)="addAction()">
                    Add Another Action
                </button>
            </div>

            <div class="form-fields-container-buttons">
                <button type="submit" mat-raised-button color="accent" [disabled]="!ruleForm.valid">Save</button>
            </div>
        </div>
    </form>
</div>

<app-modal-footer (closeModal)="close()" [closeBtnText]="'Close'" [showUpdateBtn]="false" />
